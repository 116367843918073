import React,{useEffect} from "react";
import { Link } from "react-router-dom";
import './page-important-links.css';

const Pageimportantlinks = () => {
  useEffect(() => {
    const metaTag = document.createElement("meta");
    metaTag.name = "robots";
    metaTag.content = "noindex, nofollow";
    document.head.appendChild(metaTag);

    return () => {
      document.head.removeChild(metaTag);
    };
  }, []);


  return (
    <div className="container mt-2">
      <div className="easylinks mb-4 workspacesolutions">
        <Link to="/" className="custom-link">
          Home
        </Link>{" "}
        / <span>Important Links</span>
      </div>

      <section className="privacy-policy mb-5">
      <div className="container">
        <h4>Important Links</h4>
        <div className="table-responsive">
          <table className="table" dir="ltr" cellSpacing="0" cellPadding="0" border="1">
            <colgroup>
              <col style={{ width: "auto" }} />
              <col style={{ width: "auto" }} />
              <col style={{ width: "auto" }} />
              <col style={{ width: "auto" }} />
            </colgroup>
            <tbody>
              <tr>
                <td style={{ textAlign: "center" }}>
                  <strong>Department</strong>
                </td>
                <td style={{ textAlign: "center" }}>
                  <strong>Form Link</strong>
                </td>
                <td style={{ textAlign: "center" }}>
                  <strong>Alternative</strong>
                </td>
                <td style={{ textAlign: "center" }}>
                  <strong>Comments</strong>
                </td>
              </tr>
              <tr>
                  <td
                    rowSpan="5"
                    style={{ textAlign: "center", verticalAlign: "middle" }}
                  >
                    General
                  </td>
                </tr>

               

                  <tr>
                    <td style={{ textAlign: "center" }}>
                      <a
                        className="in-cell-link"
                        href="https://docs.google.com/spreadsheets/d/1ftM7LdoNDtuFNzdu2Bk6vWtZ2tDkfdxTiNwSuGhBoHE/edit?usp=sharing"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                       Employee Contact Directory
                      </a>
                    </td>
                    <td style={{ textAlign: "center" }}>NA</td>
                    <td style={{ textAlign: "center" }}>
                    Access the real-time Employee Directory. Find employee names, designations, base locations and contact info. For internal use only.
                    </td>
                  </tr>

              
      
            <tr>
            <td style={{ textAlign: "center" }}>
                  <a
                    className="in-cell-link"
                    href="https://forms.zohopublic.com/altfcoworking/form/LeadForm/formperma/-MidPmP8VCcJeb4yDojFKES53Iqd5EbR4aEawBTXFUE"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Lead Form
                  </a>
                </td>
                <td style={{ textAlign: "center" }}>NA</td>
                <td style={{ textAlign: "center" }}>
                  To be used by <strong>Internal Non-Sales Team</strong> <br/>(For recording walk-ins, network effect,
                  Existing Customer)
                 
                </td>
            </tr>


              <tr>
                <td style={{ textAlign: "center" }}>
                  <a
                    className="in-cell-link"
                    href="https://docs.google.com/spreadsheets/d/11_5zLi1gZNg4mDO5L9Zz3Px-hSn7AdrLXAaW9kEbq5E/edit#gid=1034681481"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Google Group Emails
                  </a>
                </td>
                <td style={{ textAlign: "center" }}>NA</td>
                <td style={{ textAlign: "center" }}>This sheet contains details of all Google Group and group members</td>
              </tr>

              
              

              


              <tr>
                <td colSpan="3" style={{ textAlign: "center" }}>&nbsp;</td>
              </tr>
              <tr>
                <td rowSpan="4" style={{ textAlign: "center", verticalAlign: "middle" }}>
                  Operations
                </td>
                <td style={{ textAlign: "center" }}>
                  <a
                    className="in-cell-link"
                    href="https://forms.zohopublic.com/altfcoworking/form/SupportFormBackend/formperma/sERwsy4NFctbQYZlS5DSXb4q9FkCXw7jeADEppQ4XTs"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Backend Form
                  </a>
                </td>
                <td style={{ textAlign: "center" }}>NA</td>
                <td style={{ textAlign: "center" }}>For Internal and No Response Purposes</td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }}>
                  <a
                    className="in-cell-link"
                    href="https://forms.zohopublic.com/altfcoworking/form/SupportForm/formperma/oM2XwheSwZOFAdOYsXerULxUPuQlEamw-VrWtEGtKi8"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Frontend Form
                  </a>
                </td>
                
                <td style={{ textAlign: "center" }}>Drop Email to support@altfspaces.com</td>
                <td style={{ textAlign: "center" }}>For Clients / Internal with Response Purposes</td>
              </tr>


              <tr>
                <td style={{ textAlign: "center" }}>
                  <a
                    className="in-cell-link"
                    href="https://forms.zohopublic.com/altfcoworking/form/AdditionalCustomizationForm/formperma/_kEmvpQfYAqUg0l8C0BgF_YK6t1fBnP4rydHBYJHCAc"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Additional Customization Form
                  </a>
                </td>
                
                <td style={{ textAlign: "center" }}>NA</td>
                <td style={{ textAlign: "center" }}>For raising request related to Customization</td>
              </tr>

              


              <tr>
                <td colSpan="3" style={{ textAlign: "center" }}>&nbsp;</td>
              </tr>
              <tr>
                <td colSpan="3" style={{ textAlign: "center" }}>&nbsp;</td>
              </tr>
              <tr>
                <td rowSpan="3" style={{ textAlign: "center", verticalAlign: "middle" }}>
                  Accounts
                </td>
                <td style={{ textAlign: "center" }}>
                  <a
                    className="in-cell-link"
                    href="https://forms.zohopublic.com/altfcoworking/form/AccountsSupport/formperma/GJiJEJIRlaMFedqfIdmlhOyTjPEWc6wygzbfoATN2t4"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Accounts Support Form
                  </a>
                </td>
                <td style={{ textAlign: "center" }}>Drop Email to accounts@altfspaces.com</td>
                <td style={{ textAlign: "center" }}>For Clients / Internal team to raise query to accounts department</td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }}>
                  <a
                    className="in-cell-link"
                    href="https://forms.zohopublic.com/altfcoworking/form/NoticesSent/formperma/GF6alcQxKMR9wEuyNYbqbYEsJMlyvMVBt2uIERI3A0w"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Legal Case Initiation Form
                  </a>
                </td>
                <td style={{ textAlign: "center" }}>NA</td>
                <td style={{ textAlign: "center" }}>For Internal Purposes to initiate legal proceedings towards a client</td>
              </tr>
              <tr>
                <td colSpan="3" style={{ textAlign: "center" }}>&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>

      
    </div>
  );
};

export default Pageimportantlinks;
