import React,{useState} from "react";
import "./standout.css";
import { FaGlobe, FaMapMarkerAlt, FaClock, FaUsers, FaServer } from "react-icons/fa";
import { MdCurrencyExchange } from "react-icons/md";
import { AiOutlineRise } from "react-icons/ai";
import { RiMoneyRupeeCircleLine } from "react-icons/ri";
import { BsBuildingsFill } from "react-icons/bs";
import img1 from '../../../assests/property-detail/noida/altf-noida-58/1.avif';
import img2 from '../../../assests/property-detail/noida/altf-noida-58/2.avif';
import img3 from '../../../assests/property-detail/noida/altf-noida-58/3.avif';
import img4 from '../../../assests/property-detail/noida/altf-noida-58/4.avif';



const benefits = [
  {
    icon: img1,
    title: "Gurgaon",
  },
  {
    icon: img2,
    title: "Delhi",
  },
  {
    icon: img3,
    title: "Noida",
  },
 
  {
    icon: img4,
    title: "Hyderabad",
  },
 
];

const StandsOut = () => {
  const scrollToForm = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
};
  return (
    <div className="stands-out-section d-flex flex-column  align-items-center">

      <h2>Choose from 4 Cities</h2>
      <div className="channel-benefits-grid ">
        {benefits.map((benefit, index) => (
          <div className="channel-benefit-card" key={index}>
            <div >
            <img src={benefit.icon} alt={benefit.title} className="channel-icon"/>
            </div>
            <h3>{benefit.title}</h3>
            <button className="channelcardbtn"  onClick={scrollToForm}>Let's Go!</button>
          </div>
        ))}
      </div>



    </div>
  );
};

export default StandsOut;
