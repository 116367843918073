import React, { useEffect } from "react";
import bgpic from "../assests/slide40.avif";
import mpic from '../assests/slidem7.avif'
import "./slide2.css";
import { MdArrowBackIosNew } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";
import img1 from "../assests/slide7.avif";

const Slide7 = ({ onYearChange }) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowRight") {
        onYearChange("2021");
      } else if (event.key === "ArrowLeft") {
        onYearChange("2019");
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onYearChange]);

  return (
    <div>
      <div className="d-flex flex-row justify-content-between align-items-center arrowsintro">
        <div onClick={() => onYearChange("2019")}>
          <MdArrowBackIosNew style={{ fontSize: "30px" }} />
        </div>

        <div onClick={() => onYearChange("2021")}>
          <MdArrowForwardIos style={{ fontSize: "30px" }} />
        </div>
      </div>
      <div className="d-none d-lg-block">
        <div
          className="d-flex flex-row justify-content-center align-items-center containerheight"
         
        >
          <div className="col-lg-8 d-flex flex-row justify-content-center align-items-center" >
            <div >
              <div
                className="d-flex flex-row justify-content-start align-items-center"
                style={{marginLeft:"-30px"}}
              >
                <img src={bgpic} alt="img" className="slide4bg" />
                <div
                  className="slide1heading" 
                  style={{ marginLeft: "-20px", fontSize: "55px",marginTop:"20px", }}
                >
               <span style={{ fontSize: "2rem",fontWeight:"500" }}>
                 2020  
                  </span>
                  <br/>
                  <span>A Story of Survival</span>
                </div>
              </div>

              <div className="mt-4 slide4listitems ">
                <ul>
                  <li>One of the toughest periods for our company.</li>
                  <li>
                    Lockdown brought a rapid increase in vacancy notices and
                    absconding clients.
                  </li>
                  <li>
                  <span style={{backgroundColor:"#D36161",marginLeft:"4px",color:"white"}}>Faced a 50% drop in revenue;</span> managed clients, employees,
                    landlords, and vendors amidst these challenges.
                  </li>

                  <li>
                    Towards year-end, client interest renewed, filling
                    spaces—only to see vacancies return during COVID’s 2nd wave.
                  </li>
                  <li>
                  <span style={{backgroundColor:"#D36161",marginLeft:"4px",color:"white"}}>
              Survival was driven by our Profit-Sharing model
              </span> with
                    landlords and dedicated employees securing month-to-month
                    client arrangements.
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-lg-3 d-flex flex-row justify-content-center align-items-center" data-aos="zoom-in">
           <img src={mpic} alt="img" className="containerheight" style={{  maxWidth: "100%",objectFit:"contain",height:"80vh" }}/>
          </div>
        </div>
      </div>

      <div className="d-block d-lg-none pt-5  d-flex flex-column justify-content-center mbpt">
        <div className="d-flex flex-row justify-content-center align-items-center pt-3 allpagemargintop">
          <div className="mbslide2heading text-center d-flex flex-column mbslide3heading" style={{ backgroundImage: `url(${bgpic})` }}>
            <h2 className="mt-5 pt-2">A Story of Survival</h2>
          </div>
        </div>

       

        <div className="mbbggg">
          <div className=" slide4listitems  ">
            <ul>
              <li>One of the toughest periods for our company.</li>
              <li>
                Lockdown brought a rapid increase in vacancy notices and
                absconding clients.
              </li>
              <li>
              <span style={{backgroundColor:"#D36161",color:"white"}}>Faced a 50% drop in revenue;</span> managed clients, employees,
                landlords, and vendors amidst these challenges.
              </li>

              <li>
                Towards year-end, client interest renewed, filling spaces—only
                to see vacancies return during COVID’s 2nd wave.
              </li>
              <li>
              <span style={{backgroundColor:"#D36161",color:"white"}}>
              Survival was driven by our Profit-Sharing model
              </span>
                with landlords
                and dedicated employees securing month-to-month client
                arrangements.
              </li>
            </ul>
          </div>

          <div
          className=" d-flex flex-column justify-content-center align-items-center pb-3"
        >
          <div className="">
            <img
              src={img1}
              alt="img"
              className="slide2img4"
            />
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Slide7;
