import React, { useState } from "react";
import "./offer.css";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import img1 from "../../assests/privateoffice/po-parentpage/1.avif";
import img2 from "../../assests/privateoffice/po-parentpage/2.avif";
import img3 from "../../assests/privateoffice/po-parentpage/3.avif";
import po1 from '../../assests/flexible-office/daypass/main-img/Delhi.avif';
import vo1 from '../../assests/property-detail/noida/altf-noida-58/1.avif';
import vo2 from '../../assests/property-detail/noida/altf-noida-58/2.avif';
import vo3 from '../../assests/property-detail/noida/altf-noida-58/3.avif';
import fo1 from '../../assests/property-detail/noida/altf-noida-58/4.avif';
import fo2 from '../../assests/property-detail/noida/altf-noida-58/5.avif';
import fo3 from '../../assests/property-detail/noida/altf-noida-58/3.avif';
import fo4 from '../../assests/property-detail/noida/altf-noida-58/3.avif';

import "../../pages/workspace-solutions/private-office/private-office.css";
import "../../pages/workspace-solutions/private-office/tabs/tabs.css";
import { IoIosCheckmarkCircle } from "react-icons/io";
import EnquiryFormModal from "../pop-up-enquire/pop-up-enquiry";

const tabsData = [
  {
    name: "Private Office",
    height: "380px",
    headcontent: `Unlock the best offers on premium coworking spaces tailored for growing businesses. Whether you need a private office, a shared workspace, or a virtual office, we’ve got cost-effective solutions designed to fit your needs. Enjoy flexible terms, top-notch amenities, and a vibrant work environment—all at unbeatable prices. Limited-time discounts and special perks are available, so don’t miss out! `,
    offers: [
      {
        title: "alt.f Sector 142",
        location: "NOIDA",
        offerText: "15 DAYS RENT FREE",
        validUntil: "30th April 2025",
        image: img1,
        link: "/coworking-space/noida/altf-noida-142/",
      },
      {
        title: "alt.f Sector 62",
        location: "NOIDA",
        offerText: "30 DAYS RENT FREE",
        validUntil: "30th April 2025",
        image: img2,
        link: "/coworking-space/noida/altf-noida-62/",
      },
      
      {
        title: "alt.f Sector 68",
        location: "NOIDA",
        offerText: "15 DAYS RENT FREE",
        validUntil: "30th April 2025",
        image: po1,
        link: "/coworking-space/noida/altf-noida-142/",
      },
    ],
  },
  {
    name: "Virtual Office",
    height: "350px",
    headcontent: `Unlock the best offers on premium coworking spaces tailored for growing businesses. Whether you need a private office, a shared workspace, or a virtual office, we’ve got cost-effective solutions designed to fit your needs. Enjoy flexible terms, top-notch amenities, and a vibrant work environment—all at unbeatable prices. Limited-time discounts and special perks are available, so don’t miss out! `,
    offers: [
      {
        title: "Business Address Plan",
        validUntil: "30th April 2025",
        image: vo1,
        vooff: "20",
        link: "/workspace-solutions/virtual-office/business-address-plan/",
      },
      {
        title: "GST Registration Plan",
        validUntil: "30th April 2025",
        image: vo2,
        vooff: "20",
        link: "/workspace-solutions/virtual-office/gst-registration-plan/",
      },
      {
        title: "Business Registration Plan",
        validUntil: "30th April 2025",
        vooff: "20",
        image: vo3,
        link: "/workspace-solutions/virtual-office/business-registration-plan/",
      },
    ],
  },
  {
    name: "Flexible Office",
    height: "440px",
    headcontent: `Unlock the best offers on premium coworking spaces tailored for growing businesses. Whether you need a private office, a shared workspace, or a virtual office, we’ve got cost-effective solutions designed to fit your needs. Enjoy flexible terms, top-notch amenities, and a vibrant work environment—all at unbeatable prices. Limited-time discounts and special perks are available, so don’t miss out! `,
    offers: [
      {
        title: "LITE",
        validUntil: "30th April 2025",
        daypassprice: "₹10,000",
        limitedtimeoff: "*Limited Time Offer*",
        credits: 12000,
        additionalcred: 5000,
        image: fo1,
        link: "/workspace-solutions/flexible-office/",
      },
      {
        title: "PASS",
        validUntil: "30th April 2025",
        limitedtimeoff: "*Limited Time Offer*",
        daypassprice: "₹20,000",
        credits: 30000,
        additionalcred: 5000,
        image: fo3,
        link: "/workspace-solutions/flexible-office/",
      },
      {
        title: "BOX",
        validUntil: "30th April 2025",
        limitedtimeoff: "*Limited Time Offer*",
        daypassprice: "₹50,000",
        credits: 100000,
        additionalcred: 20000,
        image: fo2,
        link: "/workspace-solutions/flexible-office/",
      },
      {
        title: "BOX PLUS",
        validUntil: "30th April 2025",
        daypassprice: "₹1,00,000",
        limitedtimeoff: "*Limited Time Offer*",
        credits: 250000,
        additionalcred: 30000,
        image: img3,
        link: "/workspace-solutions/flexible-office/",
      },
    ],
  },
];

const Offer = () => {
  const [showMore, setShowMore] = useState(false);
  const [activeTab, setActiveTab] = useState("Private Office");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  const toggleReadMore = () => {
    setShowMore(!showMore);
  };

  const activeData = tabsData.find((tab) => tab.name === activeTab);

  return (
    <div className="container mt-2">
      {/* Breadcrumb */}
      <div className="easylinks mb-4 workspacesolutions">
        <Link to="/" className="custom-link">
          Home
        </Link>{" "}
        / <Link className="custom-link">Offers</Link>{" "}
      </div>

      <Helmet>
  {/* Meta Title */}
  <title>Offers And Discounts On Coworking Spaces - alt.f coworking</title>

  {/* Meta Description */}
  <meta
    name="description"
    content="Save big with exclusive offers and discounts on coworking spaces at alt.f coworking. Premium shared office spaces at affordable prices. Book your space now."
  />

  {/* Meta Keywords */}
  <meta
    name="keywords"
    content="offers, discounts, special offers, best deals, best discounts, shark tank special, special price, shark tank india special, altf coworking offers, shark tank deals, shark tank offers, coworking space, coworking spaces, alt.f coworking shark tank india"
  />

  {/* Robots */}
  <meta name="robots" content="index, follow" />

  {/* Canonical */}
  <link rel="canonical" href="https://www.altfcoworking.com/offers/" />

  {/* Author & Publisher */}
  <meta name="author" content="alt.f coworking" />
  <meta name="publisher" content="alt.f coworking" />

  {/* Open Graph (OG) Meta Tags */}
  <meta
    property="og:title"
    content="Offers And Discounts On Coworking Spaces - alt.f coworking"
  />
  <meta
    property="og:description"
    content="Save big with exclusive offers and discounts on coworking spaces at alt.f coworking. Premium shared office spaces at affordable prices. Book your space now."
  />
  <meta
    property="og:image"
    content="https://www.altfcoworking.com/static/media/banner-1.7d371a433fe0e00aaf6d.avif"
  />
  <meta property="og:url" content="https://www.altfcoworking.com/offers/" />
  <meta property="og:type" content="website" />
  <meta property="og:site_name" content="alt.f coworking" />

  {/* Twitter Meta Tags */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta
    name="twitter:title"
    content="Special Offers On Coworking Spaces - alt.f coworking"
  />
  <meta
    name="twitter:description"
    content="Save big with exclusive offers and discounts on coworking spaces at alt.f coworking. Premium shared office spaces at affordable prices. Book your space now."
  />
  <meta
    name="twitter:image"
    content="https://www.altfcoworking.com/static/media/banner-1.7d371a433fe0e00aaf6d.avif"
  />
  <meta
    name="twitter:image:alt"
    content="Offers And Discounts On Coworking Spaces - alt.f coworking"
  />
  <meta name="twitter:site" content="@altfcoworking" />
  <meta name="twitter:creator" content="@altfcoworking" />
      </Helmet>


      <EnquiryFormModal isOpen={isModalOpen} onClose={handleModalToggle} />

      {/* Header Section */}
      <div className="d-flex flex-column justify-content-center align-items-center w-100 text-center">
        <h1 className="propertytitle">Offers</h1>
        <div
          className="workspacepara po-tabheadcontent"
          dangerouslySetInnerHTML={{
            __html: showMore
              ? activeData.headcontent
              : `${activeData.headcontent.substring(0, 130)}...`,
          }}
        ></div>
        <h4 className="read-more-btn mt-3" onClick={toggleReadMore}>
          {showMore ? "Read Less" : "Read More..."}
        </h4>
      </div>

      {/* Tabs */}
      <div className="d-flex justify-content-center">
        <div className="po-tabs d-flex justify-content-around">
          {tabsData.map((tab) => (
            <button
              key={tab.name}
              className={`po-tab ${activeTab === tab.name ? "active" : ""}`}
              onClick={() => setActiveTab(tab.name)}
            >
              {tab.name}
            </button>
          ))}
        </div>
      </div>

      <TransitionGroup>
        <CSSTransition key={activeTab} timeout={300} classNames="fade">
          <div className="d-flex flex-row justify-content-center">
           <div  className="d-flex justify-content-center w-100">
           <div className="po-cards-container gap-5 flex-wrap justify-content-center" >
              {activeData.offers.map((offer, index) => (
                <div key={index}>
                  <div className="po-card-image  d-flex flex-row justify-content-center">
                    <img src={offer.image} alt={offer.title} className="offerimngs"/>
                  </div>
                  <div
                    className="po-card offersbox"
                    style={{ height: activeData.height }}
                  >
                    {offer.title && (
                      <h3 className="po-card-title mb-0 off-title">
                        {offer.title}
                      </h3>
                    )}

                    {offer.location && (
                      <h4 className="off-location">{offer.location}</h4>
                    )}

                    {offer.offerText && (
                      <h2 className="off-fixed-rentfre">{offer.offerText}</h2>
                    )}

                    <div>
                      {offer.daypassprice && (
                        <h4 className="dp-daypassprice">
                          {offer.daypassprice}
                        </h4>
                      )}
                      {offer.credits && (
                        <h4 className="dp-credits">{offer.credits} Credits</h4>
                      )}
                      {offer.additionalcred && (
                        <h4 className="dp-additionalcred">
                          Additional {offer.additionalcred} Credits
                        </h4>
                      )}
                      {offer.limitedtimeoff && (
                        <h4 className="dp-limitedoff">
                          {offer.limitedtimeoff} Credits
                        </h4>
                      )}
                    </div>

                    <div className="validandexplore">
                      {offer.credits && (
                        <h4 className="dp-total">
                          Total {offer.credits + offer.additionalcred} Credits{" "}
                        </h4>
                      )}
                      {offer.vooff && (
                        <h4 className="vo-off">{offer.vooff}% OFF</h4>
                      )}

                      {offer.validUntil && (
                        <p>Offers valid till {offer.validUntil}</p>
                      )}

                      {offer.link && (
                        <div className="d-flex flex-row justify-content-center">
                          <Link to={offer.link} target="_blank">
                            <button className="po-card-button">
                              Explore More
                            </button>
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
           </div>
          </div>
        </CSSTransition>
      </TransitionGroup>

      <div className="voplanmt mt-5">
        <h1 className="propertytitle">Terms & Conditions</h1>

        {/* Existing Term */}
        <p className="workspacepara mt-3">
          <IoIosCheckmarkCircle
            style={{ color: "#e45f62c9", marginRight: "10px" }}
          />
          No access to office premises unless Temporary Seating Plan or Meeting
          Room is booked
        </p>

        {/* New Terms & Conditions */}
        <p className="workspacepara mt-3 d-flex align-items-centre justify-content-center">
          <IoIosCheckmarkCircle
            style={{ color: "#e45f62c9", marginRight: "10px",marginTop:"3px" }}
          />
          <div>
            <strong>Limited Period Offer:</strong> Discounts and promotions are
            valid for a limited time and subject to availability. alt.f
            coworking reserves the right to modify or withdraw offers without
            prior notice.
          </div>
        </p>

        <p className="workspacepara mt-3">
          <IoIosCheckmarkCircle
            style={{ color: "#e45f62c9", marginRight: "10px" }}
          />
          <strong>New Customers Only:</strong> Offers apply only to new sign-ups
          and cannot be availed by existing members unless stated otherwise.
        </p>

        <p className="workspacepara mt-3">
          <IoIosCheckmarkCircle
            style={{ color: "#e45f62c9", marginRight: "10px" }}
          />
          <strong>Non-Transferable & Non-Exchangeable:</strong> Offers cannot be
          transferred, exchanged for cash, or combined with other promotions.
        </p>

        <p className="workspacepara mt-3">
          <IoIosCheckmarkCircle
            style={{ color: "#e45f62c9", marginRight: "10px" }}
          />
          <strong>Minimum Commitment:</strong> Some offers may require a minimum
          lock-in period or upfront payment to be eligible.
        </p>

        <p className="workspacepara mt-3">
          <IoIosCheckmarkCircle
            style={{ color: "#e45f62c9", marginRight: "10px" }}
          />
          <strong>Final Decision:</strong> alt.f coworking reserves the right to
          interpret these terms and resolve any disputes at its sole discretion.
        </p>
      </div>
    </div>
  );
};

export default Offer;
