import React, { useState,useEffect } from "react";
import Timeline from "./timeline";
import Slide1 from "../pages/slide1";
import Slide2 from "../pages/slide2";
import Slide3 from "../pages/slide3";
import Slide4 from "../pages/slide4";
import Slide5 from "../pages/slide5";
import { motion } from "framer-motion";
import Slide6 from "../pages/slide6";
import Whychooseus from "../pages/whychooseus";
import Whysmallbusinnes from "../pages/whysmallbusiness";
import Howarewediff from "../pages/howarewediff";
import Ourword from "../pages/ourword";
import Monologue from "../pages/monologue";
import Welcomslide from "../pages/welcomslide";
import Welcomeslide2 from "../pages/welcomeslide2";
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from "react-helmet-async";
import Slide7 from "../pages/slide7";
import Slide8 from "../pages/slide8";
import Slide9 from "../pages/slide9";
import Slide10 from "../pages/slide10";
import Slide11 from "../pages/slide11";

const TimelineNavigation = () => {
  const [selectedYear, setSelectedYear] = useState("welcome");


  const handleYearChange = (year) => {
    setSelectedYear(year);
  };

  const variants = {
    initial: { opacity: 0, x: -50 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 50 },
  };

  useEffect (() =>{
    Aos.init({duration: 1000,
      })
  },[])

  return (
    <div className="mbbggg">
      <div className="bgslide">
        <Timeline selectedYear={selectedYear} onYearChange={handleYearChange} />
        <Helmet>
  {/* Meta Title */}
  <title>Our Success Story: The Year-Wise Journey Of alt.f coworking</title>

  {/* Meta Description */}
  <meta
    name="description"
    content="alt.f coworking success story - A year-wise journey of growth, expansion, and innovation in the coworking industry. Read our story and learn about coworking."
  />

  {/* Meta Keywords */}
  <meta
    name="keywords"
    content="altf coworking story, altf coworking investment, altf coworking property launch, altf coworking journey, altf coworking founders, altf coworking growth, altf coworking success story, altf coworking revenue"
  />

  {/* Robots */}
  <meta name="robots" content="index, follow" />

  {/* Canonical */}
  <link rel="canonical" href="https://www.altfcoworking.com/our-story/" />

  {/* Author & Publisher */}
  <meta name="author" content="alt.f coworking" />
  <meta name="publisher" content="alt.f coworking" />

  {/* Open Graph (OG) Meta Tags */}
  <meta
    property="og:title"
    content="Our Success Story: The Year-Wise Journey Of alt.f coworking"
  />
  <meta
    property="og:description"
    content="alt.f coworking success story - A year-wise journey of growth, expansion, and innovation in the coworking industry. Read our story and learn about coworking."
  />
  <meta
    property="og:image"
    content="https://www.altfcoworking.com/static/media/website%20banner@3x.df998f8b309e26422189.avif"
  />
  <meta property="og:url" content="https://www.altfcoworking.com/our-story/" />
  <meta property="og:type" content="website" />
  <meta property="og:site_name" content="alt.f coworking" />

  {/* Twitter Meta Tags */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta
    name="twitter:title"
    content="Our Success Story: The Year-Wise Journey Of alt.f coworking"
  />
  <meta
    name="twitter:description"
    content="alt.f coworking success story - A year-wise journey of growth, expansion, and innovation in the coworking industry. Read our story and learn about coworking."
  />
  <meta
    name="twitter:image"
    content="https://www.altfcoworking.com/static/media/website%20banner@3x.df998f8b309e26422189.avif"
  />
  <meta
    name="twitter:image:alt"
    content="Our Success Story: The Year-Wise Journey Of alt.f coworking"
  />
  <meta name="twitter:site" content="@altfcoworking" />
  <meta name="twitter:creator" content="@altfcoworking" />
</Helmet>


        <div>
          <motion.div
            key={selectedYear}
            initial="initial"
            animate="animate"
            exit="exit"
            variants={variants}
            transition={{ duration: 0.5 }}
          >
            {selectedYear === "welcome" && (
              <Welcomslide onYearChange={handleYearChange} />
            )}
            {selectedYear === "welcome2" && (
              <Welcomeslide2 onYearChange={handleYearChange} />
            )}
            {selectedYear === "2010" && (
              <Slide1 onYearChange={handleYearChange} />
            )}
            {selectedYear === "2014" && (
              <Slide2 onYearChange={handleYearChange} />
            )}
            {selectedYear === "2016" && (
              <Slide3 onYearChange={handleYearChange} />
            )}
            {selectedYear === "2017" && (
              <Slide4 onYearChange={handleYearChange} />
            )}
            {selectedYear === "2018" && (
              <Slide5 onYearChange={handleYearChange} />
            )}
            {selectedYear === "2019" && (
              <Slide6 onYearChange={handleYearChange} />
            )}
            {selectedYear === "2020" && (
              <Slide7 onYearChange={handleYearChange} />
            )}
            {selectedYear === "2021" && (
              <Slide8 onYearChange={handleYearChange} />
            )}
            {selectedYear === "2022" && (
              <Slide9 onYearChange={handleYearChange} />
            )}
            {selectedYear === "2023" && (
              <Slide10 onYearChange={handleYearChange} />
            )}
            {selectedYear === "2024" && (
              <Slide11 onYearChange={handleYearChange} />
            )}
            {selectedYear === "how diff" && (
              <Howarewediff onYearChange={handleYearChange} />
            )}
            {selectedYear === "why small" && (
              <Whysmallbusinnes onYearChange={handleYearChange} />
            )}
            {selectedYear === "why choose" && (
              <Whychooseus onYearChange={handleYearChange} />
            )}
            {selectedYear === "our word" && (
              <Ourword onYearChange={handleYearChange} />
            )}
            {selectedYear === "monologue" && (
              <Monologue onYearChange={handleYearChange} />
            )}
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default TimelineNavigation;
