import React, { useState, useMemo, useRef, useEffect, useContext } from "react";
import DatePicker from "react-datepicker";
import { IoIosCloseCircle } from "react-icons/io";
import "react-datepicker/dist/react-datepicker.css";
import "./walkinbook.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { TokenContext } from "../tokenContext";

const Walkinbook = ({ isFormOpen, toggleForm }) => {
  // Form state variables
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedProperty, setSelectedProperty] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedHour, setSelectedHour] = useState("");
  const [selectedMinute, setSelectedMinute] = useState("");
  const [selectedAmPm, setSelectedAmPm] = useState("");
  const formRef = useRef(null);
  const { csrfToken, getValidToken } = useContext(TokenContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const validate = () => {
    const newErrors = {};

    if (!name.trim()) {
      newErrors.name = "Full Name is required.";
    }

    if (!phone.trim() || phone.trim().length < 10) {
      newErrors.phone =
        "A valid mobile number is required (at least 10 digits).";
    }

    if (!email.trim() || !/^\S+@\S+\.\S+$/.test(email.trim())) {
      newErrors.email = "A valid email address is required.";
    }

    if (!selectedCity) {
      newErrors.selectedCity = "Please select a preferred city.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const propertyCodeMapping = {
    // Gurgaon
    "Sohna Road, Gurgaon": "AltF Orchid Business Park",
    "Golf Course Road, Gurgaon": "AltF MPD Tower",
    "Golf Course Road Ext., Gurgaon": "AltF Success Tower",
    "Global Business Park, Gurgaon": "AltF Global Business Park",
    "JMD Empire Square, Gurgaon": "AltF Empire Square",
    "MGF Megacity Mall, Gurgaon": "AltF Megacity, MG Road",
    "Udyog Vihar, Gurgaon": "AltF 217, Udyog Vihar",

    // Delhi
    "Okhla, Delhi": "AltF 101 Okhla",
    "Barakhamba Road, Delhi": "AltF Statesman House, CP",
    "Mohan Cooperative, Delhi": "AltF Mohan Cooperative",
    "Connaught Place, Delhi": "AltF - Regal",

    // Noida
    "Sector 58, Noida": "AltF Noida 58",
    "Sector 142, Noida Expressway": "AltF Noida 142",
    "Sector 68, Noida": "AltF Grovy Optiva, Sec-68 Noida",
    "Sector 132, Noida Expressway": "AltF Noida 132",
    "Sector 62, Noida": "AltF - Noida 62",

    // Hyderabad (if you uncomment in citiesData later)
    "alt.f Financial District, Hyderabad": "AltF Financial District Hyderabad",
    "alt.f HITEC City, Hyderabad": "AltF Hitec City Hyderabad",

    // Default for upcoming or unknown locations
    "": "Upcoming Location",
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    setIsSubmitting(true);
    const mappedProperty =
      propertyCodeMapping[selectedProperty] || "Upcoming Location";

    const formatDateForZoho = (date) => {
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const day = date.getDate().toString().padStart(2, "0");
      const month = months[date.getMonth()];
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    };

    const formData = {
      name,
      phone,
      email,
      city: selectedCity,
      property: mappedProperty,
      date: selectedDate ? formatDateForZoho(selectedDate) : "",
      time: selectedHour, // Ensure time is in 'HH:MM AM/PM' format
    };

    try {
      const token = await getValidToken();
      const response = await axios.post(
        "https://www.altfcoworking.com/api/bookavisit", // Your backend route
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-CSRF-Token": csrfToken,
          },
          withCredentials: true,
        }
      );

      if (response.data.success) {
        setTimeout(() => {
          toggleForm();
          setName("");
          setPhone("");
          setEmail("");
          setSelectedCity("");
          setSelectedProperty("");
          setSelectedDate(null);
          setSelectedHour("");
          setSelectedMinute("");
          setSelectedAmPm("");
          navigate("/thank-you");
        }, 100);
      } else {
        alert("Failed to submit form. Please try again.");
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.message ===
          "Token expired. Please refresh the page."
      ) {
        alert(
          "Your session has expired. Please refresh the page and try again."
        );
      } else {
        console.error("Error submitting form:", error.message);
        alert("Server error. Please try again later.");
      }
    } finally {
      setIsSubmitting(false); // Reset loading state
    }
  };

  // Detect click outside the form to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isFormOpen &&
        formRef.current &&
        !formRef.current.contains(event.target) &&
        !event.target.closest(".navbar__menu-item")
      ) {
        toggleForm();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isFormOpen]);

  // Cities and their properties data
  const citiesData = useMemo(
    () => [
      {
        name: "Gurgaon",
        branches: [
          { title: "Sohna Road, Gurgaon" },
          { title: "Golf Course Road, Gurgaon" },
          { title: "Golf Course Road Ext., Gurgaon" },
          { title: "Global Business Park, Gurgaon" },
          { title: "JMD Empire Square, Gurgaon" },
          { title: "MGF Megacity Mall, Gurgaon" },
          { title: "Udyog Vihar, Gurgaon" },
        ],
      },
      {
        name: "Delhi",
        branches: [
          { title: "Okhla, Delhi" },
          { title: "Barakhamba Road, Delhi" },
          // { title: "Mohan Cooperative, Delhi" },
          // { title: "Connaught Place, Delhi" },
        ],
      },
      {
        name: "Noida",
        branches: [
          { title: "Sector 58, Noida" },
          { title: "Sector 142, Noida Expressway" },
          { title: "Sector 68, Noida" },
          { title: "Sector 132, Noida Expressway" },
          // { title: "Sector 62, Noida" },
        ],
      },
      // {
      //   name: "Hyderabad",
      //   branches: [
      //     { title: "alt.f Financial District, Hyderabad" },
      //     { title: "alt.f HITEC City, Hyderabad" },
      //   ]
      // },
    ],
    []
  );

  // Get properties based on selected city
  const properties = useMemo(() => {
    const city = citiesData.find((c) => c.name === selectedCity);
    return city ? city.branches : [];
  }, [selectedCity, citiesData]);

  return (
    <div className="walkinbook-container">
      <div>
        <div
          className={`walkinbook-form ${isFormOpen ? "open" : ""}`}
          ref={formRef}
        >
          <div className="d-flex justify-content-between align-items-center mb-3">
            <span style={{fontSize:"20px"}}>Book a visit</span>
            <button onClick={toggleForm} className="walkinbookb-close">
              <IoIosCloseCircle />
            </button>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="walkinbook-input-group">
              <label>Name</label>
              <input
                type="text"
                className="walkinbook-input"
                placeholder="Enter your name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {errors.name && (
                <p className="pop-up-error-text">{errors.name}</p>
              )}
            </div>

            <div className="walkinbook-input-group">
              <label>Phone Number</label>
              <input
                type="text"
                className="walkinbook-input"
                placeholder="Enter your phone number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              {errors.phone && (
                <p className="pop-up-error-text">{errors.phone}</p>
              )}
            </div>

            <div className="walkinbook-input-group">
              <label>Email</label>
              <input
                type="email"
                className="walkinbook-input"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {errors.email && (
                <p className="pop-up-error-text">{errors.email}</p>
              )}
            </div>

            <div className="walkinbook-input-group">
              <label>City</label>
              <select
                className="walkinbook-input"
                value={selectedCity}
                onChange={(e) => {
                  setSelectedCity(e.target.value);
                  setSelectedProperty(""); // Reset property when city changes
                }}
              >
                <option value="">Select City</option>
                {citiesData.map((city, index) => (
                  <option key={index} value={city.name}>
                    {city.name}
                  </option>
                ))}
              </select>
              {errors.selectedCity && (
                <p className="pop-up-error-text">{errors.selectedCity}</p>
              )}
            </div>

            <div className="walkinbook-input-group">
              <label>Coworking Space</label>
              <select
                className="walkinbook-input"
                value={selectedProperty}
                onChange={(e) => setSelectedProperty(e.target.value)}
                disabled={!selectedCity} // Disable until city is selected
              >
                <option value="">Select Property</option>
                {properties.map((property, index) => (
                  <option key={index} value={property.title}>
                    {property.title}
                  </option>
                ))}
              </select>
            </div>

            <div className="walkinbook-input-group">
              <label>Date</label>
              <DatePicker
                selected={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                dateFormat="dd/MM/yyyy"
                placeholderText="DD/MM/YYYY"
                className="walkinbook-input"
                minDate={new Date()}
              />
            </div>

            <div className="walkinbook-input-group">
              <label>Time</label>
              <div className="walkinbook-time-selectors">
                <select
                  className="walkinbook-input"
                  value={selectedHour}
                  onChange={(e) => setSelectedHour(e.target.value)}
                >
                  <option value="">Select Time</option>
                  {[
                    { hour: 9, minute: 0, display: "09:00 AM" },
                    { hour: 9, minute: 30, display: "09:30 AM" },
                    { hour: 10, minute: 0, display: "10:00 AM" },
                    { hour: 10, minute: 30, display: "10:30 AM" },
                    { hour: 11, minute: 0, display: "11:00 AM" },
                    { hour: 11, minute: 30, display: "11:30 AM" },
                    { hour: 12, minute: 0, display: "12:00 PM" },
                    { hour: 12, minute: 30, display: "12:30 PM" },
                    { hour: 13, minute: 0, display: "01:00 PM" },
                    { hour: 13, minute: 30, display: "01:30 PM" },
                    { hour: 14, minute: 0, display: "02:00 PM" },
                    { hour: 14, minute: 30, display: "02:30 PM" },
                    { hour: 15, minute: 0, display: "03:00 PM" },
                    { hour: 15, minute: 30, display: "03:30 PM" },
                    { hour: 16, minute: 0, display: "04:00 PM" },
                    { hour: 16, minute: 30, display: "04:30 PM" },
                    { hour: 17, minute: 0, display: "05:00 PM" },
                    { hour: 17, minute: 30, display: "05:30 PM" },
                    { hour: 18, minute: 0, display: "06:00 PM" },
                    { hour: 18, minute: 30, display: "06:30 PM" },
                  ].map((time, index) => (
                    <option key={index} value={time.display}>
                      {time.display}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <button type="submit" className="walkinbook-submit-btn">
              {isSubmitting ? "Submitting..." : "Submit"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Walkinbook;
