import React from 'react';
import "./infoflow.css";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { RiMoneyRupeeCircleLine } from "react-icons/ri";
import { TbBuildingSkyscraper } from "react-icons/tb";
import { AiOutlineRise } from "react-icons/ai";
import { FaMapMarkerAlt } from "react-icons/fa";

const LandlordInfoflow = () => {
  return (
    <div>
    <div className="inflow-secondsection d-none d-lg-block">
      <h2 className='mb-5'>How alt.f partners with Property Owners</h2>

      {/* Row 1 */}
      <div className="inflow-info-flow mbmarginginfo">
        <div className="inflow-faq-section">
          <TbBuildingSkyscraper className="inflow-icons" />
          <p className="inflow-info-text">You have a commercial property of 20k+ sq.ft.</p>
        </div>
        <div className="inflow-arrow">&#8594;</div> {/* Right Arrow */}
        <div className="inflow-faq-section">
          <FaMagnifyingGlass className="inflow-icons" />
          <p className="inflow-info-text">alt.f evaluates the location and makes projections.</p>
        </div>
        <div className="inflow-arrow">&#8594;</div>
        <div className="inflow-faq-section">
          <RiMoneyRupeeCircleLine className="inflow-icons" />
          <p className="inflow-info-text">You invest in fit-outs.<br />We convert your property into a coworking space.</p>
          <div className="inflow-down-arrow">&#8595;</div> 
        </div>
       
      </div>

      {/* Row 2 */}
      <div className="inflow-info-flow">
      <div className="inflow-faq-section">
          <RiMoneyRupeeCircleLine className="inflow-icons" />
          <p className="inflow-info-text">Get monthly Rental.</p>
        </div>
        <div className="inflow-arrow">&#8592;</div> {/* Backward Arrow */}

        <div className="inflow-faq-section">
          <AiOutlineRise className="inflow-icons" />
          <p className="inflow-info-text">You get access to a real-time occupancy dashboard.</p>
        </div>
        <div className="inflow-arrow">&#8592;</div> {/* Backward Arrow */}

        <div className="inflow-faq-section">
          <FaMapMarkerAlt className="inflow-icons" />
          <p className="inflow-info-text">SMEs lease space from us within 6-8 months.</p>
        </div>
       
       
      </div>
    </div>

    <div className="landlordsecondsection d-lg-none">
        <h2>How alt.f partners with Propery Owners</h2>

        <div className="landlord-info-section">
          <div className="d-flex flex-column align-items-center justify-content-center landlord-faq-section">
            <TbBuildingSkyscraper className="landlordicons" />
            <p className="landlord-info-text">
              You have a commercial property of 20k+ sq.ft.
            </p>
          </div>

          <div className="d-flex flex-column align-items-center justify-content-center landlord-faq-section">
            <FaMagnifyingGlass className="landlordicons" />

            <p className="landlord-info-text">
             alt.f evaluates the location and makes projections.
            </p>
          </div>

          <div className="d-flex flex-column align-items-center  justify-content-center landlord-faq-section">
            <RiMoneyRupeeCircleLine className="landlordicons" />
            <p className="landlord-info-text">
             You invest in fit-outs.<br/>We convert your property into a coworking space.
            </p>
          </div>

        </div>

        <div className="landlord-info-section">
          <div className="d-flex flex-column align-items-center justify-content-center landlord-faq-section">
            <FaMapMarkerAlt className="landlordicons" />
            <p className="landlord-info-text">
            SMEs lease space from us within 6-8 months.
            </p>
          </div>

          <div className="d-flex flex-column align-items-center justify-content-center landlord-faq-section">
            <AiOutlineRise className="landlordicons" />

            <p className="landlord-info-text">
            You get access to a real time occupancy dashboard.
            </p>
          </div>

          <div className="d-flex flex-column align-items-center  justify-content-center landlord-faq-section">
            <RiMoneyRupeeCircleLine className="landlordicons" />
            <p className="landlord-info-text">
            Get monthly Rental.
            </p>
          </div>

        </div>
      </div>

    </div>
  
  );
};

export default LandlordInfoflow;
