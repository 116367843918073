import React, { useState, useEffect, useCallback } from 'react';
import { RiCloseCircleLine } from "react-icons/ri";
import AltfButtonFunctionality from './altfbuttonfuntionality';

const Altfkey = ({ trigger }) => {
    const [isSupriceVisible, setIsSupriceVisible] = useState(false);
    const [locationAllowed, setLocationAllowed] = useState(null);

 // ✅ Function to show popup and request location
 const showPopupInstantly = () => {
    setIsSupriceVisible(true);  // ✅ Show pop-up instantly
    requestLocationPermission(); // ✅ Start location fetching in background
};

useEffect(() => {
    // ✅ Dynamically load GA4 script if not already present
    if (!window.gtag) {
        const script = document.createElement("script");
        script.async = true;
        script.src = "https://www.googletagmanager.com/gtag/js?id=G-KPE5QR1SKN";
        document.head.appendChild(script);

        script.onload = () => {
            window.dataLayer = window.dataLayer || [];
            function gtag(){ window.dataLayer.push(arguments); }
            window.gtag = gtag;
            window.gtag('js', new Date());
            window.gtag('config', 'G-KPE5QR1SKN'); // Replace with your GA4 Measurement ID
        };
    }

    const handleKeyDown = (event) => {
        const isMac = /Mac|iPod|iPhone|iPad/.test(navigator.userAgent);

        if (
            (isMac && event.altKey && event.code === "KeyF") ||
            (!isMac && event.altKey && event.code === "KeyF")
        ) {
            event.preventDefault();
            
            // ✅ Show pop-up functionality here
            showPopupInstantly(); 

            if (typeof window.gtag === "function") {
                window.gtag('event', 'altf_button_tracking', { 
                    'trigger_method': 'keyboard_shortcut', 
                    'key_combination': 'Alt+F' 
                });
            } else {
                console.warn("⚠️ gtag is not defined! Ensure GA4 script is loaded.");
            }
        }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
}, []); // ✅ No missing dependencies




    // useEffect(() => {
    //     const handleKeyDown = (event) => {
    //         const isMac = /Mac|iPod|iPhone|iPad/.test(navigator.userAgent); 


    //         if (
    //             (isMac && event.altKey && event.code === "KeyF") ||
    //             (!isMac && event.altKey && event.code === "KeyF")
    //         ) {
    //             event.preventDefault();
    //             showPopupInstantly(); 

    //             if (typeof window.gtag === "function") {
    //                 window.gtag('event', 'altf_button_tracking', { 
    //                     'trigger_method': 'keyboard_shortcut', 
    //                     'key_combination': 'Alt+F' 
    //                 });
    //             } else {
    //                 console.warn("gtag is not defined. Ensure GA4 is properly loaded.");
    //             }
    //         }
    //     };

    //     window.addEventListener('keydown', handleKeyDown);
    //     return () => window.removeEventListener('keydown', handleKeyDown);
    // }, [showPopupInstantly]);


    
    useEffect(() => {
        if (trigger) {
            showPopupInstantly();
        } else {
            setIsSupriceVisible(false); 
        }
    }, [trigger]);

    // const showPopupInstantly = () => {
    //     setIsSupriceVisible(true);  
    //     requestLocationPermission();
    // };

    const requestLocationPermission = () => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                setLocationAllowed(true);  // ✅ Location granted
            },
            (error) => {
                setLocationAllowed(false);  // ❌ Location denied
                alert("Location access denied. Please enable it to view nearby locations.");
            }
        );
    };

    const handleOverlayClick = (e) => {
        if (e.target.classList.contains("modal-overlay")) {
            setIsSupriceVisible(false);
        }
    };

    return (
        <>
            {isSupriceVisible && (
                <div className="modal-overlay" onClick={handleOverlayClick}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <button className="close-modal-btn" onClick={() => setIsSupriceVisible(false)}>
                            <RiCloseCircleLine size={24} style={{ color: "black" }} />
                        </button>
                        <AltfButtonFunctionality onClose={() => setIsSupriceVisible(false)} />
                    </div>
                </div>
            )}
        </>
    );
};

export default Altfkey;
