import React, { useEffect, useState } from "react";
import { RiCloseCircleLine } from "react-icons/ri";
import './altfkey.css';

import orchidBusinessPark from "../../assests/property-detail/gurgaon/altf-orchid-business-park/(1).avif";
import mpdTower from "../../assests/property-detail/gurgaon/altf-mpd-tower/(1).avif";
import successTower from "../../assests/property-detail/gurgaon/altf-success-tower/(4).avif";
import globalBusinessPark from "../../assests/altf-feature-img/orcade.jpg";
import empireSquare from "../../assests/property-detail/gurgaon/altf-empire-square/(1).avif";
import mgfMegacity from "../../assests/property-detail/gurgaon/altf-global-business-park/(2).avif";
import udyogVihar from "../../assests/property-detail/noida/altf-grovy-optiva/july-banner68-1.avif";

// New Delhi
import okhla from "../../assests/property-detail/delhi/altf-101-okhla/july-banner101-1.avif";
import statesmanHouse from "../../assests/property-detail/delhi/altf-statesman-house/july-bannerStateman-1.avif";
import mohanCooperative from "../../assests/property-detail/delhi/altf-mohan-cooperative/july-banner101-1 (1).avif";
import regalBuilding from "../../assests/property-detail/delhi/altf-regal-building/july-banner101-1.avif";

// Noida
import sector58 from "../../assests/property-detail/noida/altf-noida-58/1.avif";
import sector142 from "../../assests/property-detail/noida/altf-noida-142/july-banner142-1.avif";
import grovyOptiva from "../../assests/property-detail/noida/altf-grovy-optiva/july-banner68-1.avif";
import expressTradeTower from "../../assests/property-detail/noida/altf-noida-58/3.avif";
import joyTower from "../../assests/property-detail/noida/altf-express-trade-tower/july-banner132-3.avif";

// Hyderabad
import financialDistrict from "../../assests/property-detail/gurgaon/altf-megacity/(3).avif";


// Dummy loader (Replace with an animated loader if needed)
const Loader = () => (
  <div className="loader-container">
    <div className="spinner"></div>
    <p>Fetching nearby locations...</p>
  </div>
);



const AltfButtonFunctionality = ({ onClose }) => {
  const [locations, setLocations] = useState([]);
  const [error, setError] = useState("");
  const [radius, setRadius] = useState(5);
  const [showMore, setShowMore] = useState(false);
  const [loading, setLoading] = useState(true); // ✅ Add loading state

  const toggleReadMore = () => {
    setShowMore(!showMore);
  };

  const propertyImages = {
    // Gurgaon
    "alt.f Orchid Business Park": orchidBusinessPark,
    "alt.f MPD Tower": mpdTower,
    "alt.f Suncity Success Tower": successTower,
    "alt.f Global Business Park": globalBusinessPark,
    "alt.f Empire Square": empireSquare,
    "alt.f MGF Megacity": mgfMegacity,
    "alt.f Udyog Vihar": udyogVihar,
  
    // New Delhi
    "alt.f 101 Okhla": okhla,
    "alt.f Statesman House": statesmanHouse,
    "alt.f Mohan Cooperative": mohanCooperative,
    "alt.f Regal Building": regalBuilding,
  
    // Noida
    "alt.f Sector 58": sector58,
    "alt.f Expressway Sector 142": sector142,
    "alt.f Grovy Optiva": grovyOptiva,
    "alt.f Express Trade Tower": expressTradeTower,
    "alt.f Noida 62": joyTower,
  
    // Hyderabad
    "alt.f Financial District": financialDistrict,
  };
  

  const cityUrls = {
    // Gurgaon
    "alt.f Orchid Business Park": "/coworking-space/gurgaon/altf-orchid-business-park/",
    "alt.f MPD Tower": "/coworking-space/gurgaon/altf-mpd-tower/",
    "alt.f Suncity Success Tower": "/coworking-space/gurgaon/altf-success-tower/",
    "alt.f Global Business Park": "/coworking-space/gurgaon/altf-global-business-park/",
    "alt.f Empire Square": "/coworking-space/gurgaon/altf-empire-square/",
    "alt.f MGF Megacity": "/coworking-space/gurgaon/altf-megacity/",
    "alt.f Udyog Vihar": "/coworking-space/gurgaon/altf-udyog-vihar/",
  
    // New Delhi
    "alt.f 101 Okhla": "/coworking-space/delhi/altf-101-okhla/",
    "alt.f Statesman House": "/coworking-space/delhi/altf-statesman-house/",
    "alt.f Mohan Cooperative": "/coworking-space/delhi/altf-mohan-cooperative/",
    "alt.f Regal Building": "/coworking-space/delhi/altf-regal-building/",
  
    // Noida
    "alt.f Sector 58": "/coworking-space/noida/altf-sector-58/",
    "alt.f Expressway Sector 142": "/coworking-space/noida/altf-sector-142/",
    "alt.f Grovy Optiva": "/coworking-space/noida/altf-grovy-optiva/",
    "alt.f Express Trade Tower": "/coworking-space/noida/altf-express-trade-tower/",
    "alt.f Noida 62": "/coworking-space/noida/altf-noida-62/",
  
    // Hyderabad
    "alt.f Financial District": "/coworking-space/hyderabad/altf-financial-district/",
  
    // Default Fallback
    Default: "/coworking-space/",
  };
  

  // Function to get city URL based on location name
  const getCityUrl = (locationName) => {
    const match = Object.keys(cityUrls).find(key =>
      locationName.toLowerCase().includes(key.toLowerCase())
    );
    return match ? cityUrls[match] : cityUrls.Default;
  };





  const allLocations = [
    {
      city: "Gurgaon",
      locations: [
        { name: "alt.f Orchid Business Park", lat: 28.42998100518643, lng: 77.03674966137586 },
        { name: "alt.f MPD Tower", lat: 28.457752352990603, lng: 77.09740032275171 },
        { name: "alt.f Suncity Success Tower", lat: 28.411121759665352, lng: 77.07042864550343 },
        { name: "alt.f Global Business Park", lat: 28.48247905904045, lng: 77.10140269312073 },
        { name: "alt.f Empire Square", lat: 28.4824140606192, lng: 77.0900343544966 },
        { name: "alt.f MGF Megacity", lat: 28.48394477768705, lng: 77.08850369312074 },
        { name: "alt.f Udyog Vihar", lat: 28.505971342417478, lng: 77.08747366137585 }
      ]
    },
    {
      city: "New Delhi",
      locations: [
        { name: "alt.f 101 Okhla", lat: 28.55574347289252, lng: 77.27375366137586 },
        { name: "alt.f Statesman House", lat: 28.63448406839767, lng: 77.22351030687925 },
        { name: "alt.f Mohan Cooperative", lat: 28.528308529052023, lng: 77.29052701587244 },
        { name: "alt.f Regal Building", lat: 28.63091784131003, lng: 77.21657483068793 }
      ]
    },
    {
      city: "Noida",
      locations: [
        { name: "alt.f Sector 58", lat: 28.60540560927171, lng: 77.36209437301594 },
        { name: "alt.f Expressway Sector 142", lat: 28.504705946836992, lng: 77.41455233862415 },
        { name: "alt.f Grovy Optiva", lat: 28.60361439694235, lng: 77.38773138624147 },
        { name: "alt.f Express Trade Tower", lat: 28.518225051511738, lng: 77.37862430687926 },
        { name: "alt.f Noida 62", lat: 28.617041174046673, lng: 77.35522433862415 }
      ]
    },
    {
      city: "Hyderabad",
      locations: [
        { name: "alt.f Financial District", lat: 17.422478518992225, lng: 78.33232545767197 }
      ]
    }
  ];
  

  const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Earth's radius in km
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
              Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) *
              Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in km
  };
  



  useEffect(() => {
    if (navigator.geolocation) {
      setLoading(true); 
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          findNearbyLocations(latitude, longitude, radius);
        },
        (error) => {
          setError("Location access denied. Please enable it.");
          setLoading(false); 
        }
      );
    }
  }, [radius]);


  
  
  const findNearbyLocations = (latitude, longitude, radius) => {
    let nearbyLocations = [];
  
    allLocations.forEach(city => {
      city.locations.forEach(location => {
        const distance = getDistanceFromLatLonInKm(latitude, longitude, location.lat, location.lng);
        const adjustedDistance = distance * 1.5;  // Approximate for real-world driving distance
  
        if (adjustedDistance <= radius) {
          nearbyLocations.push({ ...location, distance: adjustedDistance.toFixed(2) });
        }
      });
    });
  
    nearbyLocations.sort((a, b) => a.distance - b.distance);
    setLocations(nearbyLocations);
    setLoading(false); // ✅ Stop loading once locations are found
  };
  



  const handleRadiusChange = (e) => setRadius(e.target.value);




  return (
    <div className="altfkey-modal-overlay" onClick={onClose}>
      <div className="altfkey-modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="altfkey-close-btn" onClick={onClose}>
          <RiCloseCircleLine size={24} />
        </button>

        <header className="altfkey-header">
          <h1>Nearby alt.f coworking Locations</h1>
          <div className="altfkey-radius-container">
            <label>Radius(km):</label>
            <input
              type="number"
              value={radius}
              onChange={handleRadiusChange}
              min="1"
              className="altfkey-radius-input"
            />
          </div>
        </header>

        {loading ? (
          <Loader /> // ✅ Show Loader while loading
        ) : error ? (
          <p className="altfkey-error">{error}</p>
        ) : (
        <section className="altfkey-locations-section">
          {locations.length > 0 ? (
            <>
              {/* Top Location */}
              <div className="altfkey-featured-location">
                <img
                  src={propertyImages[locations[0].name] || "https://via.placeholder.com/400"}
                  alt={locations[0].name}
                  className="altfkey-featured-img"
                />
                <div className="altfkey-featured-info">
                  <div>
                    <h2>
                      <a href={getCityUrl(locations[0].name)} className="custom-link" target="_blank" rel="noopener noreferrer">
                        {locations[0].name}
                      </a>
                    </h2>
                    <p >{locations[0].distance}km away </p>
                    <span style={{fontSize:"12px",marginTop:"-13px"}}>Approx*</span>
                  </div>
                  <button
                    className="altfkey-featured-btn"
                    onClick={() =>
                      window.open(
                        `https://www.google.com/maps/search/?api=1&query=${locations[0].name}`,
                        "_blank"
                      )
                    }
                  >
                    View on map
                  </button>
                </div>
              </div>

              {/* Other Locations */}

              {locations.length > 1 &&
                
                <div className="altfkey-other-locations">
                <h3>Other Nearby Locations</h3>
                <div className="d-flex justify-content-center">
                <div className="altfkey-location-grid">
                  {locations.slice(1, showMore ? locations.length : 4).map((location, index) => (
                    <div key={index} className="altfkey-location-card">
                      <div className="altfkey-location-details">
                        <h4>
                        {location.name}
                         
                        </h4>
                        <p >{location.distance}km away </p>
                        <span style={{fontSize:"12px"}}>Approx*</span>
                        <button
                          className="altfkey-directions-btn"
                          onClick={() =>
                            window.open(
                              `https://www.google.com/maps/search/?api=1&query=${location.name}`,
                              "_blank"
                            )
                          }
                        >
                          View on Map
                        </button>
                        <a href={getCityUrl(location.name)} className="custom-link" target="_blank" rel="noopener noreferrer">
                        <h5 className="exploremore">Explore more</h5>
                        </a>
                       
                      </div>
                    </div>
                  ))}
                </div>

                </div>

                {locations.length > 3 && 
                  <div className="d-flex flex-row justify-content-end mt-3">

                <h4 className="read-more-btn" onClick={toggleReadMore}>
                  {showMore ? "View Less" : "View More..."}
                </h4>
                </div>
                }

               
               
              </div>
              
              }
             
            </>
          ) : (
            <p className="altfkey-no-results">No nearby locations found within the selected radius.</p>
          )}
        </section>
      )}
      </div>
    </div>
  );
};

export default AltfButtonFunctionality;
