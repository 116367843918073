import React, { useState, useEffect,useContext } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "./maylike.css";
import { TokenContext } from "../../../components/tokenContext";
import { Link } from "react-router-dom";


const Maylike = () => {
  const { slug } = useParams();
  const [relatedBlogs, setRelatedBlogs] = useState([]);

  const { csrfToken, getValidToken } = useContext(TokenContext);

  useEffect(() => {
    if (!slug) return;

    const fetchRelatedBlogs = async () => {
      try {
        const token = await getValidToken();
        const response = await axios.get(
          "https://www.altfcoworking.com/api/blog-details/may-like-blogs",
          {
            params: { slug },
            headers: { Authorization: `Bearer ${token}`, "X-CSRF-Token": csrfToken },
            withCredentials: true,
          }
        );

        setRelatedBlogs(response.data.data || []);
      } catch (err) {
        console.error("Error fetching related blogs:", err);
      }
    };

    fetchRelatedBlogs();
  }, [slug, getValidToken, csrfToken]);

  // useEffect(() => {
  //   if (!slug) return;
  
  //   axios
  //     .get(
  //       `http://localhost:1337/api/blogs?sort=PublishedDate:desc&pagination[limit]=20&populate=*`
  //     )
  //     .then((res) => {
  //       let blogs = res.data.data || [];
  
  //       blogs = blogs.filter((blog) => blog.slug !== slug);
  
  //       const categoryGroups = {};
  //       blogs.forEach((blog) => {
  //         const category = blog.Category?.CategoryName || "Uncategorized";
  //         if (!categoryGroups[category]) {
  //           categoryGroups[category] = [];
  //         }
  //         categoryGroups[category].push(blog);
  //       });
  
  //       const selectedBlogs = [];
  //       const allBlogs = [];
  
  //       Object.values(categoryGroups).forEach((categoryBlogs) => {
  //         allBlogs.push(...categoryBlogs);
  //         if (categoryBlogs.length > 0 && selectedBlogs.length < 4) {
  //           const randomIndex = Math.floor(Math.random() * categoryBlogs.length);
  //           selectedBlogs.push(categoryBlogs[randomIndex]);
  //         }
  //       });
  
  //       while (selectedBlogs.length < 4 && allBlogs.length > 0) {
  //         const randomIndex = Math.floor(Math.random() * allBlogs.length);
  //         const newBlog = allBlogs[randomIndex];
  
  //         if (!selectedBlogs.some((b) => b.id === newBlog.id)) {
  //           selectedBlogs.push(newBlog);
  //         }
  
  //         allBlogs.splice(randomIndex, 1);
  //       }
  
  //       setRelatedBlogs(selectedBlogs.slice(0, 4));
  //     })
  //     .catch((err) => console.error("Error fetching related blogs:", err));
  // }, [slug]);
  

  return (
    <div className="maylike-container">
      <div className="maylike-header">
        <div>YOU MAY ALSO LIKE</div>
      </div>

      <div className="maylike-articles">
        {relatedBlogs.length === 0 ? (
          <p>No related blogs found.</p>
        ) : (
          relatedBlogs.map((blog) => (
           
            <div className="maylike-article-card" key={blog.id}>
            <Link to={`/blogs/${blog.slug}`} className="custom-link" style={{ textDecoration: "none" }} key={blog.id}>
              <div className="maylike-image-wrapper">
                <img src={blog.Image?.url} alt={blog.Title} loading="lazy" />
                <span className="maylike-category">
                  {blog.Category?.CategoryName || "Uncategorized"}
                </span>
              </div>
              <h3>{blog.Title}</h3>
              <p className=" m-2" style={{fontSize:"14px"}}>
                    {blog.Summary.length > 100 ? `${blog.Summary.substring(0, 100)}...` : blog.Summary}
                  </p>
              <p className="maylike-meta">
                {blog.author?.FullName || "Unknown"} -{" "}
                {new Date(blog.PublishedDate).toLocaleDateString()}
              </p>
              </Link>
            </div>
           
          ))
        )}
      </div>
    </div>
  );
};

export default Maylike;
