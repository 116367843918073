import React, { useState, useEffect, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./blogdetailspage.css";
import ReactMarkdown from "react-markdown";
import { FaFacebook, FaLinkedinIn, FaTwitter, FaLink } from "react-icons/fa";
import Maylike from "./maylike";
import { Link } from "react-router-dom";
import { TokenContext } from "../../../components/tokenContext";
import { Helmet } from "react-helmet-async";

const Blogdetailspage = () => {
  const { slug } = useParams();
  const [blogData, setBlogData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [latestBlogs, setLatestBlogs] = useState([]);
  const { csrfToken, getValidToken } = useContext(TokenContext);

  const hasFetchedBlogDetails = useRef(false);
  const hasFetchedLatestBlogs = useRef(false);

  useEffect(() => {
    if (hasFetchedBlogDetails.current === slug) return;
    hasFetchedBlogDetails.current = true;

    const fetchBlogDetails = async () => {
      setLoading(true);
      try {
        const token = await getValidToken();
        const response = await axios.get(
          `https://www.altfcoworking.com/api/blog-details`,
          {
            params: { slug },
            headers: {
              Authorization: `Bearer ${token}`,
              "X-CSRF-Token": csrfToken,
            },
            withCredentials: true,
          }
        );

        const blog = response.data.data[0]; // ✅ Get first matching blog
        if (blog) {
          setBlogData({
            id: blog.id,
            title: blog.Title,
            summary: blog.Summary || "No summary available",
            author: blog.author?.FullName || "Unknown",
            date: new Date(blog.PublishedDate).toLocaleDateString(),
            category: blog.Category?.CategoryName || "Uncategorized",
            bannerImage: blog.Image?.url || "/default-banner.jpg",
            content: blog.Content || "No content available",
            MetaData: blog.MetaData || "No MetaData available",
          });
        }
      } catch (err) {
        console.error("Error fetching blog:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogDetails();
  }, [slug, getValidToken, csrfToken]);

  // ✅ Fetch Latest Blogs After Blog Details Are Available
  useEffect(() => {
    if (!blogData?.id || hasFetchedLatestBlogs.current) return;
    hasFetchedLatestBlogs.current = true;

    const fetchLatestBlogs = async () => {
      try {
        const token = await getValidToken();
        const response = await axios.get(
          "https://www.altfcoworking.com/api/blog-details/latest-blogs",
          {
            params: { excludeId: blogData.id, limit: 8 },
            headers: {
              Authorization: `Bearer ${token}`,
              "X-CSRF-Token": csrfToken,
            },
            withCredentials: true,
          }
        );

        setLatestBlogs(response.data.data || []);
      } catch (err) {
        console.error("Error fetching latest blogs:", err);
      }
    };

    fetchLatestBlogs();
  }, [blogData?.id, getValidToken, csrfToken]);


  const handleCopyUrl = () => {
    navigator.clipboard.writeText(window.location.href);
  };

  return (
    <div className="container">
      <div className="blogs-detail-page">
        <div className="easylinks mb-2">
          <Link to="/" className="custom-link">
            <span>Home</span>
          </Link>{" "}
          /
          <Link to="/blogs/" className="custom-link">
            <span>Blogs</span>
          </Link>{" "}
          /<span style={{ marginLeft: "5px" }}>{slug}</span>
        </div>

        <Helmet>
          <title>{blogData?.MetaData?.MetaTitle || ""}</title>
          <meta
            name="description"
            content={blogData?.MetaData?.MetaDescription || ""}
          />
          <meta name="robots" content={blogData?.MetaData?.Robots || ""} />
          <link rel="canonical" href={blogData?.MetaData?.Canonical || ""} />
          <meta name="author" content={blogData?.MetaData?.Author || ""} />
          <meta
            name="publisher"
            content={blogData?.MetaData?.Publisher || ""}
          />
          <meta
            name="keywords"
            content={blogData?.MetaData?.MetaKeywords || ""}
          />

          <meta
            property="og:title"
            content={blogData?.MetaData?.OgTtitle || ""}
          />
          <meta
            property="og:description"
            content={blogData?.MetaData?.OgDescription || ""}
          />
          <meta
            property="og:image"
            content={blogData?.MetaData?.OgImage || ""}
          />
          <meta property="og:url" content={blogData?.MetaData?.OgUrl || ""} />
          <meta property="og:type" content={blogData?.MetaData?.OgType || ""} />
          <meta
            property="og:site_name"
            content={blogData?.MetaData?.OgSitename || ""}
          />

          <meta
            name="twitter:card"
            content={blogData?.MetaData?.TwitterCard || ""}
          />
          <meta
            name="twitter:title"
            content={blogData?.MetaData?.TwitterTitle || ""}
          />
          <meta
            name="twitter:description"
            content={blogData?.MetaData?.TwitterDescription || ""}
          />
          <meta
            name="twitter:image"
            content={blogData?.MetaData?.TwitterImage || ""}
          />
          <meta
            name="twitter:image:alt"
            content={blogData?.MetaData?.TwitterImageAlt || ""}
          />
          <meta
            name="twitter:site"
            content={blogData?.MetaData?.TwitterSite || ""}
          />
          <meta
            name="twitter:creator"
            content={blogData?.MetaData?.TwitterCreator || ""}
          />
        </Helmet>

        <div className="blogs-main-content">
          {/* Left Side: Title, Author, Date */}
          <div className="blogs-left">
            {loading ? (
              <div className="skeleton-banner"></div>
            ) : (
              <div
                className="blogs-banner mb-3"
                style={{ backgroundImage: `url(${blogData?.bannerImage})` }}
              >
                {/* <div className="blogs-banner-overlay">
                  <h3 className="mb-0">{blogData?.title}</h3>
                </div> */}
              </div>
            )}

            {loading ? (
              <>
                <div className="skeleton-title"></div>
                <div className="skeleton-meta"></div>
                {Array(50)
                  .fill(0)
                  .map((_, index) => (
                    <div key={index} className="skeleton-line"></div>
                  ))}
              </>
            ) : (
              <>
                <p className="blogs-author-date mb-1">{blogData?.category}</p>
                <div className="author-social-container">
                  <p className="blogs-author-date mb-0">
                    <span>By {blogData?.author}</span> •{" "}
                    <span>{blogData?.date}</span>
                  </p>
                  <div className="blogs-social-links">
                    <a
                      href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                        window.location.href
                      )}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="custom-link"
                    >
                      <FaFacebook />
                    </a>
                    <a
                      href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
                        window.location.href
                      )}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="custom-link"
                    >
                      <FaLinkedinIn />
                    </a>
                    <a
                      href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
                        window.location.href
                      )}&text=Check out this blog!`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="custom-link"
                    >
                      <FaTwitter />
                    </a>
                    <button className="copy-url-btn" onClick={handleCopyUrl}>
                      <FaLink />
                    </button>
                  </div>
                </div>
                <p className="mt-2 ,b-2">
                  <em>{blogData?.summary}</em>
                </p>
                <hr />
                <ReactMarkdown>{blogData?.content}</ReactMarkdown>
              </>
            )}
          </div>

          {/* Sticky Right Sidebar */}
          <div className="righbox-sticky">
            <div className="blogs-right">
              {loading ? (
                <div className="skeleton-sidebar"></div>
              ) : (
                <div className="blogs-latest-blogs">
                  <h3>Latest Blogs</h3>
                  {latestBlogs.length === 0 ? (
                    <div className="skeleton-sidebar"></div>
                  ) : (
                    latestBlogs.map((blog) => (
                      <Link
                        to={`/blogs/${blog.slug}`}
                        className="custom-link"
                        style={{ textDecoration: "none" }}
                        key={blog.id}
                      >
                        <div className="blogs-latest-item">
                          <img
                            src={blog.Image?.url}
                            alt={blog.Title}
                            className="latest-blog-image"
                            loading="lazy"
                          />
                          <div className="latest-content-box">
                            <h4>{blog.Title}</h4>
                            <p className="blogs-latest-meta">
                              <span>{blog.author?.FullName || "Unknown"}</span>{" "}
                              •{" "}
                              <span>
                                {new Date(
                                  blog.PublishedDate
                                ).toLocaleDateString()}
                              </span>
                            </p>
                            <p className="latest-blog-catgerory">
                              {blog.Category?.CategoryName || "Uncategorized"}
                            </p>
                            <h5 className="latest-blog-summery">
                              {blog.Summary
                                ? blog.Summary.length > 150
                                  ? `${blog.Summary.substring(0, 100)}...`
                                  : blog.Summary
                                : "No summary available"}
                            </h5>
                          </div>
                        </div>
                      </Link>
                    ))
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Maylike />
    </div>
  );
};

export default Blogdetailspage;
