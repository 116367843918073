import { useEffect,useState } from "react";
import { useLocation, matchPath } from "react-router-dom";

const useLoadScripts = () => {
  const location = useLocation();
  const [shouldLoadScripts, setShouldLoadScripts] = useState(false); // Control flag for script execution

  // First useEffect: Trigger script loading after 3 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setShouldLoadScripts(true); // Set flag to true after 3 seconds
    }, 3000); // 3-second delay

    return () => clearTimeout(timer); // Cleanup timer on unmount or route change
  }, []);  // Restart timer on route change


  useEffect(() => {
    if (!shouldLoadScripts) return;  
    
    const DYNAMIC_ATTRIBUTE = "data-dynamic";

    const removeDynamicElements = () => {
      document.querySelectorAll(`[${DYNAMIC_ATTRIBUTE}]`).forEach((el) => el.remove());
    };

    const addScript = (src, id, async = true, defer = false) => {
      if (document.querySelector(`script[${DYNAMIC_ATTRIBUTE}="${id}"]`)) return;
      const script = document.createElement("script");
      script.src = src;
      script.async = async;
      if (defer) script.defer = true;
      script.setAttribute(DYNAMIC_ATTRIBUTE, id);
      document.head.appendChild(script);
    };

    const addInlineScript = (content, id) => {
      if (document.querySelector(`script[${DYNAMIC_ATTRIBUTE}="${id}"]`)) return;
      const script = document.createElement("script");
      script.textContent = content;
      script.setAttribute(DYNAMIC_ATTRIBUTE, id);
      document.head.appendChild(script);
    };

    const addNoScriptTag = () => {
      if (document.querySelector(`noscript[${DYNAMIC_ATTRIBUTE}="noscript-gtm"]`)) return;
      const noscript = document.createElement("noscript");
      noscript.setAttribute(DYNAMIC_ATTRIBUTE, "noscript-gtm");
      noscript.innerHTML = `
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TNQ47ZW" 
        height="0" width="0" style="display:none;visibility:hidden"></iframe>
      `;
      document.body.insertAdjacentElement("afterbegin", noscript);
    };

    const initApollo = () => {
      if (document.querySelector(`script[${DYNAMIC_ATTRIBUTE}="apollo-tracker"]`)) return;
      const uniqueId = Math.random().toString(36).substring(7);
      addScript(
        `https://assets.apollo.io/micro/website-tracker/tracker.iife.js?nocache=${uniqueId}`,
        "apollo-tracker",
        true,
        true
      );
    };

    // Routes configuration
    const specialRoutes = [
      "/noida-landing-page/",
      "/Virtual-office-landing-page/",
      "/landing-page/coworking-spaces/",
    ];

    const noScriptRoutes = [
      "/our-story/",
      "/business-card/:cardId",
      "/landing-page/coworking-spaces/",
      "/404/",
    ];

    const isNoScriptRoute = noScriptRoutes.some((route) =>
      matchPath({ path: route, end: true }, location.pathname)
    );

    if (isNoScriptRoute) {
      removeDynamicElements();
      return;
    }

    removeDynamicElements();

    // Load scripts AFTER page has fully loaded
    const loadScriptsAfterPageLoad = () => {
      if (specialRoutes.includes(location.pathname)) {
        addScript("https://www.googletagmanager.com/gtag/js?id=AW-829038880", "gtag-aw");
        addInlineScript(
          `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-829038880');
          `,
          "gtag-config-aw"
        );
      } else {
        addScript("https://www.googletagmanager.com/gtag/js?id=G-KPE5QR1SKN", "gtag-g");
        addInlineScript(
          `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-KPE5QR1SKN');
          `,
          "gtag-config-g"
        );

        addScript(
          "https://r2.leadsy.ai/tag.js",
          "vtag-ai-js",
          true
        );
        document.getElementById("vtag-ai-js")?.setAttribute("data-pid", "iffS8g3oFfiI2pGZ");
        document.getElementById("vtag-ai-js")?.setAttribute("data-version", "062024");

        // Delay non-critical scripts until browser is idle
        if ('requestIdleCallback' in window) {
          requestIdleCallback(() => {
            addInlineScript(
              `
                (function(d,u,ac){
                  var s=d.createElement('script');
                  s.type='text/javascript';
                  s.src='https://a.omappapi.com/app/js/api.min.js';
                  s.async=true;
                  s.dataset.user=u;
                  s.dataset.account=ac;
                  d.getElementsByTagName('head')[0].appendChild(s);
                })(document,346084,365997);
              `,
              "optin-monster"
            );

            if (process.env.NODE_ENV === "production") {
              addInlineScript(
                `
                  (function(c,l,a,r,i,t,y){
                    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                    y=l.getElementsByTagName(r)[0];
                    y.parentNode.insertBefore(t,y);
                  })(window, document, "clarity", "script", "pwos282dbj");
                `,
                "microsoft-clarity"
              );
              initApollo();
            }

            addNoScriptTag();
          });
        } else {
          // Fallback if requestIdleCallback isn't supported
          setTimeout(() => {
            addInlineScript(
              `
                (function(d,u,ac){
                  var s=d.createElement('script');
                  s.type='text/javascript';
                  s.src='https://a.omappapi.com/app/js/api.min.js';
                  s.async=true;
                  s.dataset.user=u;
                  s.dataset.account=ac;
                  d.getElementsByTagName('head')[0].appendChild(s);
                })(document,346084,365997);
              `,
              "optin-monster"
            );

            if (process.env.NODE_ENV === "production") {
              addInlineScript(
                `
                  (function(c,l,a,r,i,t,y){
                    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                    y=l.getElementsByTagName(r)[0];
                    y.parentNode.insertBefore(t,y);
                  })(window, document, "clarity", "script", "pwos282dbj");
                `,
                "microsoft-clarity"
              );
              initApollo();
            }

            addNoScriptTag();
          }, 2000); // Delay by 2 seconds
        }
      }
    };

    // Load scripts after the window has fully loaded
    if (document.readyState === "complete") {
      loadScriptsAfterPageLoad();
    } else {
      window.addEventListener("load", loadScriptsAfterPageLoad);
    }

    return () => {
      removeDynamicElements();
      window.removeEventListener("load", loadScriptsAfterPageLoad);
    };
    
  }, [shouldLoadScripts,location.pathname]);
};

export default useLoadScripts;
