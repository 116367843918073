import React,{useEffect} from 'react';
import { Link } from "react-router-dom";
import './terms-of-use.css';

const Termsofuse = () => {
      useEffect(() => {
        const metaTag = document.createElement("meta");
        metaTag.name = "robots";
        metaTag.content = "noindex, nofollow";
        document.head.appendChild(metaTag);
    
        return () => {
          document.head.removeChild(metaTag);
        };
      }, []);
  return (
    <div className="container mt-2">
    <div className="easylinks mb-4 workspacesolutions">
        <Link to="/" className="custom-link">
          Home
        </Link>{" "}
        / <span>Terms of use</span>
      </div>


      <section className="privacy-policy mb-5">
        <div className="container">
            <h4>Terms of use</h4>
            <p>These Terms of Use govern and regulate; a) the temporary use and occupation of a part of the area and
                location, at the Demised Premises, on a room/seat basis (“<strong>Workspace</strong>”) by the Customers
                and; b) the Services provided by AltF Spaces Private Limited (“<strong>AltF</strong>”) to the Customers
                at the Demised Premises forming a part of the package including but not limited to internet connection,
                photocopy/printing/scanning services (chargeable after a stage), furniture, electricity and power back
                up, storage space (<strong>“Services”</strong>).</p>
            <p>These Terms of Use also forms part and parcel of the Customer Agreement between the Customer and AltF
                (“<strong>Agreement”</strong>). Capitalized terms used in this Terms of Use shall have the same meaning
                as ascribed to them in the Agreement unless specifically defined herein.</p>
            <p>The Customer shall ensure that it shall keep itself updated with any changes made to the Terms of Use, by
                visiting the website https://altfcoworking.com/terms-of-use/&nbsp;on a regular basis. AltF reserves the
                right to modify at its sole discretion, these Terms of Use without the requirement of providing notice
                of the same to the Customer. The Customer agrees to be bound by such Terms of Use and shall remain in
                compliance of the same at all times.</p>
            <p>AltF and the Customer are, hereinafter, collectively referred to as
                the&nbsp;<em>“</em><strong>Parties</strong><em>”</em>.</p>
            <ol>
                <li><strong>AltF</strong></li>
            </ol>
            <p>AltF is in the business of co-working and providing office spaces, workspaces, conference room space to
                small and medium enterprises, startup companies, multi-national companies, independent workers,
                consultants etc. along with various services.</p>
            <ol start="2">
                <li><strong>Customer</strong></li>
            </ol>
            <ul>
                <li>Any person executing and entering into an agreement with AltF on behalf of any entity represents and
                    warrants that it has all necessary right, authority and consent to bind such entity to the Agreement
                    and Terms of Use.</li>
            </ul>
            <ul>
                <li>The Customer, Customer’s employees and authorized representatives agree that it will not falsely
                    represent an association with any company, impersonate any third party, or otherwise submit or
                    present any false or misleading information to AltF or any customer or person associated with AltF.
                </li>
            </ul>
            <ul>
                <li>The Workspace and Services shall be used only for business purposes and the Customer acknowledges
                    that it shall not use the Workspace and Services for private use.</li>
            </ul>
            <ul>
                <li>The Customer, Customer’s employees and any authorized person who will be entering the Demised
                    Premises or using the Workspace and Services agree to subscribe to the KYC norms of AltF and will be
                    required to provide AltF with a valid Government ID, phone number and photograph and any other such
                    similar documents and information as required by AltF.</li>
            </ul>
            <ol start="3">
                <li><strong>Term</strong></li>
            </ol>
            <ul>
                <li>That in consideration of the payments to be made by the Customer, as set out herein and in the
                    Agreement and the Parties agreeing to comply with the covenants and conditions mentioned herein and
                    in the Agreement, AltF will grant temporary use and occupation of the Workspace and provide Services
                    to the Customer commencing from the Start Date (“<strong>Effective Date”</strong>) till the tenure
                    of the Agreement (“<strong>Term</strong>”).</li>
            </ul>
            <ol start="4">
                <li><strong>Fee</strong></li>
            </ol>
            <ul>
                <li>As per the agreement between the Parties, the Customer shall pay a monthly fee in consideration of
                    the Workspace and Services (<strong>“Fee”</strong>). The Fee shall be payable from the Effective
                    date. At the time of the reservation of the Demised Premises, an advance of the Fee will be required
                    to be paid by the Customer to AltF which shall be equal to the security deposit unless otherwise
                    agreed by the Parties.</li>
            </ul>
            <ul>
                <li>The Fee shall be payable on a per-room basis or a per-seat basis.</li>
            </ul>
            <ul>
                <li>The Fee shall be escalated by 10% (ten percent) or on the basis of prevailing rental value in the
                    market whichever is greater upon the completion of every 12-month period from the Effective Date.
                </li>
            </ul>
            <ul>
                <li>The Fee shall be paid on or before the <strong>3<sup>rd</sup>&nbsp;day</strong> of every calendar month, in advance
                    for the month for which the Fee is due. In case of delay in payment of the Fee, an interest at the
                    rate of 1.5% (one decimal five per cent) per month shall be chargeable. The Fee shall not be
                    deducted from the Security Deposit.</li>
            </ul>
            <ul>
                <li>On receipt of any payment from the Customer, AltF shall first apply such payment towards any
                    balances which are in arrears and to the earliest month due interest. Once past balances are
                    satisfied, any remaining portion of the payment will be applied to the subsequent Fee payable.</li>
            </ul>
            <ul>
                <li>In the event of termination by the Customer during the lock-in period as specified in the Agreement,
                    the Customer shall be liable to pay the Fee for the remaining lock-in period.</li>
            </ul>
            <ul>
                <li>If the client decides to reduce or downsize their membership seats plan during the lock-in period,
                    this action will be deemed a violation of the lock-in and the client will be responsible for
                    covering the costs associated with the remainder of the lock-in period. It's important to emphasize
                    that AltF retains the authority to potentially decrease or negotiate the duration of the lock-in
                    period at its sole discretion.</li>
            </ul>
            <ul>
                <li>Use of the Workspace and Services may be immediately suspended, and eventually terminated if payment
                    of the Fee is not received.</li>
            </ul>
            <ul>
                <li>In the event, the demised premises or its any part is not usable or accessible for any reason which
                    is beyond the control of the AltF including but not limited to fire, toxic smokes, damage to the
                    property due to any reason, pandemic, lockdown, terrorist attack, governmental order/ action, flood,
                    riots, utility failure,&nbsp; black-out or degradation, the violence of any arms or a mob or other
                    irresistible force or accident or any other similar force majeure circumstances, the customer shall
                    be liable to pay 50% of the service fee from the date of occurrence of the event till the demised
                    premises is restored and fully functional. Customer understand that 50% of the service fee shall be
                    payable as AltF has to incur various expenses, including but not limited to the security of the
                    premises, electricity and water charges, recurring service&nbsp;charges like building maintenance,
                    internet, housekeeping, on-ground management staff, etc which can not be discontinued for a small
                    duration and are necessary for the property. Customer unconditionally undertakes to abide by the
                    above clause and covenants that you shall not make an application and/ or institute any suits,
                    actions, or proceedings in any court of law, tribunal or any other legal forum against the above
                    charges.&nbsp; In case the demised premises is not functional even after 60 days, the customer shall
                    be at liberty to terminate the services as per the terms agreed upon and after following the
                    termination procedure by giving written notice to AltF.</li>
            </ul>
            <ul>
                <li>In the event of a delay in payment after the due date, the customer's services may get interrupted
                    or access to the premises or designated area of the customer may get restricted as per AltF's
                    discretion.</li>
            </ul>
            <ol start="5">
                <li><strong>Taxes</strong></li>
            </ol>
            <ul>
                <li>Goods and Service Tax or any other tax/ statutory dues as per the applicable laws on the Fee
                    payable, if applicable, shall be the sole liability of the Customer and shall be payable to AltF, in
                    addition to, and along with the Fee.</li>
            </ul>
            <ul>
                <li>The Fee shall be subject to the deduction of income tax at source. The Customer shall be solely
                    responsible for deposit of the taxes so deducted from the Fee with the appropriate authority within
                    the stipulated time and shall be solely responsible to furnish the certificate of the same to AltF.
                    The Customer unequivocally agrees to indemnify AltF for any loss / inconvenience caused, and actions
                    required to be taken on account of a default by the Customer in this present obligation. In
                    addition, and without prejudice to the above, if the Customer fails to submit the TDS certificate
                    and amount, the same may be deducted from the Security Deposit.</li>
            </ul>
            <ul>
                <li>The Customer shall provide AltF with a copy of the Customer’s GST registration certificate
                    containing all such information as required under law.</li>
            </ul>
            <ul>
                <li>The Customer shall ensure that the GST inward supply return (GSTR2) is filed correctly and shall
                    declare correct information on the GST portal. In case of any mismatch in information the Customer
                    shall promptly make amendments or take such corrective measures to ensure that AltF does not suffer
                    any loss of credit due to such mismatch.</li>
            </ul>
            <ul>
                <li>The Customer acknowledges that the place of supply as per the laws applicable to GST in India shall
                    be the place of supply as determined by the location of the Demised Premises.</li>
            </ul>
            <ul>
                <li>The Customer shall be solely responsible for performing all compliances and making payments of GST,
                    cesses, interest, penalties or any other tax/ duty/ amount/ charge/ liability / dues arising either
                    out of laws/ regulations applicable in India and overseas or because of a demand/ recovery initiated
                    by any revenue authority under laws/ regulations applicable in India or overseas.</li>
            </ul>
            <ol start="6">
                <li><strong>Security Deposit</strong></li>
            </ol>
            <ul>
                <li>The Customer shall pay an amount as agreed between the Parties as interest free refundable security
                    deposit (“<strong>Security Deposit</strong>”) on the Effective Date. It is agreed and acknowledged
                    that without the payment of the Security Deposit the Term shall not commence and the Customer shall
                    not have any rights in terms of the Agreement and the Terms of Use.</li>
            </ul>
            <ul>
                <li><span style={{fontWeight:"400"}}>AltF does not adjust rent/ TDS balances/ Interest/ Pending dues in
                        the Security Deposit. Security Deposit shall be retained by AltF during the Term and the said
                        deposit shall be refunded to the client within 45 days after termination of the Service
                        Agreement in accordance with the ‘terms of use’ herein contained. However, the Security Deposit
                        may not be refunded by AltF if the Customer terminates the Agreement during the lock-in period
                        as specified in the Agreement.</span></li>
            </ul>
            <ul>
                <li>The Fee payable by the Customer shall not be deducted from the Security Deposit even though the same
                    may be paid in lieu of advance by the Customer. The refund or deduction of the Security Deposit
                    shall be purely in accordance with the terms herein.</li>
            </ul>
            <ol start="7">
                <li><strong>Purpose</strong></li>
            </ol>
            <ul>
                <li>The Customer shall use the Workspace only for the purpose stated in the Agreement or as subsequently
                    agreed with AltF. The use of the Workspace for retail purposes involving frequent visits by members
                    of the public is not permitted.</li>
            </ul>
            <ul>
                <li>Children below 9 years will not be allowed on the Demised Premises. No pets/animals will be allowed
                    in the Demised Premises.</li>
            </ul>
            <ul>
                <li>The Customer shall not carry on a business which competes with the business of AltF as specified in
                    Clause 1.1.</li>
            </ul>
            <ul>
                <li>The Customer shall not be permitted to use the name of AltF in any way or in connection with the
                    business of the Customer. The Customer shall not take, copy or use for any purpose the trademarks,
                    service marks, logos, other identifiers or other intellectual property or modified or altered
                    versions of the same, or take, copy or use for any purpose any pictures or illustrations of any
                    portion of any AltF properties, without the prior consent of AltF.</li>
            </ul>
            <ul>
                <li>The Customer shall not make any agreement or settlement that requires a material act or admission by
                    AltF, or imposes any obligation on AltF without the prior written consent of AltF.</li>
            </ul>
            <ul>
                <li>The Customer shall not do or permit to be done in the Demised Premises any acts which is illegal
                    and/or which may be considered immoral, or unethical. The Customer shall not use the Demised
                    Premises, Workspace, or any part thereof, or any of the facilities provided by AltF, as storage
                    space of any illegal / banned / immoral, perverse – product/good.</li>
            </ul>
            <ul>
                <li>The Customer shall not use the Workspace in such a way, or store in the Workspace or any part of the
                    Demised Premises, any goods of hazardous or combustible nature, which may be in violation of the
                    applicable laws, and/or equipment or machinery which may affect the construction or the structure of
                    the Demised Premises or do or suffer anything to be done or around the Demised Premises which tends
                    to cause damage or deteriorate the value of the Demised Premises.</li>
            </ul>
            <ul>
                <li>The Customer shall not, during the term of this Agreement, and for a period of 3 (three) years
                    following its termination, solicit or hire or employ, whether as employee, consultant, or in any
                    other capacity, any person who has been employed, retained or hired by AltF at any time the term of
                    this Agreement. Even otherwise, the Customer shall not display posters / advertisements / notices
                    publicizing employment opportunity with the Customer, inside the Demised Premises and / or the
                    Workspace or any part thereof.</li>
            </ul>
            <ol start="8">
                <li><strong>Decorum</strong></li>
            </ol>
            <ul>
                <li>All persons including the Customer shall maintain decorum at the Demised Premises.</li>
            </ul>
            <ul>
                <li>The Customer undertakes not to do or cause to be done in&nbsp;or upon the Demised Premises any act,
                    matter or thing whatsoever, which may be or becomes nuisance or annoyance to the owner or any other
                    customer of any adjoining or neighboring premises, or which shall need any interference by the
                    police or local authorities.&nbsp;The Customer also undertakes to comply with all laws, by-laws,
                    statutes, rules and regulations, including any terms and conditions imposed by any applicable
                    authority as may be relevant for the Demised Premises.</li>
            </ul>
            <ul>
                <li>The Customer agrees not to make any excessive noise or carry out any other activity which disturbs
                    the peace and quiet of other customers in the Demised Premises.</li>
            </ul>
            <ul>
                <li>The Customer is responsible for the actions of and all damages caused by all persons that the
                    Customer invites to enter the Demised Premises.</li>
            </ul>
            <ul>
                <li>AltF is not responsible for the actions of other individuals using the Demised Premises. AltF does
                    not endorse, support or verify the facts, opinions or recommendations of any of the users of the
                    Demised Premises.</li>
            </ul>
            <ul>
                <li>No consumption of alcohol is allowed within the Demised Premises.</li>
            </ul>
            <ul>
                <li>The Customer agrees and acknowledges that it shall ensure that its employees, guests, or any third
                    party associated or connected with the Customer shall not indulge in any form of behavior or
                    activity amounting to harassment of any person at the Demised Premises in any form, regardless of
                    gender, religion, race, ethnicity, sexual orientation, disability or physical appearance. For the
                    avoidance of doubt, it is specified that harassment includes but is not limited to the use of
                    offensive verbal language, photography or recording without consent, sexual harassment including but
                    not limited to making sexually coloured remarks and physical contact and advances. Upon the notice
                    of any such incidence of harassment being given to AltF, AltF may require the Customer to take
                    action against the said individual. Without prejudice to the fact that AltF at no time, has any
                    responsibility or liability for such act, AltF reserves its right to take relevant steps against the
                    said individual or the Customer, including but without being limited to, restricting the entry to
                    the Demised Premises, and suspension, or termination of the Agreement with such AltF. Further, the
                    Customer undertakes to indemnify and save AltF from any loss or costs that AltF may be constrained
                    to undergo as a result of any such act of harassment.</li>
            </ul>
            <ol start="9">
                <li><strong>Property</strong></li>
            </ol>
            <ul>
                <li>The Customer shall not be entitled to erect or to get erected, any interior fittings, fixtures,
                    wooden partitions, cabins inside the Workspace, or make any additions or alterations, without the
                    prior permission of AltF. Such customization shall be done with the prior approval of AltF and by a
                    vendor approved by AltF.</li>
            </ul>
            <ul>
                <li>For any customization requirements that the Customer may have for the Workspace, a one-time charge
                    shall be levied by AltF which shall be payable upfront by the Customer. Any customization work shall
                    commence only after receipt of the one-time charge. It is further clarified that the Effective Date
                    shall not depend on the completion of such customization work by the vendor.</li>
            </ul>
            <ul>
                <li>The Customer may, if it so desires, bring any extra moveable furniture with the prior consent of
                    AltF, and provided the same does not cause a hindrance in the movement of persons within the
                    Workspace and the Demised Premises. The Customer shall not be permitted to remove or dismantle any
                    furniture of AltF.</li>
            </ul>
            <ul>
                <li>The Customer shall be permitted to install and use any additional appliance after prior approval
                    from AltF. Such approval may be granted by AltF, in applicable cases, subject to payment of extra
                    charges by the Customer.</li>
            </ul>
            <ul>
                <li>AltF will be responsible to take care of day to day maintenance and repairs pertaining to the
                    Demised Premises. It is the duty and responsibility of the Customer to keep and maintain the Demised
                    Premises in good, clean and neat condition. The Customer shall bear any expenses arising out of
                    repairs and wear tear rising due to any fault or negligence of the Customer, its agents, invitees or
                    guests.</li>
            </ul>
            <ul>
                <li><span style={{fontWeight:"400"}}>AltF will get the premises restored to its original condition and
                        the invoice shall be shared with the customer. This restoration cost includes the lost keys,
                        Access card, paint touch up and other charges applicable to bring the room back to its original
                        condition.</span> The Customer shall have the right to remove those movable assets belonging to
                    them which can be removed without affecting the structure of the premises or the building and if any
                    damage is caused, the Customer shall indemnify AltF. All the fixtures/furniture/equipment provided
                    to the Customer, in fit and working condition, at the time of use and occupation of the Workspace,
                    will be handed over in running condition at the time of vacating the Workspace.</li>
            </ul>
            <ul>
                <li>AltF will not be responsible for any property of the Customer that is left behind at the Demised
                    Premises. It is the responsibility of the Customer to ensure that all personal items have been
                    removed prior to leaving. Prior to the termination or early termination of the Agreement the
                    Customer must remove all property from the Demised Premises. AltF will be entitled to dispose of any
                    property remaining in the Demised Premises, after providing a notice of 15 (fifteen) days. Any
                    liability incurred by AltF in the removal of such property will be the responsibility of the
                    Customer.</li>
            </ul>
            <ul>
                <li>AltF bears no responsibility for any goods that may be stolen, destroyed or vandalized. For the
                    benefit of the Customer and other occupiers of the Demised Premises, AltF has provision for internal
                    CCTV cameras in the common Workspaces of the Demised Premises. Upon the occurrence of any act of
                    theft, destruction, or vandalism of property, the Customer shall inform AltF of the same, and AltF
                    shall make the best efforts to assist the Customer in this regard, including by way of provision of
                    the CCTV footage. However, for the avoidance of doubt, it is clarified that AltF shall not be liable
                    in any way, even in the event of inability to provide the relevant CCTV footage. It is further
                    clarified that the Customer shall not permitted to monitor or seek access to live CCTV footage.</li>
            </ul>
            <ul>
                <li>The Customer may be permitted to display its name/logo on the entry door of the Workspace or
                    anywhere within the Workspace. Such display/frosting shall be done with the prior approval of AltF
                    and by a vendor approved by AltF upto a maximum size of 20” x 10” for a charge of Rs. 2000/- (Rupees
                    Two Thousand only) payable by the Customer to the vendor. Such one- time charge shall be payable
                    upfront and prior to the commencement of the work. No other form of display to be allowed. The
                    Customer shall not be permitted to undertake any other form of advertising on the Demised Premises.
                </li>
            </ul>
            <ul>
                <li>The Customer shall not directly or indirectly take, copy or use any information or intellectual
                    property belonging to other customers or customer companies or any of their guests, including
                    without limitation personal names, business names, trademarks, service marks, logos, other
                    identifiers or other intellectual property, or modified or altered versions of the same.</li>
            </ul>
            <ol start="10">
                <li><strong>Operating Hours</strong></li>
            </ol>
            <ul>
                <li>The Customer shall be liable to pay an extra charge of Rs. 500/- (Rupees Five Hundred Only) per
                    seat, per hour, for using the Demised Premises beyond the operating hours of AltF.</li>
            </ul>
            <ul>
                <li>Entry of the Customer to the Demised Premises shall only be through the access card provided by
                    AltF. The Customer shall be responsible for loss of the access card. The Customer shall be
                    responsible for any loss or damage due to unauthorized entry.</li>
            </ul>
            <ul>
                <li>The Customer will be provided access cards to the Demised Premises on a per seat basis. For any
                    additional requirement of access cards, additional charges per card shall be applicable, such
                    additional charge per card shall be equal to 30% (thirty per cent) of the Fee per month per seat,
                    along with the penalty</li>
            </ul>
            <ul>
                <li>It is clarified for the avoidance of doubt, that the above-mentioned access cards are the property
                    of AltF and therefore on termination of the Agreement, the access cards will have to be returned to
                    AltF. On the failure to do so, AltF shall be free to initiate action against the Customer, and / or
                    deduct a charge of Rs. 1000/- (Rupees One Thousand only) per card, from the Security Deposit at its
                    sole discretion.</li>
            </ul>
            <ul>
                <li>AltF shall be closed on the following days, in addition to any local holidays that may be notified
                    by AltF from time to time:</li>
            </ul>
            <p>National holidays – 26th January, 15th August, 2nd October</p>
            <p>Holi</p>
            <p>Diwali</p>
            <p>Christmas</p>
            <ol start="11">
                <li><strong>Services</strong></li>
            </ol>
            <ul>
                <li>Depending on the agreement between the Parties, certain Credit points may be provided to the
                    Customer, which may be used to avail of certain services and facilities as offered by AltF, from
                    time to time, and which may or may not form part of the Agreement and Terms of Use. Unused credit points will not be carried forward and will lapse in the month-end.</li>
            </ul>
            <ul>
                <li>AltF will not be liable for any software or hardware issues to any electronic equipment or systems
                    that a Customer may face for no reasons attributable to AltF.</li>
            </ul>
            <ul>
                <li>Any additional requirements for data/voice points/access control and similar IT services within the
                    Workspace, will be subject to additional charges and after prior permission of AltF, subject to
                    their discretion. Such charges will be applicable for additional IT services in the Workspace only
                    and separate charges will be payable for any customization in any other Workspace subsequently used
                    and occupied by the Customer.</li>
            </ul>
            <ul>
                <li>One Lan will be provided in one Cabin.</li>
            </ul>
            <ul>
                <li>AltF shall not be obligated to disclose or share any data/information related to any IT services
                    provided by AltF to its customers including but not limited to AltF’s internet connection, networks,
                    access control, telecommunications systems or information processing systems. Any specific request
                    of a Customer shall be considered by AltF and acted upon at their sole discretion.</li>
            </ul>
            <ul>
                <li>AltF takes no responsibility for any injury, loss that may occur due to any beverage/water/food
                    consumed by the Customer at the Demised Premises. The Customer is free to make any claim/complaint
                    against the relevant third party vendor.</li>
            </ul>
            <ul>
                <li>AltF shall not be liable, directly or indirectly, to anyone for any damage or loss relating to any
                    use of any third party services and the Customer will look solely to the applicable third party for
                    provision of the applicable third party services and for compensation for any claims, damages,
                    liabilities or losses that the Customer may incur in connection with such third party services.</li>
            </ul>
            <ul>
                <li>The use of facilities such as printer and internet by any employee or authorized personnel of the
                    Customer shall at all times be considered as authorized usage of the printer and internet by the
                    Customer. The Customer will be provided with a PIN to print the pages. Any pages printed using that
                    pin would be considered as the prints taken by the Customer.</li>
            </ul>
            <ol start="12">
                <li><strong>Additional Services</strong></li>
            </ol>
            <p>If the Customer wishes to avail of additional services from AltF, but which are outside the package of
                Services contracted by the Customer, then such additional services shall be provided by AltF at
                additional charges, which are to be discussed and agreed upon by the Parties at the relevant time.</p>
            <ol start="13">
                <li><strong>Inspection and cleaning</strong></li>
            </ol>
            <ul>
                <li>During the Term, the Customer shall permit AltF, their respective agents and representatives, to
                    enter upon the Workspace, at reasonable times for inspecting its state and condition, to make
                    repairs or to show the Workspace to prospective customers. AltF or its representatives may also
                    enter the Workspace without prior consent if it appears to have been abandoned by the Customer, or
                    in the case where there is an apparent violation of law, or in case of emergency, or as otherwise
                    permitted by law.</li>
            </ul>
            <ul>
                <li>AltF or its agents, or employees, or contractors shall enter the Workspace for the purposes of
                    cleaning without any prior consent of the Customer.</li>
            </ul>
            <ul>
                <li>The Customer shall be liable to pay extra charges for any additional cleaning that would be done by
                    the housekeeping staff. The housekeeping staff will not be responsible for washing of any
                    utensils/crockery/cutlery of the Customer. The housekeeping staff of AltF will also not be
                    responsible for filling up water bottles of the Customer or for serving tea/coffee to the Customer.
                </li>
            </ul>
            <ul>
                <li>The Customer shall not mortgage, part with possession, assign any portion of the Workspace or create
                    third party interest in any manner in respect of the Workspace.</li>
            </ul>
            <ul>
                <li>AltF shall make best efforts to ensure continuous and uninterrupted provision of the Services to the
                    Customer during the Term, to the best of its ability and subject to issues of force majeure.</li>
            </ul>
            <ol start="14">
                <li><strong>Meeting Room</strong></li>
            </ol>
            <ul>
                <li>The Customer may use credits, granted to the Customer, for the meeting rooms in the Demised Premises
                    as per the charges specified, subject to availability of such meeting Rooms. Use of meeting rooms in
                    excess of any credits would be subject to the charges which can change from time to time.</li>
            </ul>
            <ul>
                <li>The use of a meeting room by any employee or authorized personnel of the Customer shall at all times
                    be considered as authorized usage of the meeting room by the Customer.</li>
            </ul>
            <ul>
                <li>Once the meeting room is booked, no changes can be made. For any changes, the meeting room can only
                    be cancelled and then re-booked. Cancellation charges may be applicable.</li>
            </ul>
            <ul>
                <li>AltF may impose additional charges on the Customer if the meeting has exceeded the time limit so
                    specified at the time of booking.</li>
            </ul>
            <ol start="15">
                <li><strong>Common Workspaces</strong></li>
            </ol>
            <ul>
                <li>The Customer shall be permitted to access the common Workspaces within the Demised Premises. Such
                    common Workspaces will be for a temporary use and not for everyday continuous work. AltF may conduct
                    events / meetings in the common Workspaces of the Demised Premises. AltF may play music in the
                    common Workspaces as per their own discretion.</li>
            </ul>
            <ul>
                <li>AltF reserves its right to display any form of advertising in the common Workspaces at its sole
                    discretion and does not need the leave or permission of any customer in this regard.</li>
            </ul>
            <ol start="16">
                <li><strong>Mail</strong></li>
            </ol>
            <p>The Customer shall be permitted to receive mail and packages at the Demised Premises during the regular
                business hours and on regular business days. AltF will inform the Customer on receiving any such mail or
                package. AltF will be under no obligation to store such mail or packages for more than 15 (fifteen) days
                from receipt.</p>
            <ol start="17">
                <li><strong>Termination</strong></li>
            </ol>
            <ul>
                <li>AltF shall be at liberty to terminate this Agreement by giving 30 (thirty) days prior notice in
                    writing.</li>
                <li>In case a member company wants to terminate its membership, the member company must give a Notice
                    Period. Notice Period will be applicable post completion of lock-in period (If applicable). A notice
                    period is mandatory and shall be accepted on the 1st of every month. Any notice, if received post
                    the 1st Day of the month, shall be considered from the 1st Day of the next month.</li>
            </ul>
            <ul>
                <li>In the event of a material breach of the Agreement by the Customer, AltF will be entitled to
                    terminate this Agreement without any prior notice to the Customer. The Term will be considered to
                    have concluded from the date of the communication, unless the same has been revoked and recalled by
                    AltF.</li>
            </ul>
            <ul>
                <li>The Customer shall make full and final payment of all outstanding sums and the applicable Fee or
                    part thereof, commensurate to the period that the Workspace was utilised by the Customer since the
                    date of last payment of the Fee until the date of termination. Any default or delay in such payment
                    shall carry an interest of 1.5% (one decimal five per cent) per month. AltF reserves the right to
                    recover such dues from the Security Deposit.</li>
            </ul>
            <ul>
                <li>Prior to, and no later than the date of termination, the Customer shall vacate the Workspace and in
                    case the Customer fails to do so, the Customer shall be liable to pay damages equal to double the
                    Fee, proportionate to the number of days that the Customer has exceeded post the termination of the
                    Agreement till the actual date of full and final vacation. Provided however that in the event the
                    Customer fails to fully vacate the Workspace even after 14 days after termination of the Agreement,
                    AltF shall be at liberty to vacate the Workspace on behalf of the Customer and shall hold the
                    possessions of the Customer for a period of 48 hours, which if not collected by the Customer, shall
                    be destroyed by AltF, at its sole discretion, at the risk and cost of the Customer. The Customer
                    acknowledges and admits that payment of the above damages would not in any way create any rights of
                    the Customer on the said Workspace.</li>
            </ul>
            <ul>
                <li>In a situation of partial termination of the Agreement during the lock-in period as specified in the
                    Agreement, it is hereby stated that at any given point of time, only a maximum of 10 (ten) per cent
                    of the seats may be vacated by the Customer. Provided however, at any given point of time such 10
                    (ten) per cent vacation shall not result in rendering any one of the rooms booked by the Customer as
                    partially vacant.</li>
            </ul>
            <ol start="18">
                <li><strong>Indemnity and Limitation of Liability</strong></li>
            </ol>
            <ul>
                <li>The Customer shall indemnify and shall hold AltF harmless and without having to undergo any losses
                    or costs, in relation to any actions that AltF may need to take including legal advice or
                    representation, to remedy or address any situation, including any action / enquiry from any
                    government instrumentality, or loss of goodwill or reputation on account of any actions / inactions
                    on the part of the Customer.</li>
            </ul>
            <ul>
                <li>The liability and consequences for doing any wrong/illegal acts, in the premises by the Customer
                    concerning various departments, namely, GST, income tax, custom, electricity, labour, municipal
                    corporation, health and pollution etc. shall be of the Customer exclusively and AltF shall not be
                    liable and responsible in any manner whatsoever.</li>
            </ul>
            <ul>
                <li>The Customer shall abide by the bye-laws and guidelines of the relevant authorities applicable to
                    the Demised Premises and shall carry out and obey all the legal orders and directions issued by the
                    authorities’ from time to time, unless such instructions are relevant for, and pertaining to AltF.
                </li>
            </ul>
            <ul>
                <li>AltF does not make any representations as to the security of the LAN (or the internet) or of any
                    information that is placed on it by the Customer. The Customer shall adopt whatever security
                    measures to safeguard its information. Furthermore, the Customer acknowledges that it has no
                    expectation of privacy with respect to AltF’s internet connection, networks, telecommunications
                    systems or information processing systems (including any stored computer files, email messages and
                    voice messages), and the Customer’s activity and any files or messages on or using any of those
                    devices or systems may be monitored at any time without notice, including for security reasons and
                    to ensure compliance with AltF’s policies, regardless of whether such activity occurs on equipment
                    owned by you or us.</li>
            </ul>
            <ul>
                <li>AltF is not liable for any loss as a result of failure to provide a service as a result of
                    mechanical breakdown, strike, fire drill etc. That for failure to provide any service, the Customer
                    must inform AltF in writing and give AltF reasonable time to correct the issue.</li>
            </ul>
            <ul>
                <li>The Customer agrees that AltF will not be held liable for any loss, expense, damage or claim
                    (hereinafter referred to as “Claims”) which arises as a result of, or in connection with, the
                    Agreement and/or the Agreed Services except to the extent that such loss, damage, expense or claim
                    is solely and directly attributable to a deliberate act or gross negligence of AltF.</li>
            </ul>
            <ul>
                <li>The Customer agrees that AltF will not be held liable for any mishap/accident taking place on the
                    Demised Premises.</li>
            </ul>
            <ul>
                <li>AltF will not be held liable for loss of business, loss of profits, loss of anticipated savings,
                    loss of or damage to data, third party claims or any consequential loss.</li>
            </ul>
            <ul>
                <li>The Parties acknowledge and agree that the liability of AltF, if any, shall be limited to the amount
                    of the monthly Fee only.</li>
            </ul>
            <ol start="19">
                <li><strong>Insurance</strong></li>
            </ol>
            <p>The Customer shall be solely responsible for getting the insurance, and protecting the property owned by
                the Customer which is brought into the Demised Premises and the Workspace, including data and documents,
                and&nbsp;<em>inter alia&nbsp;</em>for liability towards its employees and towards third parties,
                potential business loss, damage expense or liability.</p>
            <ol start="20">
                <li><strong>Governing Law and Jurisdiction</strong></li>
            </ol>
            <p>The Agreement and Terms of Use shall be governed by, and construed in accordance with, laws of India. The
                Courts at New Delhi shall have the exclusive jurisdiction to entertain any dispute arising out of or in
                any manner concerning the Agreement and Terms of Use to the exclusion of the other Courts.</p>
            <ol start="21">
                <li><strong>Arbitration</strong></li>
            </ol>
            <ul>
                <li>Notwithstanding anything contained in the Agreement or the Terms of Use, any dispute arising out of
                    or in connection with the Agreement or Terms of Use shall be sought to be resolved and settled
                    amicably within 30 (thirty) days of such dispute arising, failing which the dispute shall be
                    referred to and finally resolved by a sole arbitrator. The Parties agree that in the event of a
                    dispute which needs to be resolved by arbitration, the arbitrator shall be appointed mutually by the
                    parties, provided that there shall be no conflict of interest for the chosen arbitrator with either
                    party. The cost of the arbitration shall be borne equally by the Parties. The provisions of the
                    Arbitration and Conciliation Act, 1996 shall remain applicable.</li>
            </ul>
            <ul>
                <li>All proceedings in any such arbitration shall be conducted in English. The seat of the arbitration
                    proceedings shall be in Delhi and the award of the arbitrator shall be final and binding on the
                    Parties.</li>
            </ul>
            <ol start="22">
                <li><strong>Notices</strong></li>
            </ol>
            <p>Any notice required to be given, by AltF to the Customer or the Customer to AltF, shall be by way of
                e-mail at the addresses specified in the Agreement or at&nbsp;<a href="mailto:accounts@altfspaces.com">accounts@altfspaces.com</a>. It is clarified that no verbal
                communication shall constitute valid notice. In case of change of address of the Customer, the same
                shall be intimated immediately by the Customer to AltF in writing.</p>

                <ol start="23">
                    <li><strong>Non-Payment and Reallocation Clause</strong></li>
                </ol>
                <ul>
                    <li>In the event that the Client has failed to pay the monthly Subscription Fee for a period exceeding 30 (thirty) days, or has not paid the Subscription Fee for the said period and has also ceased to use or visit the premises, and subsequently expresses the desire to continue their subscription, AltF reserves the right to allocate to the Client the same cabin or space previously occupied, or any other available cabin or space, which may be of a similar or different size, subject to availability and at the discretion of AltF.</li>
                </ul>
                <ul>
                    <li>The Client acknowledges and agrees that such reallocation may result in changes to the configuration, size, or location of the allocated space, and that any continuation of the subscription shall be subject to payment of all outstanding dues, along with any applicable charges or adjustments as determined by AltF.</li>
                </ul>




            <ol start="24">
                <li><strong>Miscellaneous</strong></li>
            </ol>
            <ul>
                <li>These Terms of Use (as may be amended from time to time) consist of the terms and conditions which
                    forms part of the&nbsp;Agreement.</li>
            </ul>
            <ul>
                <li>The Customer agrees to avail of the Services and facilities which may also be used by other
                    customers of AltF, and other occupiers of the Demised Premises, with full cooperation and
                    understanding and without creating any hindrances.</li>
            </ul>
            <ul>
                <li>The Parties acknowledge and agree that nothing in the Terms of Use and/or Agreement shall be deemed
                    to constitute a partnership, joint venture, agency relationship or otherwise between the Parties.
                </li>
            </ul>
            <ul>
                <li>Nothing in the Terms of Use and/or Agreement shall be deemed to grant a lease over any part of the
                    Demised Premises and / or the Workspace, and the Customer agrees and warrants that no such
                    contention shall be taken up by the Customer at any time.</li>
            </ul>
            <ul>
                <li>If any part of the Terms of Use and/or Agreement is held to be illegal or unenforceable neither the
                    validity nor enforceability of the remainder of the Terms of Use and/or Agreement shall be affected.
                </li>
            </ul>
            <ul>
                <li>AltF shall not be liable for failure to perform or delay in performing any obligation under the
                    Terms of Use and/or Agreement if the failure or delay is caused by any circumstances beyond its
                    reasonable control, including but not limited to acts of god, war, civil commotion or strike, etc.
                </li>
            </ul>
            <ul>
                <li>Endorsements and Testimonials: AltF may from time to time, publish testimonials by customers related
                    to their experiences with the Services. These testimonials are the customers’ subjective opinions,
                    and they represent individual results. ALTF neither verifies them nor claims that they are typical
                    results that others will generally achieve. Names, locations, dates and other information may have
                    been changed to protect the privacy of the individuals involved. All other testimonials and
                    endorsements of any type, format or nature posted by users are not verified by ALTF, and ALTF makes
                    no warranty or representation as to their accuracy. The Customer should be cautious when relying on
                    any testimonials or endorsements, and should assume the results described therein are not typical or
                    standard.</li>
            </ul>
            <ul>
                <li>The failure of either party to enforce its rights under the Terms of Use and/or Agreement at any
                    time for any period shall not be construed as a waiver of such rights.</li>
            </ul>
            <ul>
                <li><span style={{fontWeight:"400"}}>AltF shall not be liable for any error made in good faith or any or
                        omission beyond the scope of their employment by any officer or employee of AltF. Customer shall
                        not hold AltF liable for any loss faced by you on account of any act of an employee or officer
                        of AltF which is not authorised by AltF.</span></li>
            </ul>
            <ul>
                <li>AltF will have no obligation or responsibility to mediate or participate or indemnify any party in a
                    dispute between customers of AltF or between customers of AltF and any third party. If AltF deems it
                    fit to mediate any issue between the customers of AltF at the Demised Premises then the decision of
                    AltF shall be final.</li>
            </ul>
        </div>

    </section>

      
    </div>
  )
}

export default Termsofuse;
