import React, { useState, useEffect, useMemo, useContext, useRef } from "react";
import axios from "axios";
import "./homeblog.css";
import Homeblogtabs from "./home-blog-tabs";
import { Link } from "react-router-dom";
import { TokenContext } from "../../../components/tokenContext";
import { Helmet } from "react-helmet-async";

const Homeblog = () => {
  const [blogs, setBlogs] = useState([]);
  const [highlightedBlog, setHighlightedBlog] = useState(null);
  const [metaData, setMetaData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { csrfToken, getValidToken } = useContext(TokenContext);
  const hasFetched = useRef(false);
  const hasFetchedMeta = useRef(false);
  const [showMore, setShowMore] = useState(false);
  


  const toggleReadMore = () => {
    setShowMore(!showMore);
  };

  // ✅ Fetch Homepage Meta Data
  useEffect(() => {
    if (hasFetchedMeta.current) return;
    hasFetchedMeta.current = true;

    const fetchMeta = async () => {
      try {
        const token = await getValidToken();
        const response = await axios.get(
          `https://www.altfcoworking.com/api/blogsherosection/metahome`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "X-CSRF-Token": csrfToken,
            },
            withCredentials: true,
          }
        );

        setMetaData(response.data || {});
      } catch (err) {
        console.error("Error fetching meta data:", err);
      }
    };

    fetchMeta();
  }, [csrfToken, getValidToken]);

  useEffect(() => {
    const fetchBlogs = async () => {
      if (hasFetched.current) return;
      hasFetched.current = true;

      try {
        const token = await getValidToken();
        const response = await axios.get(
          `https://www.altfcoworking.com/api/blogsherosection`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "X-CSRF-Token": csrfToken,
            },
            withCredentials: true,
          }
        );

        const fetchedBlogs = response.data || [];
        setHighlightedBlog(
          fetchedBlogs.find((blog) => blog.HighlightedFeaturedBlogs) ||
            fetchedBlogs[0]
        );
        setBlogs(fetchedBlogs);
      } catch (err) {
        console.error("Error fetching blogs:", err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, [csrfToken, getValidToken]);

  const filteredBlogs = useMemo(
    () => blogs.filter((blog) => blog.id !== highlightedBlog?.id),
    [blogs, highlightedBlog]
  );

  return (
    <div className="container">
      <div className="container-fluid blog-container">
        <div className="easylinks mb-2">
          <Link to="/" className="custom-link">
            <span>Home</span>
          </Link>{" "}
          / <span>Blogs</span>
        </div>

        <Helmet>
          <title>{metaData?.MetaData.MetaTitle || "Default Title"}</title>
          <meta
            name="description"
            content={
              metaData?.MetaData.MetaDescription || "Default description"
            }
          />
          <meta name="robots" content={metaData?.MetaData.Robots || "noindex nofollow"} />
          <link rel="canonical" href={metaData?.MetaData.Canonical || ""} />
          <meta name="author" content={metaData?.MetaData.Author || ""} />
          <meta name="publisher" content={metaData?.MetaData.Publisher || ""} />
          <meta
            name="keywords"
            content={metaData?.MetaData.MetaKeywords || ""}
          />

          <meta
            property="og:title"
            content={metaData?.MetaData.OgTtitle || ""}
          />
          <meta
            property="og:description"
            content={metaData?.MetaData.OgDescription || ""}
          />
          <meta
            property="og:image"
            content={metaData?.MetaData.OgImage || ""}
          />
          <meta property="og:url" content={metaData?.MetaData.OgUrl || ""} />
          <meta property="og:type" content={metaData?.MetaData.OgType || ""} />
          <meta
            property="og:site_name"
            content={metaData?.MetaData.OgSitename || ""}
          />

          <meta
            name="twitter:card"
            content={metaData?.MetaData.TwitterCard || ""}
          />
          <meta
            name="twitter:title"
            content={metaData?.MetaData.TwitterTitle || ""}
          />
          <meta
            name="twitter:description"
            content={metaData?.MetaData.TwitterDescription || ""}
          />
          <meta
            name="twitter:image"
            content={metaData?.MetaData.TwitterImage || ""}
          />
          <meta
            name="twitter:image:alt"
            content={metaData?.MetaData.TwitterImageAlt || ""}
          />
          <meta
            name="twitter:site"
            content={metaData?.MetaData.TwitterSite || ""}
          />
          <meta
            name="twitter:creator"
            content={metaData?.MetaData.TwitterCreator || ""}
          />
        </Helmet>

        <h1>Blogs</h1>
        <p>
          Welcome to the official blog page of alt.f coworking — your trusted
          source for in-depth insights, expert advice, and fresh perspectives on
          everything related to coworking spaces, startups, work culture, and
          the rapidly evolving future of work. In a world where flexibility,
          agility, and innovation are key to business success, our blogs serves
          {showMore && (
            <>
          as a valuable hub for knowledge and inspiration. Whether you're an
          entrepreneur launching your first venture, a freelancer seeking a
          collaborative environment, a small business in growth mode, or an
          established team looking to scale with ease, our content is
          thoughtfully curated just for you.
          <br /><br />
          At alt.f coworking, we understand the modern-day challenges of running
          a business, managing a team, or simply finding the right environment
          to stay productive. That’s why our blog covers a wide spectrum of
          topics — from how to choose the perfect coworking space that aligns
          with your brand and goals, to exploring the many advantages of shared
          office space, including cost-efficiency, networking, and flexibility.
          We dive deep into startup challenges, provide tips for creating a
          positive work culture, share success stories from our vibrant
          coworking communities, and explore how workspace design can influence
          productivity and employee well-being.
          <br /><br />
          Through every blog post, our mission is to empower individuals and
          businesses with the knowledge they need to thrive in flexible
          workspaces. Discover how alt.f coworking is transforming the way India
          works by offering innovative workspace solutions that cater to the
          unique needs of startups, MSMEs, and growing enterprises. Stay tuned,
          get inspired, and explore how our coworking spaces and shared office
          environments can be the catalyst for your next big move.
          </>
        )}
        </p>
        <span className="read-more-btn" onClick={toggleReadMore}>
          {showMore ? "Read Less" : "Read More..."}
        </span>

        <div className="row mt-3">
          {/* Left Block: Highlighted Blog */}
          {loading ? (
            <div className="col-lg-6 col-md-12 blog-highlighted">
              <div
                className="skeleton-card large"
                style={{ height: "680px" }}
              ></div>
            </div>
          ) : (
            highlightedBlog && (
              <div className="col-lg-6 col-md-12 blog-highlighted">
                <Link
                  className="custom-link"
                  to={`/blogs/${highlightedBlog.slug}`}
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <div className="blog-item">
                    <img
                      src={highlightedBlog.Image?.url}
                      alt={highlightedBlog.Title}
                      className="blog-image"
                      loading="lazy"
                    />
                    <div className="mt-2">
                      <h3 className="blog-title">{highlightedBlog.Title}</h3>
                      <p className="blog-summary">
                        {highlightedBlog.Summary.length > 200
                          ? `${highlightedBlog.Summary.substring(0, 200)}...`
                          : highlightedBlog.Summary}
                      </p>
                      <p className="blog-meta">
                        <span>{highlightedBlog.PublishedDate}</span> •{" "}
                        <span>{highlightedBlog.author?.FullName}</span> •{" "}
                        <span>{highlightedBlog.Category?.CategoryName}</span>
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
            )
          )}

          {/* Right Block: Recent Blogs */}
          <div className="col-lg-6 col-md-12 ">
            {loading
              ? Array(3)
                  .fill(0)
                  .map((_, index) => (
                    <div
                      key={index}
                      className="col-md-12 blog-item-left-image-right-content"
                    >
                      <div className="skeleton-card"></div> {/* Skeleton */}
                    </div>
                  ))
              : filteredBlogs.slice(0, 3).map((blog) => (
                  <div
                    key={blog.id}
                    className="col-md-12 blog-item-left-image-right-content"
                  >
                    <Link
                      className="custom-link"
                      to={`/blogs/${blog.slug}`}
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      <div className="row">
                        <div className="col-md-5">
                          <img
                            src={blog.Image?.url}
                            alt={blog.Title}
                            className="blog-image"
                            loading="lazy"
                          />
                        </div>
                        <div className="col-md-7">
                          <div className="blog-content blog-rightbox-content">
                            <h3 className="blog-title blog-rightbox-title">
                              {blog.Title}
                            </h3>
                            <p className="blog-summary blog-rightbox-summary">
                              {blog.Summary.length > 200
                                ? `${blog.Summary.substring(0, 200)}...`
                                : blog.Summary}
                            </p>
                            <p className="blog-meta blog-rightbox-meta">
                              <span>{blog.PublishedDate}</span> •{" "}
                              <span>{blog.author?.FullName}</span> •{" "}
                              <span>{blog.Category?.CategoryName}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
          </div>
        </div>
      </div>

      <Homeblogtabs />
    </div>
  );
};

export default Homeblog;
