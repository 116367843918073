import React, { useState, useEffect } from 'react';
import './stickybar.css';

const Stickybar = () => {
  const [showBar, setShowBar] = useState(false);

  useEffect(() => {
    // Check if the sticky bar has been dismissed before
    const isDismissed = localStorage.getItem('stickyBarDismissed');

    if (!isDismissed) {
      // Show the sticky bar after 3 seconds if not dismissed before
      const timer = setTimeout(() => {
        setShowBar(true);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, []);

  // Close the sticky bar and save the dismissal in localStorage
  const handleClose = () => {
    setShowBar(false);
    localStorage.setItem('stickyBarDismissed', 'true');  // Save the dismissal state
  };

  return (
    <>
      {showBar && (
        <div className="stickybar-container">
          <div className="stickybar-content">
            <span className="expaltf">
              alt<span style={{color:"#e15252"}}>.</span>f  <span style={{color:"#e15252"}}>nearby</span>
            </span>

            <span className="stickybar-text">
            Press <strong>alt + f</strong> on Windows | Press <strong>Option + F</strong> on Mac and discover the closest center to you
            </span>

            <button className="stickybar-close" onClick={handleClose}>✕</button>
          </div>
        </div>
      )}
    </>
  );
};

export default Stickybar;
