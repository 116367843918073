import { createContext, useState, useEffect } from "react";
import axios from "axios";

export const TokenContext = createContext();

export const TokenProvider = ({ children }) => {
  const [jwtToken, setJwtToken] = useState(localStorage.getItem("authToken") || "");
  const [csrfToken, setCsrfToken] = useState("");

  const fetchNewToken = async () => {
    try {
      const response = await axios.get("https://www.altfcoworking.com/api/generate-token");
      const newToken = response.data.token;
      setJwtToken(newToken);
      localStorage.setItem("authToken", newToken);
      return newToken;
    } catch (error) {
      console.error("Error fetching new JWT token:", error.message);
      return null;
    }
  };

  const fetchCsrfToken = async () => {
    try {
      const response = await axios.get("https://www.altfcoworking.com/api/csrf-token", {
        withCredentials: true,
      });
      setCsrfToken(response.data.csrfToken);
    } catch (error) {
      console.error("Error fetching CSRF token:", error.message);
    }
  };

  // Check if token is expired
  const isTokenExpired = (token) => {
    if (!token) return true;
    try {
      const payload = JSON.parse(atob(token.split(".")[1]));
      return payload.exp * 1000 < Date.now();
    } catch (error) {
      return true; // Assume expired if decoding fails
    }
  };

  // Get fresh token if expired
  const getValidToken = async () => {
    const storedToken = localStorage.getItem("authToken"); // Fetch fresh value from localStorage
    if (!storedToken || isTokenExpired(storedToken)) {
      return await fetchNewToken();
    }
    setJwtToken(storedToken); // Update state if token exists
    return storedToken;
  };

  useEffect(() => {
    if (!jwtToken) {
      fetchNewToken();
    }
    fetchCsrfToken();
  }, []);

  // 🔹 Watch localStorage changes (when manually deleted)
  useEffect(() => {
    const handleStorageChange = () => {
      const storedToken = localStorage.getItem("authToken");
      if (!storedToken) {
        setJwtToken(""); // Clear token in React state
      }
    };

    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, []);

  return (
    <TokenContext.Provider value={{ jwtToken, csrfToken, getValidToken }}>
      {children}
    </TokenContext.Provider>
  );
};
