import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./visitingcards.css";
import logo from "../assests/white logo@3x.png";
import phoneicon from '../assests/icons/grfedws.png';
import whatsapp from '../assests/icons/juhygtfrde.png';
import mail from '../assests/icons/hggfre.png';
import web from '../assests/icons/yhtgrfeds.png';
import location from '../assests/icons/ujhygtfrd.png';
import insta from '../assests/icons/esdfcgvhb.png';
import linkdin from '../assests/icons/dfgh.png';


const VisitingCards = () => {
  const { cardId } = useParams();
  const navigate = useNavigate();
  const [selectedCard, setSelectedCard] = useState(null);

  useEffect(() => {
          const metaTag = document.createElement("meta");
          metaTag.name = "robots";
          metaTag.content = "noindex, nofollow";
          document.head.appendChild(metaTag);
      
          return () => {
            document.head.removeChild(metaTag);
          };
        }, []);

  const visitingCardsData = [
    {
      name: "Abhimanyu Singh",
      title: "Director",
      department: "Expansion & Partnerships",
      email: "abhimanyu.singh@altfspaces.com",
      phone: "9821006209",  
    },
 
    {
      name: "Karan Malik",
      title: "Manager - Inside Sales",
      email: "karan.malik@altfspaces.com",
      phone: "9821573002",
    },
    {
      name: "Divya Singh",
      title: "Manager - Inside Sales",
      email: "divya.singh@altfspaces.com",
      phone: "9821540062",
    },
    {
      name: "Aishwarya Kapoor",
      title: "Manager - Pre Sales",
      email: "aishwarya.kapoor@altfspaces.com",
      phone: "9821593813",
    },
    {
      name: "Surbhi Anand",
      title: "Manager - Pre Sales",
      email: "surbhi.anand@altfspaces.com",
      phone: "9821699556",
    },

    {
      name: "Prateek Verma",
      title: "Sales Manager",
      email: "prateek.verma@altfspaces.com",
      phone: "9821593815",
    },
    {
      name: "Sandeep Valecha",
      title: "Sales Manager",
      email: "sandeep.valecha@altfspaces.com",
      phone: "9289624901",
    },
    {
      name: "Lakshay Seth",
      title: "Sales Consultant",
      email: "lakshay.s@altfspaces.com",
      phone: "9289624900",
    },
    {
      name: "Ayush Raman",
      title: "Sales Manager",
      email: "ayush.raman@altfspaces.com",
      phone: "9812500230",
    },
    {
      name: "Shailendra Singh",
      title: "Sales Manager",
      email: "shailendra.singh@altfspaces.com",
      phone: "9667071886",
    },
    {
      name: "Reepika Saini",
      title: "Cluster Head - Sales",
      email: "reepika.saini@altfspaces.com",
      phone: "9821591664",
    },
    {
      name: "Kamal Arora",
      title: "Sr. Manager-Sales",
      email: "kamal.arora@altfspaces.com",
      phone: "9821709569",
    },
    {
      name: "Ayushi Arya",
      title: "Manager - Inside Sales",
      email: "ayushi.arya@altfspaces.com",
      phone: "7303094990",
    },
    {
      name: "Jutin Arora",
      title: "Head of Marketing",
      email: "jutin.arora@altfspaces.com",
      phone: "9999236670",
    },
    {
      name: "Dinesh Jakhar",
      title: "Head of Operations",
      email: "dinesh.jakhar@altfspaces.com",
      phone: "9821592230",
    },
    {
      name: "Tamanna Jain",
      title: "VP - Human Resources",
      email: "tamanna.jain@altfspaces.com",
      phone: "9821633976",
    },
    {
      name: "Sarthak Chhabra",
      title: "Director",
      email: "sarthak@altfspaces.com",
      phone: "9821592921",
    },
    {
      name: "Yogesh Arora",
      title: "Director",
      email: "yogesh@altfspaces.com",
      phone: "9873795553",
    },
    {
      name: "Palak Bhardwaj",
      title: "Sales Manager",
      email: "Palak.bhardwaj@altfspaces.com",
      phone: "9821817923",
    },

  ];

  useEffect(() => {
    if (cardId) {
        const formattedCardId = cardId.replace(/-/g, " ").toLowerCase();

        const card = visitingCardsData.find(
            (c) => c.name.toLowerCase() === formattedCardId // Compare both in lowercase
        );

        if (card) {
            setSelectedCard(card);
        } else {
            navigate("/visiting-cards"); // Redirect if no match
        }
    }
}, [cardId, navigate]);

  

  if (!selectedCard) {
    return <p>Loading...</p>;
  }

  const { name, title, department, email, phone } = selectedCard;

  return (
    <div className="d-flex flex-column justifycontentmd align-items-center" >
      <div className="visitingcardcontainer">
        <div className="d-flex flex-row justify-content-end w-1oo">
          <img alt="logo" src={logo} className="logo" />
        </div>

        <div className="d-flex fle-row justify-content-center align-items-center">
          <hr className="hrline" />
        </div>

        <div className="d-flex flex-column justify-content-start detailscontainer">
          <div className="mbdetailscontainer d-flex flex-column justify-content-around">
            <h1>{name}</h1>
            <div>
              <h2>{title}</h2>
              {department &&   <h3>{department}</h3>}
             
            </div>
            <div>
              <h3 className="gmail">{email}</h3>
              <h3>{phone.replace(/(\d{5})(\d+)/, "$1 $2")}</h3>
            </div>
          </div>
        </div>

        <div className="d-flex fle-row justify-content-center align-items-center">
          <hr className="hrline" />
        </div>

        <div className="d-flex flex-row justify-content-center w-1oo">
          <div className="d-flex flex-row justify-content-around iconcontainer w-100">
            <a href={`tel:${phone}`} target="_blank" rel="noopener noreferrer">
              <img src={phoneicon} alt="img" className="icon"/>
            </a>
            <a href={`https://wa.me/${phone}`} target="_blank" rel="noopener noreferrer">
            <img src={whatsapp} alt="img" className="icon"/>
            </a>
            <a href={`mailto:${email}`} target="_blank" rel="noopener noreferrer">
            <img src={mail} alt="img" className="icon"/>
            </a>
            <a href="https://www.altfcoworking.com/" target="_blank" rel="noopener noreferrer">
            <img src={web} alt="img" className="icon"/>
            </a>
            <a href="https://www.google.com/maps?q=701-708,+7th+Floor,+Tower+D,+Mehrauli-Gurgaon+Rd,+Sikanderpur,+Sector+26,+Gurugram,+Haryana+122002" target="_blank" rel="noopener noreferrer">
            <img src={location} alt="img" className="icon"/>
            </a>
            <a href="https://www.instagram.com/alt.f_coworking/" target="_blank" rel="noopener noreferrer">
            <img src={insta} alt="img" className="icon"/>
            </a>
            <a href="https://www.linkedin.com/company/altf-coworking/" target="_blank" rel="noopener noreferrer">
            <img src={linkdin} alt="img" className="icon"/>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisitingCards;
