import React, { useEffect, useRef, useState } from "react";
import "./startup-event.css";
import video from "../../assests/startup-event/sarthak startup-event.mp4";
import {
  FaGlobe,
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaYoutube,
  FaVolumeUp,
} from "react-icons/fa";

const Startupevent = () => {
  const videoRef = useRef(null);
  const [isMuted, setIsMuted] = useState(true);

  useEffect(() => {
    if (videoRef.current) {
      // Start the video muted for autoplay compliance
      videoRef.current.muted = true;
      videoRef.current.play().catch((error) => {
        console.error("Error playing video:", error);
      });
    }
  }, []);

  const handleUnmute = () => {
    if (videoRef.current) {
      videoRef.current.muted = false;
      setIsMuted(false);
      videoRef.current.play().catch((error) => {
        console.error("Error playing video after unmuting:", error);
      });
    }
  };

  return (
    <div className="startupevent-bg">
      <div className="container">
        <div className="startupevent-container">
          <div className="startupevent-video-wrapper">
            <video
              className="startupevent-video-player"
              ref={videoRef}
              src={video}
              controls
              autoPlay
              loop
              playsInline
            />
            {isMuted && (
              <button className="unmute-button" onClick={handleUnmute}>
                <FaVolumeUp size={20} /> Unmute
              </button>
            )}
          </div>
          <div className="startupevent-social-links">
            <a
              href="https://www.altfcoworking.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaGlobe size={30} />
              <span className="social-label">Website</span>
            </a>
            <a
              href="https://www.facebook.com/altfcoworking"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebook size={30} />
              <span className="social-label">Facebook</span>
            </a>
            <a
              href="https://www.instagram.com/alt.f_coworking/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram size={30} />
              <span className="social-label">Instagram</span>
            </a>
            <a
              href="https://www.linkedin.com/company/altf-coworking"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin size={30} />
              <span className="social-label">LinkedIn</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Startupevent;
