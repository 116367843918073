import React, { useState, useEffect, useMemo,useContext } from "react";
import "./quiz.css";
import { Link, useNavigate } from "react-router-dom";
import { IoCloseCircle } from "react-icons/io5";
import { TokenContext } from "../tokenContext";
import axios from "axios";

const Quiz = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [answers, setAnswers] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    company: "",
    teamSize: "",
    location: "",
    budget: "",
    propertyname: "Quiz", 
  });
  const [showQuiz, setShowQuiz] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [randomProperty, setRandomProperty] = useState(null);
  const { csrfToken, getValidToken } = useContext(TokenContext);

  const navigate = useNavigate();

  const citiesData = useMemo(
    () => [
      {
        name: "Gurgaon",
        branches: [
          { slug: "altf-orchid-business-park", title: "Sohna Road, Gurgaon" },
          { slug: "altf-mpd-tower", title: "Golf Course Road, Gurgaon" },
          {
            slug: "altf-success-tower",
            title: "Golf Course Road Ext., Gurgaon",
          },
          { slug: "altf-global-business-park", title: "MG Road, Gurgaon" },
          { slug: "altf-empire-square", title: "MG Road, Gurgaon" },
          { slug: "altf-megacity", title: "MG Road, Gurgaon" },
          { slug: "altf-udyog-vihar", title: "Udyog Vihar, Gurgaon" },
        ],
      },
      {
        name: "Delhi",
        branches: [
          { slug: "altf-statesman-house", title: "Connaught Place, Delhi" },
          { slug: "altf-101-okhla", title: "Okhla, Delhi" },
          { slug: "altf-regal-building", title: "Connaught Place, Delhi" },
          { slug: "altf-mohan-cooperative", title: "Mohan Cooperative, Delhi" },
        ],
      },
      {
        name: "Noida",
        branches: [
          { slug: "altf-noida-58", title: "Sector 58, Noida" },
          { slug: "altf-noida-142", title: "Sector 142, Noida Expressway" },
          { slug: "altf-grovy-optiva", title: "Sector 68, Noida" },
          { slug: "altf-noida-62", title: "Sector 62, Noida" },
        ],
      },
      {
        name: "Hyderabad",
        branches: [
          {
            slug: "altf-financial-district",
            title: "alt.f Financial District",
          },
          { slug: "altf-hitec-city", title: "alt.f HITEC City" },
        ],
      },
    ],
    []
  );

  const steps = [
    {
      progress: "1/5 complete",
      question: "What’s your biggest challenge with your current office setup?",
      options: [
          {
            icon: "💰",
            label: "We’re paying too much for what we get",
            value: "costly"
          },
          {
            icon: "🌐",
            label: "We're isolated and missing a business vibe",
            value: "isolated"
          },
          {
            icon: "🔒",
            label: "No flexibility — leases, timings, everything is rigid",
            value: "inflexible"
          },
          {
            icon: "🏢",
            label: "It’s basic. We want something more professional",
            value: "basic"
          },
        ],
      buttonText: "Next",
    },
    {
      progress: "2/5 complete",
      question: "When do you need your private office ready?",
      options: [
        {
          icon: "⏰",
          label: "Immediately",
          value: "immediately"
        },
        {
          icon: "📅",
          label: "Within 30 days",
          value: "30days"
        },
        {
          icon: "🗓️",
          label: "Next quarter",
          value: "nextQuarter"
        },
        {
          icon: "🔮",
          label: "Planning phase",
          value: "planning"
        },
      ],
      buttonText: "Next",
    },
    {
      progress: "3/5 complete",
      question: "What's most important for your team's productivity?",
      options: [
        {
          icon: "🔒",
          label: "Privacy for focused work",
          value: "privacy"
        },
        {
          icon: "🤝",
          label: "Space for team collaboration",
          value: "collaboration"
        },
        {
          icon: "💼",
          label: "Professional client meetings",
          value: "clientMeetings"
        },
        {
          icon: "🏢",
          label: "Access to business amenities",
          value: "amenities"
        },
      ],
      buttonText: "Next",
    },
    {
      progress: "4/5 complete",
      question: "Which industry best describes your business?",
      options: [
        {
          icon: "💻",
          label: "Technology/IT",
          value: "tech"
        },
        {
          icon: "💼",
          label: "Finance/Consulting",
          value: "finance"
        },
        {
          icon: "🎨",
          label: "Creative/Marketing",
          value: "creative"
        },
        {
          icon: "📊",
          label: "Professional Services",
          value: "professional"
        },
        {
          icon: "❓",
          label: "Other",
          value: "other"
        },
      ],
      buttonText: "Next",
    },
    {
      progress: "5/5 complete",
      isForm: true,
      question: "We've identified private office solutions tailored to your business needs.",
      buttonText: "View Available Private Offices",
    },
  ];
  

  useEffect(() => {
    const hasTakenQuiz = localStorage.getItem("quizCompleted");
    const quizAttempts =
      parseInt(localStorage.getItem("quizAttempts"), 10) || 0;

    if (!hasTakenQuiz && quizAttempts < 3) {
      setTimeout(() => {
        setShowQuiz(true);
      }, 20000);
    }
  }, []);

  // Modified option selection: auto-advance for steps 0-3.
  const handleOptionSelect = (value) => {
    setAnswers({ ...answers, [currentStep]: value });
    if (currentStep < 4) {
      // Auto move to next question for the first four questions
      setTimeout(() => {
        setCurrentStep((prev) => prev + 1);
      }, 400);
    }
  };

  const handleNext = () => {
    if (!answers[currentStep]) return;
    setCurrentStep((prev) => prev + 1);
  };

  const handleBack = () => {
    if (currentStep > 0) setCurrentStep((prev) => prev - 1);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // const handleFormSubmit = async (e) => {
  //   e.preventDefault();
  //   localStorage.setItem("quizCompleted", "true");

  //   const mappedData = {
  //     fullName: formData.name,         
  //     mobile: formData.phone,         
  //     email: formData.email,          
  //     company: formData.company,         
  //     preferredCity: formData.location, 
  //     budget: formData.budget,              
  //     seatRequirement: formData.teamSize,
  //     propertyname: formData.propertyname, 
  //   };

  //   try {
  //     const token = await getValidToken();
  //     const response = await axios.post(
  //       "https://www.altfcoworking.com/api/enquiryform",
  //       mappedData,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "X-CSRF-Token": csrfToken,
  //         },
  //         withCredentials: true,
  //       }
  //     );

  //     if (response.data.success) {
       
  //     } else {
  //       alert("Failed to submit form. Please try again.");
  //     }
  //   } catch (error) {
  //     if (
  //       error.response &&
  //       error.response.status === 401 &&
  //       error.response.data.message ===
  //         "Token expired. Please refresh the page."
  //     ) {
  //       alert(
  //         "Your session has expired. Please refresh the page and try again."
  //       );
  //     } else {
  //       console.error("Error submitting form:", error.message);
  //       alert("Server error. Please try again later.");
  //     }
  //   } finally {
  //     setShowResult(true);
  //   }

  // };


  const handleFormSubmit = async (e) => {
    e.preventDefault();
    localStorage.setItem("quizCompleted", "true");
  
    // Map your data for your own backend submission
    const mappedData = {
      fullName: formData.name,
      mobile: formData.phone,
      email: formData.email,
      company: formData.company,
      preferredCity: formData.location,
      budget: formData.budget,
      seatRequirement: formData.teamSize,
      propertyname: formData.propertyname,
      reason:"Shared Space"
    };
  
    try {
      // Submit to your backend
      const token = await getValidToken();
      const backendResponse = await axios.post(
        "https://www.altfcoworking.com/api/enquiryform",
        mappedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-CSRF-Token": csrfToken,
          },
          withCredentials: true,
        }
      );
  
      if (!backendResponse.data.success) {
        alert("Failed to submit form. Please try again.");
        return;
      }
  
      // Now submit to the Zoho form.
      // Create a FormData object to mimic form submission
      const zohoData = {
        SingleLine4: formData.name,
        SingleLine5: formData.phone,
        SingleLine6: formData.email,
        SingleLine7: formData.company,
        SingleLine: answers[0] || "",    // Quiz Q1: Team Size
        SingleLine1: answers[1] || "",   // Quiz Q2: Timeline
        SingleLine2: answers[2] || "",   // Quiz Q3: Productivity
        SingleLine3: answers[3] || "",   // Quiz Q4: Industry
      };


  
      await axios.post("https://www.altfcoworking.com/api/quizanswers", zohoData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-CSRF-Token": csrfToken,
        },
        withCredentials: true,
      });
      
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.message === "Token expired. Please refresh the page."
      ) {
        alert("Your session has expired. Please refresh the page and try again.");
      } else {
        console.error("Error submitting form:", error.message);
        alert("Server error. Please try again later.");
      }
    } finally {
      setShowResult(true);
    }
  };
  


  const handleCloseResult = () => {
    setShowResult(false);
    setShowQuiz(false);
    setCurrentStep(0);
    setAnswers({});
    setFormData({
      name: "",
      email: "",
      phone: "",
      company: "",
      teamSize: "",
      location: "",
      budget: "",
    });
  };

  const handleCloseQuiz = () => {
    let quizAttempts = parseInt(localStorage.getItem("quizAttempts"), 10) || 0;
    quizAttempts += 1;
    if (quizAttempts >= 3) {
      localStorage.setItem("quizAttempts", "3");
    } else {
      localStorage.setItem("quizAttempts", quizAttempts.toString());
    }
    setShowQuiz(false);
    setShowResult(false);
    setCurrentStep(0);
    setAnswers({});
    setFormData({
      name: "",
      email: "",
      phone: "",
      company: "",
      teamSize: "",
      location: "",
      budget: "",
    });
  };

  useEffect(() => {
    if (formData.location) {
      const city = citiesData.find((city) => city.name === formData.location);
      if (city) {
        const randomBranch =
          city.branches[Math.floor(Math.random() * city.branches.length)];
        setRandomProperty(randomBranch);
      }
    }
  }, [formData.location, citiesData]);

  const handleRedirect = () => {
    setShowQuiz(false);
    setShowResult(false);
  };
  const teamSizeMapping = {
    3: "3-8",
    8: "8-20",
    20: "20+"
  };



  if (showResult) {
    return (
      <div className="quiz-overlay">
        <div className="quiz-modal">
          <div className="quiz-result-container">
            <button className="quiz-close-btn" onClick={handleCloseResult}>
              <IoCloseCircle className="closeicon-quiz" />
            </button>
            <div className="quiz-result-content">
              <div className="quiz-result-icon">🎉</div>
              <h2 className="quiz-result-heading">Private Office Solutions Identified</h2>
              <p className="quiz-result-message">
              Based on your requirements, we've found{" "}
              {randomProperty && (
                <p className="mb-0">
                  <Link
                    to={`/coworking-space/${formData.location.toLowerCase()}/${
                      randomProperty.slug
                    }`}
                    onClick={handleRedirect}
                  >
                    {randomProperty.title} 
                  </Link>
                </p>
              )} private office solutions in {formData.location}{" "}
              suited for your team of {teamSizeMapping[formData.teamSize]} members.
              </p>
              <p className="quiz-result-additional">
              Get 15 day rent free 
              </p>

              <p style={{fontSize:"12px"}}>Our office solutions consultant will contact you within 2 hours to discuss options and arrange a private tour.</p>
              
              <Link
                to={`/coworking-space/${formData.location.toLowerCase()}/`}
                onClick={handleRedirect}
              >
                <button className="quiz-result-btn">
                Explore other Workspaces in {formData.location}
                </button>
              </Link>
            </div>
            <Confetti />
          </div>
        </div>
      </div>
    );
  }

  if (!showQuiz || showResult) return null;

  return (
    <div className="quiz-overlay">
      <div className="quiz-modal">
        <button className="quiz-close-btn" onClick={handleCloseQuiz}>
          <IoCloseCircle className="closeicon-quiz" />
        </button>
       
        <h2 className="quiz-header">Find your Ideal Workspace in 17.3 seconds.<br/>
        <span style={{fontSize:"17px"}}>Yes, we timed it.</span>
         </h2>
        <ProgressBar
          currentStep={currentStep}
          totalSteps={steps.length}
          onDotClick={(step) => {
            // Allow navigation only backwards.
            if (step <= currentStep) setCurrentStep(step);
          }}
        />
        {/* Non-form steps */}
        {currentStep < steps.length && !steps[currentStep].isForm && (
          <div className="quiz-step">
            <p className="quiz-question">{steps[currentStep].question}</p>
            <div className="quiz-options">
              {steps[currentStep].options.map((option, idx) => (
                <button
                  key={idx}
                  className={`quiz-option ${
                    answers[currentStep] === option.value ? "selected" : ""
                  }`}
                  onClick={() => handleOptionSelect(option.value)}
                >
                  <span className="quiz-option-label">{option.label}</span>
                </button>
              ))}
            </div>
            {/* For steps 4 and onward, require manual navigation */}

            <div className=" quiz-navigation">
            {currentStep >= 1 && (
              <button
                className="quiz-next-btn quiz-back-btn"
                onClick={handleBack}
              >
                Back
              </button>
            )}
              {currentStep >= 4 && (
                <button
                  className="quiz-next-btn"
                  onClick={handleNext}
                  disabled={!answers[currentStep]}
                >
                  {steps[currentStep].buttonText}
                </button>
              )}
            </div>
          </div>
        )}
       
        {/* Form step */}
        {currentStep < steps.length && steps[currentStep].isForm && (
        <>
          <div className="quiz-step quiz-form-container" >
            <p className="quiz-question">{steps[currentStep].question}</p>
            <form className="quiz-form"  >
              <input
                type="text"
                name="name"
                placeholder="Full Name"
                value={formData.name}
                onChange={handleFormChange}
                required
                className="quiz-input"
              />
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleFormChange}
                required
                className="quiz-input"
              />
              <input
                type="tel"
                name="phone"
                placeholder="Mobile"
                value={formData.phone}
                onChange={handleFormChange}
                required
                className="quiz-input"
              />
              <input
                type="text"
                name="company"
                placeholder="Company"
                value={formData.company}
                onChange={handleFormChange}
                required
                className="quiz-input"
              />
              <select
                name="teamSize"
                value={formData.teamSize}
                onChange={handleFormChange}
                required
                className="quiz-select"
              >
                <option value="">Team Size</option>
                <option value={3}>Just me and a few geniuses (3-8)</option>
                <option value={8}>Growing team (8-20)</option>
                <option value={20}>Big family (20+)</option>
              </select>
              <select
                name="location"
                value={formData.location}
                onChange={handleFormChange}
                required
                className="quiz-select"
              >
                <option value="">Preferred Location</option>
                <option value="Delhi">Delhi - The Capital Hub</option>
                <option value="Noida">Noida</option>
                <option value="Gurgaon">Gurgaon</option>
                <option value="Hyderabad">Hyderabad</option>
              </select>
              <select
                name="budget"
                value={formData.budget}
                onChange={handleFormChange}
                required
                className="quiz-select"
              >
                <option value="">Monthly Budget</option>
               
                <option value="6,000&#x20;-&#x20;8,000&#x20;&#x2f;&#x20;Seat">
                ₹6,000 - ₹8,000 &#x2f; Seat
                </option>
                <option value="8,000&#x20;-&#x20;10,000&#x20;&#x2f;&#x20;Seat">
                ₹8,000 - ₹10,000 &#x2f; Seat
                </option>
                <option value="10,000&#x20;-&#x20;14,000&#x20;&#x2f;&#x20;Seat">
                ₹10,000 + &#x2f; Seat
                </option>
               
              </select>
          
            </form>
          </div>
          <div className="quiz-navigation">
                <button
                  className="quiz-next-btn quiz-back-btn"
                  onClick={handleBack}
                >
                  Back
                </button>
                <button onClick={handleFormSubmit} className="quiz-submit-btn">
                  {steps[currentStep].buttonText}
                </button>
              </div>
              </>
        )}

      </div>
    
    </div>
  );
};

const ProgressBar = ({ currentStep, totalSteps, onDotClick }) => {
  const progressPercent = (currentStep / (totalSteps - 1)) * 100;
  return (
    <div className="quiz-progress-container">
      <div className="quiz-progress-line">
        <div
          className="quiz-progress-filled"
          style={{ width: `${progressPercent}%` }}
        ></div>
      </div>
      <div className="quiz-progress-dots">
        {Array.from({ length: totalSteps }).map((_, idx) => (
          <div
            key={idx}
            className={`d-flex justify-content-center align-items-center quiz-progress-dot ${
              idx <= currentStep ? "active" : ""
            }`}
            onClick={() => {
              if (idx <= currentStep) onDotClick(idx);
            }}
          >
            {idx + 1}
          </div>
        ))}
      </div>
    </div>
  );
};

const Confetti = () => {
  const confettiPieces = Array.from({ length: 20 }, (_, i) => ({
    left: Math.random() * 100,
    delay: Math.random() * 3,
  }));

  return (
    <div className="quiz-confetti">
      {confettiPieces.map((piece, idx) => (
        <div
          key={idx}
          className="confetti-piece"
          style={{ left: `${piece.left}%`, animationDelay: `${piece.delay}s` }}
        ></div>
      ))}
    </div>
  );
};

export default Quiz;
