import { useEffect, useState } from "react";

const useLazyLoadOnScroll = (elementId) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const element = document.getElementById(elementId);
      if (element) {
        const rect = element.getBoundingClientRect();
        if (rect.top < window.innerHeight && !isVisible) {
          setIsVisible(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Check visibility on initial load too
    return () => window.removeEventListener("scroll", handleScroll);
  }, [elementId, isVisible]);

  return isVisible;
};

export default useLazyLoadOnScroll;
