import React,{useEffect} from "react";
import bgpic from "../assests/slide1.avif";
import img2 from "../assests/slide62.avif";
import mpic from '../assests/slidem6.avif'
import "./slide4.css";
import { MdArrowBackIosNew } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";

const Slide6 = ({onYearChange}) => {

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowRight') {
        onYearChange("2020");
      } else if (event.key === 'ArrowLeft') {
        onYearChange("2018");
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onYearChange]);
  return (
    <div >
    <div className="d-flex flex-row justify-content-between align-items-center arrowsintro">
        <div onClick={() => onYearChange("2018")}>
        <MdArrowBackIosNew style={{fontSize:"30px"}}/>
        </div>

        <div onClick={() => onYearChange("2020")}>
        <MdArrowForwardIos style={{fontSize:"30px"}}/>
        </div>
      </div>
      <div className="d-none d-lg-block">
        <div
          className="d-flex flex-row justify-content-center align-items-center containerheight"
          
        >
          <div  className="col-lg-6 d-flex flex-row justify-content-start align-items-center"
           >
            <div>
              <div
                className="d-flex flex-row justify-content-start align-items-center"
                style={{ marginLeft: "-40px" }}
              >
                <img src={bgpic} alt="img" className="slide4bg" />
                <div className="slide1heading " style={{marginTop:"20px"}}>
                <span style={{ fontSize: "2rem",fontWeight:"500" }}>
                 2019  
                  </span>
                  <br/>
                  <span style={{ marginLeft: "10px",fontSize:"56px" }}>Taming the Chaos</span>
                </div>
              </div>

              <div className="mt-4 slide4listitems">
                <ul>
                  <li>
                  Struggled with operational chaos but
                  <span style={{backgroundColor:"#D36161",marginLeft:"4px",color:"white"}}>focused on automating systems</span> in
                    operations, sales, and accounting.
                  </li>
                  <li>
                  Segregated responsibilities: I handled
                operations post-launch, while Yogesh
                focused on growth.
                  </li>
                  <h2 className="kuchmb mt-5" style={{textAlign:"start"}}>
                  Divided Roles, United Vision
          </h2>
                </ul>
               
              </div>

              {/* <div className="mt-5 lastparabox">
                <p className="slide4lastpara">
                  In 2016, we had 60 seats; today, we have 6,500, with 11,000
                  more signed. COVID hit hard, but we worked tirelessly, keeping
                  all centres open.
                </p>
                <hr className="lastborderline" />
              </div> */}
            </div>
          </div>

          <div className="col-lg-5 d-flex flex-row justify-content-center align-items-center" data-aos="zoom-in">
           <img src={mpic} alt="img" className="containerheight" style={{  maxWidth: "100%",objectFit:"contain" }}/>
          </div>
        </div>
      </div>

      <div className="d-block d-lg-none pt-5 moblievbox d-flex flex-column justify-content-cemter mbpt ">
        <div className="d-flex flex-row justify-content-center align-items-center pt-3 allpagemargintop">
          <div className="mbslide2heading text-center d-flex flex-column mbslide3heading" >
            <h2 className="mt-5">
            Taming the Chaos
            </h2>
          </div>
        </div>

     

       <div className="mbbggg">
       <div className="mt-4 slide4listitems">
                <ul>
                  <li>
                  Struggled with operational chaos but
                  <span style={{backgroundColor:"#D36161",marginLeft:"4px",color:"white"}}>focused on automating systems</span> in
                    operations, sales, and accounting.
                  </li>
                  <li>
                  Segregated responsibilities: I handled
                operations post-launch, while Yogesh
                focused on growth.
                  </li>
                 
                </ul>
                
              </div>
              <h4 className="kuchmb" style={{textAlign:"center"}}>
                  Divided Roles, United Vision
          </h4>


          <div
          className="pt-2 d-flex flex-row justify-content-center align-items-start"
         
        >
        {/* <div className=" d-flex flex-column justify-content-center" style={{height:"100%"}}>
            <img src={img1} alt="img" className="slide4pic4" />
          </div> */}
          <div className=" spacebetweenpic d-flex flex-column justify-content-center pb-3" style={{height:"100%"}}>
            <img src={img2} alt="img" className="slide2img4" />
           
          </div>
        </div>
       </div>
      </div>
    </div>
  );
};

export default Slide6;
