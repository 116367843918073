import React from "react";
import "./channel-partners.css";
import StandsOut from "./standout";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

const Channelpartners = () => {
  const scrollToForm = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="container" style={{ padding: "0px" }}>
      <div className="easylinks mb-3 workspacesolutions channel-partnerseasy">
        <Link to="/" className="custom-link">
          Home
        </Link>{" "}
        / <span>Channel-Partners</span>
      </div>

      <Helmet>
        <title>Join Our Channel Partners Program - Earn ₹6,000/seat*</title>
        <meta name="description" content="Partner with alt.f coworking and earn ₹6,000/seat*. Join our Channel Partners Program to unlock exclusive benefits and grow your business." />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.altfcoworking.com/channel-partners/" />
        <meta name="author" content="alt.f coworking" />
        <meta name="publisher" content="alt.f coworking" />
        <meta name="keywords" content="Channel Partners, Brokers, Dealers, Sales Partner" />

        {/* Open Graph Meta Tags (For Facebook, LinkedIn, etc.) */}
        <meta property="og:title" content="Join Our Channel Partners Program - Earn ₹6,000/seat*" />
        <meta property="og:description" content="Partner with alt.f coworking and earn ₹6,000/seat*. Join our Channel Partners Program to unlock exclusive benefits and grow your business." />
        <meta property="og:image" content="https://www.altfcoworking.com/static/media/header-logo.a8b7d89779a89ca6b579.avif" />
        <meta property="og:url" content="https://www.altfcoworking.com/channel-partners/" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="alt.f coworking" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Join Our Channel Partners Program - Earn ₹6,000/seat*" />
        <meta name="twitter:description" content="Partner with alt.f coworking and earn ₹6,000/seat*. Join our Channel Partners Program to unlock exclusive benefits and grow your business." />
        <meta name="twitter:image" content="https://www.altfcoworking.com/static/media/header-logo.a8b7d89779a89ca6b579.avif" />
        <meta name="twitter:image:alt" content="Join Our Channel Partners Program - Earn ₹6,000/seat*" />
        <meta name="twitter:site" content="@altfcoworking" />
        <meta name="twitter:creator" content="@altfcoworking" />
      </Helmet>


      <div className="channel-partners-container">
        <div className="channel-partners-overlay">
          <div className="channel-partners-content col-8">
            <h1 className="channel-partners-heading">Earn ₹6,000/seat</h1>
            <p className="channel-partners-text">
              Join our channel partner program
            </p>
          </div>

          {/* <form 
            class="channel-partners-form-control col-4"
            action="https://forms.zohopublic.com/altfcoworking/form/Brokersform/formperma/i1qUzujIObqWLqKiUnBXJBVfWY69O9kw5hES6UY04Lo/htmlRecords/submit"
            name="form"
            method="POST"
            accept-charset="UTF-8"
            enctype="multipart/form-data"
            id="form"
          
          >
            <input type="hidden" name="zf_referrer_name" value=""/>
            <input type="hidden" name="zf_redirect_url" value="https://www.altfcoworking.com/thank-you/"/>
            <input type="hidden" name="zc_gad" value=""/>

            <p class="channel-partners-title">Join our Program</p>

            <div class="channel-partners-input-field">
              <input
                required
                class="channel-partners-input"
                type="text"
                name="SingleLine"
                maxlength="255"
              />
              <label class="channel-partners-label" for="SingleLine">
                Enter Full Name
              </label>
            </div>

            <div class="channel-partners-input-field">
              <input
                required
                class="channel-partners-input"
                type="text"
                name="PhoneNumber_countrycode"
                
                maxlength="20"
                valType="number"
              />
              <label
                class="channel-partners-label"
                for="PhoneNumber_countrycode"
              >
                Enter Mobile
              </label>
            </div>

            <div class="channel-partners-input-field">
              <input
                required
                class="channel-partners-input"
                type="email"
                name="Email"
                maxlength="255"
              />
              <label class="channel-partners-label" for="Email">
                Enter Email
              </label>
            </div>

            <div class="channel-partners-input-field">
              <select  name="Dropdown2" required>
                <option selected value="" disabled>
                  Preferred City*
                </option>
                <option value="Gurgaon">Gurgaon</option>
                <option value="Noida">Noida</option>
                <option value="Delhi">Delhi</option>
                <option value="Hyderabad">Hyderabad</option>
              </select>
            </div>

            <button class="channel-partners-submit-btn">Let's Go!</button>
          </form> */}

          <form
            action="https://forms.zohopublic.com/altfcoworking/form/brokerform/formperma/USYnRLbc9sY6-v0UnJWRQzj04e6N-BZs3XO15ePwscw/htmlRecords/submit"
            name="form"
            class="channel-partners-form-control col-4"
            id="form"
            method="POST"
            accept-charset="UTF-8"
            enctype="multipart/form-data"
          >
            <input type="hidden" name="zf_referrer_name" value="" />
            <input
              type="hidden"
              name="zf_redirect_url"
              value="https://www.altfcoworking.com/thank-you/"
            />
            <input type="hidden" name="zc_gad" value="" />
            <input type="hidden" name="utm_source" value="" />
            <input type="hidden" name="utm_medium" value="" />
            <input type="hidden" name="utm_campaign" value="" />
            <input type="hidden" name="utm_term" value="" />
            <input type="hidden" name="utm_content" value="" />

            <p class="channel-partners-title">Join our Program</p>

            <div class="channel-partners-input-field">
              <input
                required
                class="channel-partners-input"
                type="text"
                name="SingleLine"
                maxlength="255"
              />
              <label class="channel-partners-label" for="SingleLine">
                Enter Full Name
              </label>
            </div>

            <div class="channel-partners-input-field">
              <input
                required
                class="channel-partners-input"
                type="text"
                name="PhoneNumber_countrycode"
                maxlength="20"
                valType="number"
              />
              <label
                class="channel-partners-label"
                for="PhoneNumber_countrycode"
              >
                Enter Mobile
              </label>
            </div>

            <div class="channel-partners-input-field">
              <input
                required
                class="channel-partners-input"
                type="email"
                name="Email"
                maxlength="255"
              />
              <label class="channel-partners-label" for="Email">
                Enter Email
              </label>
            </div>

            <div class="channel-partners-input-field">
              <select name="Dropdown2" required>
                <option selected value="" disabled>
                  Preferred City*
                </option>
                <option value="Gurgaon">Gurgaon</option>
                <option value="Noida">Noida</option>
                <option value="Delhi">Delhi</option>
                <option value="Hyderabad">Hyderabad</option>
              </select>
            </div>

            <button class="channel-partners-submit-btn">
              <em>Submit</em>
            </button>
          </form>
        </div>
      </div>

      <div className="channel-partnerssecondsection">
        <h2>Why partner with alt.f ?</h2>

        <div className="channel-partners-info-section ">
          <div className="d-flex flex-column align-items-center justify-content-center channel-partners-faq-section">
            <h4>Automated payments</h4>
          </div>

          <div className="d-flex flex-column align-items-center justify-content-center channel-partners-faq-section">
            <h4>Get brokerage for upto 24 months*</h4>
          </div>

          <div className="d-flex flex-column align-items-center  justify-content-center channel-partners-faq-section">
            <h4>Standard pricing</h4>
          </div>
        </div>
      </div>

      <StandsOut />

      <div className="banner-channel">
        <h5>
          Schedule a call with our sales team to know more about our channel
          partner program
        </h5>
        <button
          className="expapantionbtn channelexplorebtn"
          onClick={scrollToForm}
        >
          Talk To Our Sales Team
        </button>
      </div>

      <div className="bottom-lanloard-btn"></div>
    </div>
  );
};

export default Channelpartners;
