import React, { useState, useContext, useEffect } from "react";
import "./pop-up-enquiry.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { TokenContext } from "../tokenContext";



const EnquiryFormModal = ({ isOpen,propertyname, onClose }) => {
  const [formData, setFormData] = useState({
    fullName: "",
    mobile: "",
    email: "",
    reason: "",
    preferredCity: "",
    seatRequirement: 0,
    receiveUpdates: false,
    propertyname:""
  });
  // const [csrfToken, setCsrfToken] = useState("");
    const { csrfToken, getValidToken } = useContext(TokenContext);
    const [isSubmitting, setIsSubmitting] = useState(false);
  
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();


  // useEffect(() => {
  //   const fetchToken = async () => {
  //     try {
  //       const response = await axios.get(
  //         "https://www.altfcoworking.com/api/generate-token"
  //       );
  //       const jwtToken = response.data.token;
  //       localStorage.setItem("authToken", jwtToken);
  //     } catch (error) {
  //       console.error("Error fetching JWT token:", error.message);
  //     }
  //   };

  //   fetchToken();
  // }, []);

  // useEffect(() => {
  //   const fetchCsrfToken = async () => {
  //     try {
  //       const response = await axios.get("https://www.altfcoworking.com/api/csrf-token", {
  //         withCredentials: true,
  //       });
  //       setCsrfToken(response.data.csrfToken);
  //     } catch (error) {
  //       console.error("Error fetching CSRF token:", error.message);
  //     }
  //   };

  //   fetchCsrfToken();
  // }, []);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
  
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" 
        ? checked 
        : type === "radio" && name === "seatRequirement" 
          ? Number(value) 
          : value,  // Keep "reason" and other radio fields as strings
    }));
  };
  
  
  // const propertyCodeMapping = {
  //   "Orchid Business Park, Sector 48": "alt.f Orchid Business Park",
  //   "2nd Floor, MPD Tower": "alt.f MPD Tower",
  //   "Suncity Success Towers": "alt.f Success Tower",
  //   "Tower D, Global Business Park": "alt.f Global Business Park",
  //   "JMD Empire Square": "alt.f Empire Square",
  //   "MGF Megacity Mall": "alt.f Megacity, MG Road",
  //   "Shankar Chowk Rd, Sector 19": "alt.f 217, Udyog Vihar",
  
  //   "101, NH-19, Ishwar Nagar": "alt.f 101 Okhla",
  //   "Statesman House": "alt.f Statesman House, CP",
  //   "Plot No. A-26, Block B": "alt.f Mohan Cooperative",
  //   "Regal Building": "alt.f - Regal",
  
  //   "A100, A Block": "alt.f Noida 58",
  //   "Plot No. 21 & 21A": "alt.f Noida 142",
  //   "Grovy Optiva": "alt.f Grovy Optiva, Sec-68 Noida",
  //   "Express Trade Tower 2": "alt.f Noida 132",
  //   "C-20, 1/1A, Coast Guard Golf Ground Rd, Sector 62, Noida": "alt.f - Noida 62",
  
  //   "9th Floor,Kapil Kavuri Hub.144,Hyderabad": "alt.f Financial District Hyderabad",
  //   "": "Upcoming Location",
  // };

  const propertyCodeMapping = {
    "Sohna Road, Sector 48, near Subhash Chowk, Gurgaon": "AltF Orchid Business Park",
    "2nd Floor, MPD Tower": "AltF MPD Tower",
    "Suncity Success Towers": "AltF Success Tower",
    "Tower D, Global Business Park": "AltF Global Business Park",
    "JMD Empire Square": "AltF Empire Square",
    "MGF Megacity Mall": "AltF Megacity, MG Road",
    "Shankar Chowk Rd, Sector 19": "AltF 217, Udyog Vihar",
    
    "101, NH-19, Ishwar Nagar": "AltF 101 Okhla",
    "Statesman House": "AltF Statesman House, CP",
    "Plot No. A-26, Block B": "AltF Mohan Cooperative",
    "Regal Building": "AltF - Regal",
  
    "A100, A Block": "AltF Noida 58",
    "Plot No. 21 & 21A": "AltF Noida 142",
    "Grovy Optiva": "AltF Grovy Optiva, Sec-68 Noida",
    "Express Trade Tower 2": "AltF Noida 132",
    "C-20, 1/1A, Coast Guard Golf Ground Rd, Sector 62, Noida": "AltF - Noida 62",
  
    "9th Floor, Kapil Kavuri Hub.144, Hyderabad": "AltF Financial District Hyderabad",
  
    // Additional mappings for Zoho Form's "Dropdown3" format
    "Empire Square-Get in Touch": "AltF Empire Square",
    "Megacity-Get in Touch": "AltF Megacity, MG Road",
    "GBP-Get in Touch": "AltF Global Business Park",
    "Success Tower-Get in Touch": "AltF Success Tower",
    "Plaza-Get in Touch": "AltF Plaza",
    "Grovy Optiva-Get in Touch": "AltF Grovy Optiva, Sec-68 Noida",
    "Express Trade Tower-Get in Touch": "AltF Noida 132",
    "Statesman-Get in Touch": "AltF Statesman House, CP",
    "MPD-Get in Touch": "AltF MPD Tower",
    "Noida 142-Get in Touch": "AltF Noida 142",
  
    // Other possible locations
    "AltF Plaza": "AltF Plaza",
    "AltF Global Foyer": "AltF Global Foyer",
    "AltF 101 Okhla": "AltF 101 Okhla",
    "AltF 217, Udyog Vihar": "AltF 217, Udyog Vihar",
    "AltF Managed Offices": "AltF Managed Offices",
    "AltF Past Locations": "AltF Past Locations",
    "AltF Financial District Hyderabad": "AltF Financial District Hyderabad",
    "AltF - Regal": "AltF - Regal",
    "AltF - Noida 62": "AltF - Noida 62",
    "AltF - Hitec City Hyderabad": "AltF - Hitec City Hyderabad",
  
    // Default fallback
    "": "Upcoming Location"
  };
  
  


  useEffect(() => {
    if (propertyname) {
      const normalizePropertyName = propertyname.replace(/\s+/g, ' ').trim();
      const uniqueCode = propertyCodeMapping[normalizePropertyName] || "Upcoming Location";
      
      setFormData((prevData) => ({
        ...prevData,
        propertyname: uniqueCode,
      }));
    } else {
      // Reset propertyname when there's no title
      setFormData((prevData) => ({
        ...prevData,
        propertyname: "",  // Clear propertyname if title is empty
      }));
    }
  }, [propertyname]);


  


  const validate = () => {
    const newErrors = {};

    if (!formData.fullName || !formData.fullName.trim()) {
      newErrors.fullName = "Full Name is required.";
    }

    if (!formData.mobile || formData.mobile.trim().length < 10) {
      newErrors.mobile = "Valid Mobile number is required.";
    }

    if (!formData.email || !/^\S+@\S+\.\S+$/.test(formData.email.trim())) {
      newErrors.email = "Valid email is required.";
    }

    if (!formData.preferredCity) {
      newErrors.preferredCity = "Preferred City is required.";
    }

    if (!formData.reason) {
      newErrors.reason = "Please select an option.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    // const token = localStorage.getItem("authToken"); 
    setIsSubmitting(true); 
    try {
      const token = await getValidToken();
      const response = await axios.post(
        "https://www.altfcoworking.com/api/enquiryform",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-CSRF-Token": csrfToken,
          },
          withCredentials: true,
        }
      );

      if (response.data.success) {
        setTimeout(() => {
          navigate(`/thank-you`);
        }, 100);
      } else {
        alert("Failed to submit form. Please try again.");
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.message ===
          "Token expired. Please refresh the page."
      ) {
        alert(
          "Your session has expired. Please refresh the page and try again."
        );
      } else {
        console.error("Error submitting form:", error.message);
        alert("Server error. Please try again later.");
      }
    } finally {
      setIsSubmitting(false); // Reset loading state
    }

  };

  const handleOverlayClick = (e) => {
    if (e.target.className === "enquiry-pop-up-overlay") {
      onClose();
    }
  };

  if (!isOpen) return null; 

  return (
    <>
      <div className="enquiry-pop-up-overlay" onClick={handleOverlayClick}>
        <div className="pop-up-content">
          <button
            className="pop-up-close-button"
            onClick={onClose}
          >
            &times;
          </button>
          <div className="pop-up-form-container">
            <h2 className="pop-up-form-heading">Submit an Enquiry</h2>
            <form onSubmit={handleSubmit}>
              <div className="pop-up-input-group">
                <input
                  type="text"
                  name="fullName"
                  autoComplete="off"
                  className="pop-up-input"
                  value={formData.fullName}
                  onChange={handleInputChange}
                />
                <label className="pop-up-label">Full Name*</label>
                {errors.fullName && (
                  <p className="pop-up-error-text">{errors.fullName}</p>
                )}
              </div>
  
              <div className="pop-up-input-group">
                <input
                  type="tel"
                  name="mobile"
                  className="pop-up-input"
                  value={formData.mobile}
                  onChange={handleInputChange}
                />
                <label className="pop-up-label">Mobile*</label>
                {errors.mobile && (
                  <p className="pop-up-error-text">{errors.mobile}</p>
                )}
              </div>
  
              <div className="pop-up-input-group">
                <input
                  type="email"
                  name="email"
                  autoComplete="off"
                  className="pop-up-input"
                  value={formData.email}
                  onChange={handleInputChange}
                />
                <label className="pop-up-label">Email*</label>
                {errors.email && (
                  <p className="pop-up-error-text">{errors.email}</p>
                )}
              </div>
  
              <p>What brings you to alt.f coworking?</p>
              <div className="pop-up-radio-group">
                <label>
                  <input
                    type="radio"
                    name="reason"
                    value="Shared Space"
                    checked={formData.reason === "Shared Space"}
                    onChange={handleInputChange}
                  />
                  Coworking Space
                </label>
                <label>
                  <input
                    type="radio"
                    name="reason"
                    value="Virtual Office - Business Address"
                    checked={formData.reason === "Virtual Office - Business Address"}
                    onChange={handleInputChange}
                  />
                  Virtual Office
                </label>
              </div>
              {errors.reason && <p className="pop-up-error-text">{errors.reason}</p>}
  
              {formData.reason === "Shared Space" && (
                <div className="pop-up-seat-requirement mt-3">
                  <p>How many Seats do you require?</p>
                  <div className="pop-up-radio-group">
                    <label>
                      <input
                        type="radio"
                        name="seatRequirement"
                        value= {3}
                        checked={formData.seatRequirement === 3}
                        onChange={handleInputChange}
                      />
                      03-08
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="seatRequirement"
                        value= {8}
                        checked={formData.seatRequirement === 8}
                        onChange={handleInputChange}
                      />
                      08-20
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="seatRequirement"
                        value= {20}
                        checked={formData.seatRequirement === 20}
                        onChange={handleInputChange}
                      />
                      20+
                    </label>
                  </div>
                </div>
              )}
  
              <div className="pop-up-input-group mt-3">
                <label>
                  Preferred City*
                  <select
                    name="preferredCity"
                    value={formData.preferredCity}
                    onChange={handleInputChange}
                    className="pop-up-select"
                  >
                    <option value="">-Select-</option>
                    <option value="Gurgaon">Gurgaon</option>
                    <option value="Delhi">Delhi</option>
                    <option value="Noida">Noida</option>
                    <option value="Hyderabad">Hyderabad</option>
                  </select>
                </label>
              </div>
  
              <p className="privacy-notice d-flex align-items-start">
              <input
                  type="checkbox"
                  name="receiveUpdates"
                  checked={formData.receiveUpdates}
                  onChange={handleInputChange}
                />
                <span>
                By submitting this form, you agree to our Website <Link to="/terms-and-conditions/" target="_blank">Terms and Conditions</Link> and acknowledge our <Link to="/privacy-policy/" target="_blank">Privacy Policy</Link>.
                </span>
              </p>
  
              <button type="submit" className="pop-up-submit-button mt-4">
                {isSubmitting ? "Submitting..." : "Submit"}
              </button>
            </form>
          </div>
        </div>
      </div>

  </>
  
  );
};

export default EnquiryFormModal;
