import React, { useEffect, useState } from "react";
import "./pop-up-enquiry.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import numberimage from "../../../assests/footerpages/businnesspartner/numberimg.png";

const EnquiryFormModal = ({ isOpen, onClose }) => {
  const [selectedDate, setSelectedDate] = useState(null); // For calendar
  const [selectedHour, setSelectedHour] = useState(""); // For time hour
  const [selectedMinute, setSelectedMinute] = useState(""); // For time minute
  const [selectedAmPm, setSelectedAmPm] = useState(""); // For AM/PM

  // const handleFormSubmit = (e) => {
  //   e.preventDefault();

  //   if (!selectedDate || !selectedHour || !selectedMinute || !selectedAmPm) {
  //     alert("Please fill in all fields.");
  //     return;
  //   }

  //   const dateInput = document.getElementById("zoho-date");
  //   const hourInput = document.getElementById("zoho-hour");
  //   const minuteInput = document.getElementById("zoho-minute");
  //   const amPmInput = document.getElementById("zoho-ampm");

  //   if (dateInput && hourInput && minuteInput && amPmInput) {
  //     dateInput.value = selectedDate
  //       ? selectedDate.toLocaleDateString("en-GB") // Convert to DD/MM/YYYY
  //       : "";
  //     hourInput.value = selectedHour;
  //     minuteInput.value = selectedMinute;
  //     amPmInput.value = selectedAmPm;
  //   } else {
  //     console.error("One or more hidden input elements are missing.");
  //     return;
  //   }

  //   e.target.submit();
  // };

  const handleFormSubmit = (e) => {
    e.preventDefault();
  
    // Ensure all required fields are filled
    if (!selectedDate || !selectedHour || !selectedMinute || !selectedAmPm) {
      alert("Please fill in all fields.");
      return;
    }
  
    // Get form data
    const formData = new FormData(e.target);
  
    // Convert selected values to the correct format before submission
    const availableArea = parseInt(formData.get("COBJ13CF55"), 10) || null;
  
    // Get hidden input elements for date and time
    const dateInput = document.getElementById("zoho-date");
    const hourInput = document.getElementById("zoho-hour");
    const minuteInput = document.getElementById("zoho-minute");
    const amPmInput = document.getElementById("zoho-ampm");
  
    if (dateInput && hourInput && minuteInput && amPmInput) {
      // Update hidden input values
      dateInput.value = selectedDate
        ? selectedDate.toLocaleDateString("en-GB") // Convert to DD/MM/YYYY
        : "";
      hourInput.value = selectedHour;
      minuteInput.value = selectedMinute;
      amPmInput.value = selectedAmPm;
    } else {
      console.error("One or more hidden input elements are missing.");
      return;
    }
  
    // Debugging: Log form data before submission
    console.log("Submitting Form Data:", {
      ...Object.fromEntries(formData),
      COBJ13CF55: availableArea, // Ensure it's submitted as a number
    });
  
    // Manually append the corrected value back to the form
    const availableAreaInput = document.getElementById("COBJ13CF55");
    if (availableAreaInput) {
      availableAreaInput.value = availableArea; // Ensure Zoho receives a numeric value
    }
  
    // Submit the form
    e.target.submit();
  };
  


  const handleOverlayClick = (e) => {
    if (e.target.className === "enquiry-pop-up-overlay") {
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <>
      <div className="enquiry-pop-up-overlay" onClick={handleOverlayClick}>
        <div className="pop-up-content">
          <button className="pop-up-close-button" onClick={onClose}>
            &times;
          </button>
          <div className="pop-up-form-container">
            <h2 className="pop-up-form-heading">Monetise Your Property</h2>
            <div className="text-center mb-3">
              <img src={numberimage} alt="img" className="numberimglandlord" />
            </div>
            {/* Zoho CRM Web Form */}
            <form
              action="https://crm.zoho.com/crm/WebForm"
              method="POST"
              onSubmit={handleFormSubmit}
            >
              {/* Hidden Fields for Zoho CRM */}
              <input
                type="hidden"
                name="xnQsjsdp"
                value="4a78fc0c027ccf52c881a3fa9c2370f6daa7282d95cb3dae2f716ee301365cd7"
              />
              <input
                type="hidden"
                name="xmIwtLD"
                value="62555b9578f5fbb9ea0930240e9d1fc252e776f397060c1cb8b31552ef735b84"
              />
              <input
                type="hidden"
                name="actionType"
                value="Q3VzdG9tTW9kdWxlMTM="
              />
              <input type="hidden" id="zoho-date" name="COBJ13CF119" />
              <input type="hidden" id="zoho-hour" name="COBJ13CF119hour" />
              <input type="hidden" id="zoho-minute" name="COBJ13CF119minute" />
              <input type="hidden" id="zoho-ampm" name="COBJ13CF119ampm" />
              <input
                type="hidden"
                name="returnURL"
                value="https://altfcoworking.com/thank-you"
              />

              <div className="pop-up-input-group">
                <label>Name*</label>
                <input
                  type="text"
                  name="NAME"
                  className="pop-up-input"
                  required
                />
              </div>

              <div className="pop-up-input-group">
                <label>Email*</label>
                <input
                  type="email"
                  name="Email"
                  className="pop-up-input"
                  required
                />
              </div>

              <div className="pop-up-input-group">
                <label>Mobile*</label>
                <input
                  type="number"
                  name="COBJ13CF21"
                  className="pop-up-input"
                  required
                />
              </div>

              <div className="pop-up-input-group">
                <label>City</label>
                <select name="COBJ13CF18" className="pop-up-select">
                  <option value="">-Select-</option>
                  <option value="Delhi">Delhi</option>
                  <option value="Gurgaon">Gurgaon</option>
                  <option value="Noida">Noida</option>
                  <option value="Mumbai">Mumbai</option>
                  <option value="Hyderabad">Hyderabad</option>
                  <option value="Bangalore">Bangalore</option>
                  <option value="Pune">Pune</option>
                  <option value="Chennai">Chennai</option>
                  <option value="Kolkata">Kolkata</option>
                  <option value="Jaipur">Jaipur</option>
                  <option value="Other">Other</option>
                </select>
              </div>

              {/* <div class="zcwf_row">
                <div class="zcwf_col_lab">
                  <label for="COBJ13CF55">
                    Available Area (in sqft.)
                    <span>*</span>
                  </label>
                </div>
                <div class="zcwf_col_fld">
                  <input
                    className="pop-up-input"
                    type="number"
                    id="COBJ13CF55"
                    aria-required="true"
                    aria-label="COBJ13CF55"
                    name="COBJ13CF55"
                    aria-valuemax="9"
                    maxlength="9"
                    placeholder="Enter numeric value"
                  ></input>
                  <div class="zcwf_col_help"></div>
                </div>
              </div> */}

              <div class="zcwf_col_lab">
                  <label for="COBJ13CF55">
                    Available Area (in sqft.)
                    <span>*</span>
                  </label>
                </div>

              <select
                className="pop-up-select"
                id="COBJ13CF55"
                name="COBJ13CF55"
                aria-required="true"
              >
                <option value="">-Select-</option>
                <option value="5000">5,000 - 10,000</option>
                <option value="10000">10,000 - 20,000</option>
                <option value="20000">20,000 - 30,000</option>
                <option value="30000">30,000 - 40,000</option>
                <option value="40000">40,000+</option>
              </select>

              <div>
                <div className="pop-up-input-group mt-3">
                  <label>Schedule a Call</label>
                  <br />
                  <DatePicker
                    selected={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    dateFormat="dd/MM/yyyy" // Ensures Zoho-compatible format
                    placeholderText="DD/MM/YYYY"
                    className="pop-up-input"
                    minDate={new Date()}
                  />
                </div>

                <div className="pop-up-input-group">
                  <div style={{ display: "flex", gap: "10px" }}>
                    <select
                      name="COBJ13CF119hour"
                      className="pop-up-input"
                      value={selectedHour}
                      onChange={(e) => setSelectedHour(e.target.value)}
                      style={{ width: "100px" }}
                    >
                      <option value="">Hour</option>
                      {[...Array(12)].map((_, i) => (
                        <option
                          key={i + 1}
                          value={String(i + 1).padStart(2, "0")}
                        >
                          {String(i + 1).padStart(2, "0")}
                        </option>
                      ))}
                    </select>

                    <select
                      name="COBJ13CF119minute"
                      className="pop-up-input"
                      value={selectedMinute}
                      onChange={(e) => setSelectedMinute(e.target.value)}
                      style={{ width: "120px" }}
                    >
                      <option value="">Minute</option>
                      {[...Array(60)].map((_, i) => (
                        <option key={i} value={String(i).padStart(2, "0")}>
                          {String(i).padStart(2, "0")}
                        </option>
                      ))}
                    </select>

                    <select
                      name="COBJ13CF119ampm"
                      className="pop-up-input"
                      value={selectedAmPm}
                      onChange={(e) => setSelectedAmPm(e.target.value)}
                      style={{ width: "100px" }}
                    >
                      <option value="">AM/PM</option>
                      <option value="AM">AM</option>
                      <option value="PM">PM</option>
                    </select>
                  </div>
                </div>
              </div>

              {/* <div className="pop-up-input-group">
                <label>Schedule a Call (Date)*</label>
                <input 
                  type="text" 
                  name="COBJ13CF119" 
                  className="pop-up-input" 
                  placeholder="DD/MM/YYYY"
                  pattern="\d{2}/\d{2}/\d{4}" 
                  required
                />
              </div>

              <div className="pop-up-input-group">
                <label>Schedule a Call (Time)*</label>
                <select name="COBJ13CF119hour" className="pop-up-input" required>
                  {[...Array(12)].map((_, i) => (
                    <option key={i + 1} value={String(i + 1).padStart(2, "0")}>
                      {String(i + 1).padStart(2, "0")}
                    </option>
                  ))}
                </select>

                <select name="COBJ13CF119minute" className="pop-up-input" required>
                  {[...Array(60)].map((_, i) => (
                    <option key={i} value={String(i).padStart(2, "0")}>
                      {String(i).padStart(2, "0")}
                    </option>
                  ))}
                </select>

                <select name="COBJ13CF119ampm" className="pop-up-input" required>
                  <option value="AM">AM</option>
                  <option value="PM">PM</option>
                </select>
              </div> */}

              <br />

              <button type="submit" className="pop-up-submit-button">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnquiryFormModal;
