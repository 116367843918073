import "./App.css";
import './components/altfkey-word/altfkey.css';
import { HelmetProvider } from "react-helmet-async";
import Navbar from "./components/navbar/navbar";
import "./components/navbar/navbar.css";
import Home from "./pages/home/home";
import stickyimg from "./assests/home/sticky.avif";
import "./pages/home/faq.css";
import "./pages/workspace-solutions/virtualoffice parent/Virtualoffice/virtual-office.css";
import "./pages/workspace-solutions/workspacesolutions.css";
import "./components/enquiryform/enquiryform.css";
import "./pages/workspace-solutions/virtualoffice parent/Virtualoffice/voslider.css";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  Link,
  useLocation,
  matchPath,
} from "react-router-dom";
import React, { Suspense, useEffect, useMemo, useState } from "react";
import ScrollToTopdefault from "./components/srolltotopdefault";
import Pageimportantlinks from "./pages/imp-links/page-important-links/page-important-links";
import Termsofuse from "./pages/imp-links/terms-of-use/terms-of-use";
import TimelineNavigation from "./pages/landingpages/our-story/components/timelinenavigation";
import VisitingCards from "./pages/landingpages/business-card/pages/visititngcards";
import useLoadScripts from "./loadscripts";
import Landlord from "./pages/fotter-pages/landlord/landlord";
import Channelpartners from "./pages/fotter-pages/channel-partners/channel-partners";
import { TokenProvider } from "./components/tokenContext";
import Offer from "./components/offerpage/offer";
import Altfkey from "./components/altfkey-word/altfkeys";
import Walkinbook from "./components/walkin-book/walkinbook";
import Blogdetailspage from "./pages/blogs/blog-detail-page/blogdetailspage";
import Homeblog from "./pages/blogs/homeblog/homeblog";
import Officespaces from "./pages/fotter-pages/office-spaces/office-spaces";
import Quiz from "./components/quiz/quiz";
import Startupevent from "./pages/other-pages/startup-event";
const Noidalandingpage = React.lazy(() =>
  import("./pages/landingpages/noida-landing-page/noidalandingpage")
);
const Virtualofficelangingpage = React.lazy(() =>
  import(
    "./pages/landingpages/virtual-office-landing-page/virtual-office-langing-page"
  )
);
const Alllocationslp = React.lazy(() =>
  import(
    "./pages/landingpages/location-landing-pages/components/coworking-space/alllocationslp"
  )
);
const Workspacesolutions = React.lazy(() =>
  import("./pages/workspace-solutions/workspacesolutions")
);
const Virtualoffice = React.lazy(() =>
  import(
    "./pages/workspace-solutions/virtualoffice parent/Virtualoffice/virtual-office"
  )
);
const VirtualOfficeRouter = React.lazy(() =>
  import("./pages/workspace-solutions/virtualoffice parent/virtualofficerouter")
);
const Enquiryform = React.lazy(() =>
  import("./components/enquiryform/enquiryform")
);
const Careers = React.lazy(() =>
  import("./pages/fotter-pages/careers/careers")
);
const PrivateOffice = React.lazy(() =>
  import("./pages/workspace-solutions/private-office/private-office")
);
const Tabspo = React.lazy(() =>
  import("./pages/workspace-solutions/private-office/tabs/tabs")
);
const FlexibleOffice = React.lazy(() =>
  import("./pages/workspace-solutions/flexible-office/flexible-office")
);
const Daypass = React.lazy(() =>
  import("./pages/workspace-solutions/flexible-office/tabs/dayPassBundles")
);
const Meetingroom = React.lazy(() =>
  import("./pages/workspace-solutions/flexible-office/tabs/meethingroom")
);
const Privacypolicy = React.lazy(() =>
  import("./pages/fotter-pages/Policy's/Privacy Policy/privacypolicy")
);
const Refundpolicy = React.lazy(() =>
  import("./pages/fotter-pages/Policy's/Refund Policy/refundpolicy")
);
const Termsandconditions = React.lazy(() =>
  import(
    "./pages/fotter-pages/Policy's/Terms and conditions/termsandconditions"
  )
);
const Faqft = React.lazy(() => import("./pages/fotter-pages/faq/faqft"));
const Aboutaltf = React.lazy(() =>
  import("./pages/fotter-pages/aboutaltf/aboutaltf")
);
const Referral = React.lazy(() =>
  import("./pages/fotter-pages/referral/referral")
);
const Thankyou = React.lazy(() => import("./components/thank-you/thankyou"));
const Errorpage = React.lazy(() => import("./components/404page/errorpage"));
const Footer = React.lazy(() => import("./components/fotter/fotter"));

const Alllocations = React.lazy(() =>
  import("./pages/coworking-space/alllocations")
);
const PropertyPage = React.lazy(() =>
  import("./pages/coworking-space/property pages/propertyPagee")
);

function App() {
  return (
    <div>
      <BrowserRouter basename="/">
        <HelmetProvider>
          <TokenProvider>
            <MainApp />
          </TokenProvider>
        </HelmetProvider>
      </BrowserRouter>
    </div>
  );
}

function MainApp() {
  const navigate = useNavigate();
  const location = useLocation();
  useLoadScripts();

  useEffect(() => {
    const loadNonCriticalScripts = () => {
      const addScript = (src, id) => {
        if (document.getElementById(id)) return;
        const script = document.createElement("script");
        script.src = src;
        script.async = true;
        script.defer = true;
        script.id = id;
        document.body.appendChild(script);
      };

      addScript("https://crm.zoho.com/crm/javascript/zcga.js", "zoho-crm");
      addScript("https://forms.zoho.com/js/zf_gclid.js", "zoho-forms");
    };

    const timer = setTimeout(loadNonCriticalScripts, 5000); // Delay loading by 5 seconds
    return () => clearTimeout(timer); // Cleanup
  }, []);

  const redirects = useMemo(
    () => ({
      "/coworking-spaces/gurgaon/": "/coworking-space/gurgaon/",
      "/workspace-solutions/fixed-office/":
        "/workspace-solutions/private-office/",
      "/workspace-solutions/fixed-office":
        "/workspace-solutions/private-office/",
      "/year-zero/free-coworking/": "/",
      "/about-altf.php": "/about-altf/",
      "/faq.php": "/faq/",
      "/media.php": "/",
      "/referral.php": "/referral/",
      "/careers.php": "/careers/",
      "/coworking-space-solutions/": "/",
      "/partnerships.php": "/",
      "/landlords.php": "/",
      "/investors.php": "/",
      "/brokers.php": "/",
      "/terms-and-conditions.php": "/terms-and-conditions/",
      "/privacy-policy.php": "/privacy-policy/",
      "/refund-policy.php": "/refund-policy/",
      "/workspace-solutions/virtual-office-in-gurgaon/":
        "/workspace-solutions/virtual-office/gurgaon",
      "/workspace-solutions/virtual-office-in-noida/":
        "/workspace-solutions/virtual-office/noida",
      "/workspace-solutions/virtual-office-in-delhi/":
        "/workspace-solutions/virtual-office/delhi",
      "/workspace-solutions/office-space-in-delhi/": "/",
      "/workspace-solutions/office-space-in-gurgaon/": "/",
      "/workspace-solutions/office-space-in-noida/": "/",
      "/workspace-solutions/office-space-in-hyderabad/": "/",
      "/workspace-solutions/managed-office/": "/",
      "/termsofuse": "/terms-of-use",
      "/terms-of-use.php": "/terms-of-use",
      "/thank-you.php": "/thank-you",
    }),
    []
  );

  const currentUrl = useMemo(() => {
    const path = window.location.pathname;
    const hash = window.location.hash;
    return `${path}${hash}`;
  }, [window.location.pathname, window.location.hash]);

  const target = useMemo(() => {
    return redirects[currentUrl] || redirects[window.location.pathname];
  }, [redirects, currentUrl]);

  useEffect(() => {
    if (target) {
      navigate(target, { replace: true });
    }
  }, [navigate, target]);

  useEffect(() => {
    const normalizeUrl = () => {
      const { pathname, search, hash } = window.location;
      // Check if the pathname ends with a trailing slash
      if (!pathname.endsWith("/")) {
        const newUrl = `${pathname}/${search}${hash}`;
        // Replace the current URL with the normalized one
        window.history.replaceState(null, "", newUrl);
      }
    };

    normalizeUrl();
  }, [navigate]);

  const hiddenRoutes = [
    "/noida-landing-page/",
    "/Virtual-office-landing-page/",
    "/landing-page/coworking-spaces/",
    "/our-story/",
    "/business-card/:cardId",
    "/startup-event/"
  ];
  const hideNavbarFooter = hiddenRoutes.some((route) =>
    matchPath({ path: route, end: true }, location.pathname)
  );



  return (
    <div className="appcontaineroverflow">
      {!hideNavbarFooter && (
        <div className="wearehirecontainer">
          <img src={stickyimg} alt="img" className="stickyimg" />
        </div>
      )}
      <ScrollToTopdefault />
      <Altfkey />
      {!hideNavbarFooter && <Quiz/>}
      {!hideNavbarFooter && <Navbar />}
      <Suspense fallback={<div>Loading...</div>}></Suspense>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/enquiry" element={<Enquiryform />} />
        <Route path="/coworking-space" element={<Alllocations />} />
        <Route path="/coworking-space/:city" element={<Alllocations />} />
        <Route
          path="/coworking-space/:city/:property"
          element={<PropertyPage />}
        />
        <Route path="/workspace-solutions" element={<Workspacesolutions />} />
        <Route
          path="/workspace-solutions/virtual-office"
          element={<Virtualoffice />}
        />
        <Route
          path="/workspace-solutions/virtual-office/:param1/:param2?"
          element={<VirtualOfficeRouter />}
        />
        <Route
          path="/workspace-solutions/private-office"
          element={<PrivateOffice />}
        />
        <Route
          path="/workspace-solutions/private-office/:tab"
          element={<Tabspo />}
        />
        <Route
          path="/workspace-solutions/flexible-office"
          element={<FlexibleOffice />}
        />
        <Route
          path="/workspace-solutions/flexible-office/day-pass"
          element={<Daypass />}
        />
        <Route
          path="/workspace-solutions/flexible-office/day-pass/:tab?"
          element={<Daypass />}
        />
        <Route
          path="/workspace-solutions/flexible-office/meeting-rooms"
          element={<Meetingroom />}
        />
        <Route
          path="/workspace-solutions/flexible-office/meeting-rooms/:tab?"
          element={<Meetingroom />}
        />
        <Route path="/careers" element={<Careers />} />
        <Route path="/privacy-policy" element={<Privacypolicy />} />
        <Route path="/refund-policy" element={<Refundpolicy />} />
        <Route path="/terms-and-conditions" element={<Termsandconditions />} />
        <Route path="/faq" element={<Faqft />} />
        <Route path="/about-altf" element={<Aboutaltf />} />
        <Route path="/referral" element={<Referral />} />
        <Route path="/thank-you" element={<Thankyou />} />
        <Route path="/404" element={<Errorpage />} />
        <Route path="/page-important-links" element={<Pageimportantlinks />} />
        <Route path="/terms-of-use" element={<Termsofuse />} />
        <Route path="/noida-landing-page" element={<Noidalandingpage />} />
        <Route
          path="/Virtual-office-landing-page"
          element={<Virtualofficelangingpage />}
        />
        <Route
          path="/landing-page/coworking-spaces/"
          element={<Alllocationslp />}
        />
        <Route path="/our-story" element={<TimelineNavigation />} />
        <Route path="/business-card/:cardId" element={<VisitingCards />} />
        <Route path="/property-owners" element={<Landlord />} />
        <Route path="/channel-partners" element={<Channelpartners />} />
        <Route path="/offers" element={<Offer />} />
        <Route path="/walkin" element={<Walkinbook />} />
        <Route path="/blogs" element={<Homeblog />} />
        <Route path="/blogs/:slug" element={<Blogdetailspage />} />
        <Route path="/workspace-solutions/office-space" element={<Officespaces />} />
        <Route path="/workspace-solutions/office-space/:city" element={<Officespaces />} />
        <Route path="/startup-event" element={<Startupevent />} />
        <Route path="*" element={<Errorpage />} />
      </Routes>
      {!hideNavbarFooter && <Footer />}
    </div>
  );
}

export default App;
