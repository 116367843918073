import React, { useEffect } from "react";
import bgpic from "../assests/slide1.avif";
import img1 from "../assests/slide111.avif";
import mpic from '../assests/slidem11.avif';
import "./slide2.css";
import { MdArrowBackIosNew } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";

const Slide11 = ({ onYearChange }) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowRight") {
        onYearChange("how diff");
      } else if (event.key === "ArrowLeft") {
        onYearChange("2023");
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onYearChange]);

  return (
    <div>
      <div className="d-flex flex-row justify-content-between align-items-center arrowsintro">
        <div onClick={() => onYearChange("2023")}>
          <MdArrowBackIosNew style={{ fontSize: "30px" }} />
        </div>

        <div onClick={() => onYearChange("how diff")}>
          <MdArrowForwardIos style={{ fontSize: "30px" }} />
        </div>
      </div>
      <div className="d-none d-lg-block">
        <div
          className="d-flex flex-row justify-content-center align-items-center containerheight"
          
        >
          <div className="col-lg-6 d-flex flex-row justify-content-center align-items-center">
            <div>
              <div
                className="d-flex flex-row justify-content-start align-items-center"
                style={{ marginLeft: "-20px" }}
              >
                <img src={bgpic} alt="img" className="slide4bg" style={{height:"140px"}}/>
                <div
                  className="slide1heading "
                  style={{ marginLeft: "-30px", fontSize: "45px",marginTop:"20px" }}
                >
                <span style={{ fontSize: "2rem",fontWeight:"500" }}>
                 2024  
                  </span><br/>
                  <span> Expansion & Beyond</span>
                </div>
              </div>

              <div className="mt-3 slide5listitems">
                <ul>
                  <li>Corporate team grew to 75 employees.</li>
                  <li>
                    Revenue scaled from <span style={{backgroundColor:"#D36161",marginLeft:"4px",color:"white",marginRight:"3px"}}>₹21 Cr ARR in early 2023 to ₹50 Cr ARR</span>
                    by mid-2024.
                  </li>
                  <li>
                    Launched flagship product, alt.f Orchid Business Tower
                    (Gurgaon), with a 1,000-seat capacity.
                  </li>

                  <li>
                    Achieved 7,300 operational seats and added 4,000 signed
                    seats, projected to surpass <span style={{backgroundColor:"#D36161",marginLeft:"4px",color:"white"}}>₹100 Cr revenue next year.</span>
                  </li>
                  <li>
                    Expanding to Hyderabad, with plans for Pune, aiming to
                    transform micro offices across India.
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-lg-5 d-flex flex-row justify-content-center align-items-center" data-aos="zoom-in">
           <img src={mpic} alt="img" className="containerheight" style={{  maxWidth: "100%",objectFit:"contain" }}/>
          </div>
        </div>
      </div>

      <div className="d-block d-lg-none   d-flex flex-column justify-content-around mbpt">
        <div className="d-flex flex-row justify-content-center align-items-center pt-3 allpagemargintop">
          <div className="mbslide2heading text-center d-flex flex-column mbslide3heading" >
            <h2 className="mt-5">Expansion & Beyond</h2>
          </div>
        </div>

        

        <div className="mbbggg">
          <div className="mt-4 slide4listitems ">
          <ul>
                  <li>Corporate team grew to 75 employees.</li>
                  <li>
                    Revenue scaled from <span style={{backgroundColor:"#D36161",marginLeft:"4px",color:"white",marginRight:"3px"}}>₹21 Cr ARR in early 2023 to ₹50 Cr ARR</span>
                    by mid-2024.
                  </li>
                  <li>
                    Launched flagship product, alt.f Orchid Business Tower
                    (Gurgaon), with a 1,000-seat capacity.
                  </li>

                  <li>
                    Achieved 7,300 operational seats and added 4,000 signed
                    seats, projected to surpass <span style={{backgroundColor:"#D36161",marginLeft:"4px",color:"white"}}>₹100 Cr revenue next year.</span>
                  </li>
                  <li>
                  Expanding to Hyderabad, with plans for Pune, aiming to
                    transform micro offices across India.
                  </li>
                </ul>
          </div>

          <div
          className=" d-flex flex-row justify-content-center align-items-center "
        >
          <div
            className="d-flex flex-column justify-content-center align-items-center pb-3"
            
          >
           <img
              src={img1}
              alt="img"
              className="slide2img4 "
             
            />
           
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Slide11;
