import React,{useState} from "react";
import "./landlord.css";
import { Link } from "react-router-dom";
import EnquiryFormModal from "./pop-up-enquiry";
import LandlordInfoflow from "./infoflow";
import { Helmet } from "react-helmet-async";
import bglandlord from '../../../assests/footerpages/businnesspartner/Web 1920 – 2.avif';


const Landlord = () => {
      const [isModalOpen, setIsModalOpen] = useState(false);
    
      const handleModalToggle = () => {
        setIsModalOpen(!isModalOpen);
      };
  return (
    <div className="container" style={{ padding: "0px" }}>
    <EnquiryFormModal isOpen={isModalOpen} onClose={handleModalToggle}/>
      <div className="easylinks mb-3 workspacesolutions landlordeasy" style={{marginLeft:"5px"}}>
        <Link to="/" className="custom-link">
          Home
        </Link>{" "}
        / <span>Property Owners</span>
      </div>

      <Helmet>
  {/* Meta Title */}
  <title>Property Owners - Convert Your Space Into Coworking Space</title>

  {/* Meta Description */}
  <meta
    name="description"
    content="Are you a commercial property owner? Convert it into a coworking space with alt.f coworking and generate high returns. Start your coworking journey today."
  />

  {/* Meta Keywords */}
  <meta
    name="keywords"
    content="offers, discounts, special offers, best deals, best discounts, shark tank special, special price, shark tank india special, altf coworking offers, shark tank deals, shark tank offers, coworking space, coworking spaces, alt.f coworking shark tank india"
  />

  {/* Robots */}
  <meta name="robots" content="index, follow" />

  {/* Canonical */}
  <link rel="canonical" href="https://www.altfcoworking.com/property-owners/" />

  {/* Author & Publisher */}
  <meta name="author" content="alt.f coworking" />
  <meta name="publisher" content="alt.f coworking" />

  {/* Open Graph (OG) Meta Tags */}
  <meta
    property="og:title"
    content="Property Owners - Convert Your Space Into Coworking Space"
  />
  <meta
    property="og:description"
    content="Are you a commercial property owner? Convert it into a coworking space with alt.f coworking and generate high returns. Start your coworking journey today."
  />
  <meta
    property="og:image"
    content="https://www.altfcoworking.com/static/media/Web%201920%20%E2%80%93%202.72de0458ef1992f2b092.avif"
  />
  <meta
    property="og:url"
    content="https://www.altfcoworking.com/property-owners/"
  />
  <meta property="og:type" content="website" />
  <meta property="og:site_name" content="alt.f coworking" />

  {/* Twitter Meta Tags */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta
    name="twitter:title"
    content="Property Owners - Convert Your Space Into Coworking Space"
  />
  <meta
    name="twitter:description"
    content="Are you a commercial property owner? Convert it into a coworking space with alt.f coworking and generate high returns. Start your coworking journey today."
  />
  <meta
    name="twitter:image"
    content="https://www.altfcoworking.com/static/media/Web%201920%20%E2%80%93%202.72de0458ef1992f2b092.avif"
  />
  <meta
    name="twitter:image:alt"
    content="Property Owners - Convert Your Space Into Coworking Space"
  />
  <meta name="twitter:site" content="@altfcoworking" />
  <meta name="twitter:creator" content="@altfcoworking" />
</Helmet>


      <div className="d-none d-md-block ">
      <div className="d-flex justify-content-center w-100">
      <div className="landlord-container ">
        <button className="landlord-cta-button " onClick={handleModalToggle}>Monetise Your Property</button>
      </div>
      </div>
     
      </div>
     
    <div className="d-md-none">
    <div className="d-flex flex-column">
        <img src={bglandlord} alt="img" className="mbbannerlord"/>
        <button className="landlord-cta-button mb-button-landlord"  onClick={handleModalToggle}>Monetise Your Property</button>
      </div>
    </div>
     



      <LandlordInfoflow/>

      <div className="bottom-lanloard-btn">
      <button className="expapantionbtn" onClick={handleModalToggle}>Talk To Our Expansion Team</button>

      </div>

    </div>
  );
};

export default Landlord;
