import React, { useState, useEffect, useRef } from "react";
import "./navbarmb.css";
import { FaHome, FaBars, FaTimes } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io5";
import { MdCall, MdCoPresent } from "react-icons/md";
import { useNavigate, useLocation,Link } from "react-router-dom";
import { MdLocationOn, MdWork, MdLocalOffer } from "react-icons/md";
import { TiDocumentText } from "react-icons/ti";
import { IoAdd } from "react-icons/io5";
import Walkinbook from "../walkin-book/walkinbook";
import { IoIosBookmark } from "react-icons/io";
import Altfkey from "../altfkey-word/altfkeys";

const Navbarmb = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("Home");
  const navbarRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [isFormOpen, setIsFormOpen] = useState(false);

  const toggleForm = () => {
    setIsFormOpen(!isFormOpen);
  };

  useEffect(() => {
    const path = location.pathname;
  
    if (path === "/") {
      setActiveTab("Home");
    } else if (path === "/enquiry") {
      setActiveTab("Enquiry");
    } else {
      setActiveTab("");
    }
  }, [location]);
  

  const toggleMenu = () => {
    if (!menuOpen) {
      setActiveTab("");
    }
    setMenuOpen(!menuOpen);
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName); // ✅ Update active tab first
    setMenuOpen(false); // ✅ Close menu
  
    switch (tabName) {
      case "Home":
        navigate("/"); 
        break;
      case "Enquiry":
        navigate("/enquiry"); 
        break;
      case "Call":
        window.location.href = "tel:+919958500731"; 
        break;
      case "Whatsapp":
        window.open("https://wa.me/919958080053", "_blank"); 
        break;
      default:
        break;
    }
  };
  

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

    const [isAltfTriggered, setIsAltfTriggered] = useState(false);
  
    const handleMobileAltfClick = () => {
      if (window.innerWidth <= 768) {
        setIsAltfTriggered(false); // Reset trigger
        setTimeout(() => {
          setIsAltfTriggered(true); // Re-trigger after reset
        }, 100); // Short delay to allow state update
      }
    };

  return (
    <div className="mobilenewnav-container" ref={navbarRef}>
      <Walkinbook isFormOpen={isFormOpen} toggleForm={toggleForm} />

      <div className="mobilenewnav-bar">
        <div className="mobilenewnav-icons">
          {["Home", "Enquiry"].map((tab) => (
            <div
              key={tab}
              className={`mobilenewnav-icon-item ${
                activeTab === tab ? "active" : ""
              }`}
              onClick={() => handleTabClick(tab)}
            >
              <span
                className={`homebtnnav ${
                  activeTab === tab ? "homebtnnavactive" : ""
                }`}
              >
                {tab === "Home" && <FaHome />}
              </span>
              {tab === "Enquiry" && <TiDocumentText />}
              <p className="mbiconname">{tab}</p>
            </div>
          ))}

          <button
            className={`mobilenewnav-menu-btn  ${menuOpen ? "active" : ""}`}
            onClick={toggleMenu}
          >
            {menuOpen ? <FaTimes /> : <IoAdd />}
          </button>

          {["Call", "Whatsapp"].map((tab) => (
            <div
              key={tab}
              className={`mobilenewnav-icon-item ${
                activeTab === tab ? "active" : ""
              }`}
              onClick={() => handleTabClick(tab)}
            >
              {tab === "Call" && <MdCall />}
              {tab === "Whatsapp" && <IoLogoWhatsapp />}
              <p className="mbiconname">{tab}</p>
            </div>
          ))}
        </div>
      </div>

      <div className={`mobilenewnav-dropdown ${menuOpen ? "open" : "close"}`}>
  <div className="mobilenewnav-dropdown-icons">

    {/* Book a Visit */}
    <Link
      to="#"
      onClick={(e) => {
        e.stopPropagation();
        toggleForm();
      }}
      className="mobilenewnav-dropdown-icon-item"
    >
      <IoIosBookmark className="dropdown-icon" />
      <span>Book a Visit</span>
    </Link>

    {/* Offers */}
    <Link onClick={handleMobileAltfClick} className="mobilenewnav-dropdown-icon-item">
      <span style={{fontSize:"21px"}}>alt.f</span>
      <span>Nearby</span>
    </Link>

    {/* Locations */}
    <Link to="/coworking-space/" className="mobilenewnav-dropdown-icon-item">
      <MdLocationOn className="dropdown-icon" />
      <span>Locations</span>
    </Link>

    {/* Workspace Solutions */}
    <Link to="/workspace-solutions/" className="mobilenewnav-dropdown-icon-item">
      <MdWork className="dropdown-icon" />
      <span>Workspaces</span>
    </Link>

    
  </div>
</div>
 <Altfkey trigger={isAltfTriggered}/>
    </div>
  );
};

export default Navbarmb;
