import React from "react";


const Faq = ({data}) => {
  const faqData = data
  return (
    <div className="faq d-flex flex-column container">
      <div className="faq-header">Frequently Asked Questions</div>
    
      <div className="faq-content firstboxwidth d-flex flex-column  container">
        {faqData.map((faq, index) => (
          <div className="faq-question" key={faq.id} >
            {/* Ensure a unique ID using the index */}
            <input id={`faq-${index}`} type="checkbox" className="panel" />
            
            {/* Link the label to the specific input */}
            <label htmlFor={`faq-${index}`} className="panel-title">
              <div className="plus">+</div>
              {faq.question}
            </label>
            
            <div
              className="panel-content"
              dangerouslySetInnerHTML={{ __html: faq.answer }}
            />
          </div>
        ))}
      </div>

    
    </div>
  );
};
export default Faq;
