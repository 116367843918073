import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "./home-blog-tabs.css";
import { TokenContext } from "../../../components/tokenContext";

const Homeblogtabs = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [blogs, setBlogs] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const observerRef = useRef(null);
  const controllerRef = useRef(null); // To prevent race conditions
  const initialRender = useRef(true); // Avoid unnecessary calls on mount
  const { csrfToken, getValidToken } = useContext(TokenContext);
  const hasFetched = useRef(false);

  useEffect(() => {
    const fetchCategories = async () => {
      if (hasFetched.current) return;
      hasFetched.current = true;

      try {
        const token = await getValidToken();
        const response = await axios.get(
          "https://www.altfcoworking.com/api/blogshometab/categories",
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "X-CSRF-Token": csrfToken,
            },
            withCredentials: true,
          }
        );

        setCategories(response.data);
      } catch (err) {
        console.error("Error fetching categories:", err);
      }
    };

    fetchCategories();
  }, [getValidToken, csrfToken]);

  // const fetchBlogs = useCallback(
  //   async (reset = false) => {
  //     if (loading || !hasMore) return;

  //     setLoading(true);

  //     if (controllerRef.current) {
  //       controllerRef.current.abort();
  //     }
  //     controllerRef.current = new AbortController();

  //     try {
  //       const params = {
  //         populate: ["Image", "author", "Category"],
  //         fields: ["Title", "Summary", "PublishedDate", "slug"],
  //         sort: "PublishedDate:desc",
  //         pagination: {
  //           page: reset ? 1 : page,
  //           pageSize: selectedCategory === "all" ? 50 : 6,
  //         },
  //       };

  //       if (selectedCategory !== "all") {
  //         params.filters = {
  //           Category: {
  //             CategoryName: { $eq: selectedCategory.replace("-", " ") },
  //           },
  //         };
  //       }

  //       const res = await axios.get("http://localhost:1337/api/blogs", {
  //         params,
  //         signal: controllerRef.current.signal,
  //       });

  //       const fetchedBlogs = res.data.data || [];

  //       setBlogs((prev) => (reset ? fetchedBlogs : [...prev, ...fetchedBlogs]));

  //       setHasMore(fetchedBlogs.length > 0);
  //       setPage((prev) => (reset ? 2 : prev + 1));
  //     } catch (err) {
  //       if (err.name !== "AbortError") {
  //         console.error("Error fetching blogs:", err);
  //       }
  //     } finally {
  //       setLoading(false);
  //     }
  //   },
  //   [selectedCategory, page, hasMore, loading]
  // );

  const hasFetchedBlogs = useRef(false);

  const fetchBlogs = useCallback(
    async (reset = false) => {
      if (loading || !hasMore) return;

      setLoading(true);
      hasFetchedBlogs.current = true; // ✅ Prevent multiple fetches at once

      if (controllerRef.current) {
        controllerRef.current.abort();
      }
      controllerRef.current = new AbortController();

      try {
        const token = await getValidToken();
        const pageSize = selectedCategory === "all" ? (reset ? 40 : 10) : 6;

        const response = await axios.get(
          "https://www.altfcoworking.com/api/blogshometab/blogs",
          {
            params: {
              category: selectedCategory,
              page: reset ? 1 : page,
              pageSize,
            },
            headers: {
              Authorization: `Bearer ${token}`,
              "X-CSRF-Token": csrfToken,
            },
            withCredentials: true,
            signal: controllerRef.current.signal,
          }
        );

        const fetchedBlogs = response.data.data || [];

        // ✅ Avoid adding duplicate blogs
        setBlogs((prev) => {
          const uniqueBlogs = [
            ...new Map(
              [...prev, ...fetchedBlogs].map((blog) => [blog.id, blog])
            ).values(),
          ];
          return reset ? fetchedBlogs : uniqueBlogs;
        });

        setHasMore(fetchedBlogs.length > 0);
        setPage((prev) => (reset ? 2 : prev + 1));
      } catch (err) {
        if (err.name !== "AbortError") {
          console.error("Error fetching blogs:", err);
        }
      } finally {
        setLoading(false);
        hasFetchedBlogs.current = false; // ✅ Allow new fetches
      }
    },
    [selectedCategory, page, hasMore, loading, getValidToken, csrfToken]
  );

  // ✅ Fetch new blogs when category changes
  useEffect(() => {
    setPage(1);
    setHasMore(true);
    setBlogs([]);
    fetchBlogs(true);
  }, [selectedCategory]);

  // ✅ Attach IntersectionObserver for infinite scroll
  useEffect(() => {
    if (!observerRef.current || !hasMore || loading) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !hasFetchedBlogs.current) {
          fetchBlogs(false);
        }
      },
      { threshold: 1.0 }
    );

    observer.observe(observerRef.current);

    return () => observer.disconnect();
  }, [hasMore, loading, fetchBlogs]);

  return (
    <div className="blogs-home-tabs">
      {/* Category Tabs */}
      <div className="d-none d-lg-block">
        <div className="blogs-tabs">
          <button
            onClick={() => setSelectedCategory("all")}
            className={`blogs-tab-btn ${
              selectedCategory === "all" ? "selected" : ""
            }`}
          >
            All
          </button>
          {categories.map((category, index) => (
            <button
              key={category.id} // ✅ Use a unique key
              onClick={() => setSelectedCategory(category.slug)}
              className={`blogs-tab-btn ${
                selectedCategory === category.slug ? "selected" : ""
              }`}
            >
              {category.name.toUpperCase()} {/* ✅ Display the category name */}
            </button>
          ))}
        </div>
      </div>

      {/* Mobile Dropdown */}
      <div className="d-lg-none">
        <div className="blogs-dropdown-container">
          <div
            className="blogs-dropdown-header"
            onClick={() => setDropdownOpen((prevState) => !prevState)}
          >
            {selectedCategory !== "all"
              ? selectedCategory.replace("-", " ").toUpperCase()
              : "All Blogs"}
            <span
              className={`blogs-dropdown-arrow ${dropdownOpen ? "rotate" : ""}`}
            >
              ▼
            </span>
          </div>
          {dropdownOpen && (
            <ul className="blogs-dropdown-menu">
              <li
                className="blogs-dropdown-item"
                onClick={() => setSelectedCategory("all")}
              >
                All
              </li>
              {categories.map((category) => (
                <li
                  key={category.id} // ✅ Unique key
                  className="blogs-dropdown-item"
                  onClick={() => setSelectedCategory(category.slug)}
                >
                  {category.name.toUpperCase()}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>

      <div className="blogs-cards-container">
        {loading && blogs.length === 0 ? (
          Array(6)
            .fill(0)
            .map((_, index) => (
              <div key={index} className="blogs-card skeleton-card">
                <div className="skeleton-image"></div>
                <div className="skeleton-content">
                  <div className="skeleton-title"></div>
                  <div className="skeleton-text"></div>
                  <div className="skeleton-meta"></div>
                </div>
              </div>
            ))
        ) : blogs.length > 0 ? (
          blogs.map((card, index) => (
            <div key={card.id || `blog-${index}`} className="blogs-card">
              <Link
                className="custom-link"
                to={`/blogs/${card.slug}`}
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <img
                  src={card.Image?.url}
                  alt={card.Title}
                  className="blogs-card-img"
                />
                <div className="blogs-card-content">
                  <span className="blogs-card-category">
                    {card.Category?.CategoryName}
                  </span>
                  <h3 className="blogs-card-title">{card.Title}</h3>
                  <p className="blogs-card-summary">
                    {card.Summary.length > 100
                      ? `${card.Summary.substring(0, 130)}...`
                      : card.Summary}
                  </p>
                  <p className="blogs-card-meta">
                    <span>{card.author?.FullName}</span> •{" "}
                    <span>{card.PublishedDate}</span>
                  </p>
                  <button className="blogs-btn-request">Read Blog</button>
                </div>
              </Link>
            </div>
          ))
        ) : !loading && blogs.length === 0 ? (
          <p>No blogs found.</p>
        ) : null}
      </div>

      {/* Infinite Scroll Loader */}
      <div ref={observerRef} className="d-flex justify-content-center mb-4">
        {loading && <p>Loading more blogs...</p>}
      </div>
    </div>
  );
};

export default Homeblogtabs;
